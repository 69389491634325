import React, { useState, useEffect, useMemo } from 'react';

import FirebaseUtils from './../../utils/firebase.utils';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import { StatusLogs, Vehicles } from '../../models';

import { Link } from 'react-router-dom';

import { StockTransfers, StockTransactions, Requests } from '../../models';

import { message } from 'antd';

import { Tag, Badge } from 'antd';

import moment from 'moment';

import GetUserName from '../../components/get-username/get-username';
import { Content } from 'antd/lib/layout/layout';

function AllocationCard({ vehicle, index, step }) {
    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">{vehicle.bookingNo}</h2>

                <h3 className="title">{vehicle.customer.customerName}</h3>

                <h2 className="title">{vehicle.customer.phone}</h2>

                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}
                </h3>

                <p className="address">KEC on {vehicle.kec}</p>

                {waitingTime && waitingTime.toString().length > 0 ? (
                    <p className="date-time" style={{ display: 'flex' }}>
                        Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (
                        <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                    </p>
                ) : null}

                {/* <Tag color="magenta">{vehicle.status}</Tag> */}

                {vehicle.finance && vehicle.finance.documentStatus ? <Badge status="processing" text={vehicle.finance.documentStatus} /> : null}
            </div>
        </Link>
    );
}

function TransferCard({ city, vehicle = {}, index, step }) {
    let waitinghours = moment().diff(moment(vehicle.created_at, 'DD/MM/YYYY hh:mm'));

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${vehicle.from}/${index}`}>
            <div className="card">
                <h2 className="title amount ">{vehicle.from + ' -> ' + vehicle.to}</h2>
                <h4 className="title"></h4>

                <h3 className="title ">Booking : {vehicle.bookingNo}</h3>

                <h3 className="title ">Vin No : {vehicle.vinNo}</h3>

                <h4>{vehicle.remarks}</h4>

                <Tag color="purple">{vehicle.status || 'Initial'}</Tag>

                <p className="address" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    Created by <strong style={{ marginLeft: '16px' }}><GetUserName user_id={vehicle.created_by} /></strong>
                </p>

                <p className="date-time" style={{ display: 'flex' }}>
                    Waiting since {moment(vehicle.created_at, 'DD/MM/YYYY hh:mm').format('hh a dddd - DD/MM/YYYY')} (
                    <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                </p>
            </div>
        </Link>
    );
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function StockOutward({ vehicle, index, step }) {


    return (
        <Link className="report-item" to={`/${step.redirect_to}/${vehicle.id}`}>
            <div className="card">
                <h2 className="title amount ">{vehicle.from + ' -> ' + vehicle.to}</h2>
                <h2 className="title amount">
                    {vehicle['bookingNo']}
                </h2>


                <h2 className="title">
                    {vehicle['vinNo']}
                </h2>

                <p className="address" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    Created by <strong style={{ marginLeft: '16px' }}><GetUserName user_id={vehicle.created_by} /></strong>
                </p>
                <h3 className="Created_at">
                    {vehicle['created_at']}
                </h3>

                {/* <h3 className="title">
                    {vehicle['Variant']} - {vehicle['Exterior Color Name']}
                </h3> */}

            </div>
        </Link>
    )
}

function NewCard({ vehicle, index, step }) {
    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    let city = vehicle.dealerCode == 'KL305' ? 'kozhikode' : vehicle.dealerCode == 'KL306' ? 'kannur' : vehicle.dealerCode == 'KL307'?'malappuram':vehicle.dealerCode == 'KL508'?'kasargod':vehicle.dealerCode == 'KL505'?'kondotty':vehicle.dealerCode == 'KL507'?'vadakara':'tirur';;

    let id = step.endpoint === 'Allocation' ? vehicle.id : vehicle.requestId;

    return (
        <Link className="report-item" to={`/${step.redirect_to}/${city}/${id}`}>
            <div className="card">
                <h2 className="title amount">{vehicle.bookingNo}</h2>

                <p>{vehicle.customerName}</p>

                <h3 className="title">{vehicle.customerID}</h3>

                <h2 className="title">{vehicle.vinNo}</h2>

                {waitingTime && waitingTime.toString().length > 0 ? (
                    <p className="date-time" style={{ display: 'flex' }}>
                        Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (
                        <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                    </p>
                ) : null}

                <Tag color="magenta">{vehicle.status}</Tag>
            </div>
        </Link>
    );
}

function CancelCard({ vehicle = {}, index, step }) {
    let city = vehicle.dealerCode == 'KL305' ? 'kozhikode' : vehicle.dealerCode == 'KL306' ? 'kannur' : vehicle.dealerCode == 'KL307'?'malappuram':vehicle.dealerCode == 'KL508'?'kasargod':vehicle.dealerCode == 'KL505'?'kondotty':vehicle.dealerCode == 'KL507'?'vadakara':'tirur';

    return (
        <Link className="request-card" to={`/${step.redirect_to}/${city}/${vehicle.id}`}>
            <div className="card">
                <h2 className="title amount ">{vehicle.customerName}</h2>
                <h4 className="title">Phone No : {vehicle.phone}</h4>
                <h3 className="title ">Type : {vehicle.type}</h3>
                <a href={`/bookings/${vehicle.bookingId}`} style={{ zIndex: 1 }}>
                    {vehicle.bookingId}
                </a>
                <h4>{vehicle.remarks}</h4>
                <Tag color="purple">{vehicle.status || 'Initial'}</Tag>
                Created at {vehicle.approve_at}
            </div>
        </Link>
    );
}

export const edpSteps = [
    {
        caption: 'Customer-Receipts',
        path: '/receipts',
        model: 'customer_entries',
        queries: ({ user }) => {
            return [
                {
                    field: 'department_id',
                    value: user.department_ids[0],
                },
                {
                    field: 'pending_at',
                    value: 'edp',
                },
                {
                    field: 'verified',
                    value: 'approved',
                },
            ];
        },
        Card: TransferCard,
    },

    {
        caption: 'Stock Transfers',
        path: '/transfers',
        redirect_to: 'stock-transfer-form',
        model: 'stock_transfers',
        queries: ({ user }) => {
            return [
                {
                    field: 'from',
                    value: user.locations[0],
                },
                {
                    field: 'status',
                    value: 'approved',
                },
                {
                    field: 'sub_mode',
                    value: "normal"
                }

            ];
        },
        Card: TransferCard,
    },
    {
        caption: 'Inward Stock Transfers',
        path: '/inward_transfers',
        redirect_to: 'stock-transfer-form',
        model: 'stock_transfers',
        queries: ({ user }) => {
            return [
                {
                    field: 'to',
                    value: user.locations[0],
                },
                {
                    field: 'status',
                    value: 'stock-transfer-completed',
                },
                {
                    field: 'sub_mode',
                    value: 'normal'
                }
            ];
        },
        Card: TransferCard,

        //         onSubmit:(transferRequest, values) => {

        //             var app = FirebaseUtils.getApp();

        //             var batch = app.batch();
        // console.log("transferRequest",transferRequest)
        // console.log("values",values)
        //             return StockTransfers.createStatusLong('DONE', transferRequest, batch,values).then((res) => {
        //                 batch.commit().then(() => {
        //                     // message.success('Request For Stock Transfer Created');
        //                 });
        //             });
        //         }
    },
    {
        caption: 'Virtual Stock Transfers',
        path: '/virtual_transfers',
        redirect_to: 'stock-outward',
        model: 'stock_transfers',
        queries: ({ user }) => {
            return [
                {
                    field: 'from',
                    value: user.locations[0],
                },
                {
                    field: 'status',
                    value: 'approved',
                },
                {
                    field: 'sub_mode',
                    value: 'virtual'
                }
            ];
        },
        Card: StockOutward,
    },

    {
        caption: 'Inward Virtual Stock Transfers',
        path: '/inward_virtual_transfers',
        redirect_to: 'stock-outward',
        model: 'stock_transfers',
        queries: ({ user }) => {
            return [
                {
                    field: 'to',
                    value: user.locations[0],
                },
                {
                    field: 'status',
                    value: 'outward-done',
                },
                {
                    field: 'sub_mode',
                    value: 'virtual'
                }
            ];
        },
        Card: StockOutward,
    },
    // Task Routes

    {
        caption: 'For Allocation',
        path: '/for-allocation',
        redirect_to: 'allocation-form',
        otherTable: true,
        model: 'allocations',
        // queries: [
        //     {
        //         field: 'status',
        //         value: 'approved'
        //     }
        // ],
        queries: ({ code }) => {
            return [
                {
                    field: 'dealerCode',
                    value: code,
                },
                {
                    field: 'status',
                    value: 'approved',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];
        },
        field_time: 'created_time',
        Card: NewCard,
    },

    {
        caption: 'For Deallocation',
        path: '/for-deallocation',
        redirect_to: 'deallocation-form',
        otherTable: true,
        model: 'allocations',
        endpoint: 'Allocation',
        // queries: [
        //     {
        //         field: 'status',
        //         value: 'cancelled'
        //     }
        // ],
        queries: ({ code }) => {
            return [
                {
                    field: 'dealerCode',
                    value: code,
                },
                {
                    field: 'status',
                    value: 'cancelled',
                },
            ];
        },
        field_time: 'cancelled_allocated_time',
        Card: NewCard,
    },

    {
        caption: 'For Billing',
        path: '/for-billing',
        redirect_to: 'billing-form',
        queries: [
            {
                field: 'billing.status',
                value: 'approved',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'billing.approved_time',
        Card: AllocationCard,
    },

    {
        caption: 'For Delivery',
        path: '/for-delivery',
        redirect_to: 'delivery-form',
        queries: [
            {
                field: 'delivery.status',
                value: 'approved',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'delivery.approved_time',
        Card: AllocationCard,
    },

    // All Cancellation

    {
        caption: 'For Cancellation',
        path: '/for-cancellation',
        redirect_to: 'cancellations-list-form',
        model: 'cancellations',
        queries: [
            {
                field: 'status',
                value: 'DMS Cancellation Pending',
            },
        ],
        field_time: 'created_time',
        Card: CancelCard,
    },

    // For Punching Extended Warranty
    {
        caption: 'Punch Extended Warranty',
        path: '/punch-extended-warranty',
        redirect_to: 'extended-warrany-form',
        queries: [
            {
                field: 'warranty.status',
                value: 'Ready for Punching',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'delivery.delivered_note_time',
        Card: AllocationCard,
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | EW Punched',
                body: `Extended Warranty has been punched for ${cName}. `,
            };
        },
    },

    // Stock Transfer Form
    {
        isForm: true,
        caption: 'Stock Transfer Request',
        path: '/stock-transfer-form/:city/:id',
        endpoint: 'stock_transfers',
        model: 'stock_transfers',
        post_status: 'Allocated',
        extraParams: (content, params) => {
            var identifier
            if (content.status === 'approved') {

                return {
                    status: params.approve === 'Yes' ? 'outward-pending' : 'rejected',
                };
            } else if (content.status === 'stock-transfer-completed') {

                return {
                    status: 'done',
                    fulfilled: true
                };
            }
        },
        fields: [
            {
                type: 'radio',
                options: ['Yes', 'No'],
                caption: 'Approve',
                field: 'approve',
                condition: (content) => {
                    return content.status === 'approved';
                },
            },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        onSubmit: async (transferRequest, values) => {
            let identifier
            var app = FirebaseUtils.getApp();

            var batch = app.batch();
            // If rejected, set status of stock transfer record as rejected
            // Create a status log record
            if (values.approve === 'No') {

                // setBtnLoading(true);
                //  StockTransfers.update(transferRequest.id, { remarks: values.remarks, approve: StockTransfers.firebase().firestore.FieldValue.delete() }).then(() => {
                // message.success('Rejected');
                // setBtnLoading(false);
                //StockTransfers.createStatusLog('STCKCANCELLED', transferRequest, batch, values)

                StockTransfers.cancelStockTransferRequest(transferRequest, batch).then((res) => {
                    batch.commit().then(() => {

                    })
                })

                // });


            } else {
                // This situation arises as approve cannot be named as status( the condition in fields would fail as there is already a status in the record)
                // So an approve field is created which is not needed
                await StockTransfers.update(transferRequest.id, { approve: StockTransfers.firebase().firestore.FieldValue.delete() })
                // Change the status according to outward or inward and create status log record
                if (transferRequest.status === 'stock-transfer-completed') {
                    identifier = 'DONE'
                } else {
                    identifier = 'STCKOUTPENDING'
                }
               
                return StockTransfers.createStatusLog(identifier, transferRequest, batch, values).then((res) => {
                    batch.commit().then(() => {
                        // message.success('Request For Stock Transfer Created');
                    });
                });
            }
        }



        // onSubmit: (transferRequest, values) => {
        //     // Once done, We have to update the allocation status on request so that
        //     // stock is taken from the same city

        //     let params = {};

        //     var app = FirebaseUtils.getApp();

        //     var batch = app.batch();

        //     //Get Stock details from stock_transactions by Vehicle Number sorted using Transaction code

        //     var stocks;

        //     var query = [
        //         {
        //             field: 'item_identity_number',
        //             value: transferRequest.vinNo,
        //         },
        //     ];

        //     var config = {
        //         orderBy: 'transaction_code',
        //     };

        //     StockTransactions.get(query, config).then((result) => {
        //         console.log(result)
        //         stocks = result.stock_transactions[0]


        //         //Update logs and Requests  with stock_transfer request 
        //         Requests.updateRequestGeneric(transferRequest.requestId,
        //             params,
        //             'Stock Transfer Request is Created',
        //             values.remarks,
        //             'allocation').then(async () => {

        //                 if (transferRequest.status === 'approved') {

        //                     const vehicleRef = Vehicles.getRecordReference(transferRequest.vinNo)
        //                     //Updating vehicle dealer code

        //                     await batch.update(vehicleRef, {

        //                         dealerCode: transferRequest.toDealerCode,
        //                         stock_status: 'in-transit'

        //                     })

        //                     const stockRef = StockTransfers.getRecordReference(transferRequest.id)
        //                     var docRef = StockTransactions.getRecordReference()
        //                     //Updating vehicle dealer code

        //                     await batch.update(stockRef, {

        //                         start_timestamp: new Date(moment().format('MMMM DD, YYYY')),
        //                         stock_transaction_id: docRef.id
        //                     })

        //                     //update Stock Transactions with  outward stock details
        //                     delete stocks.quantity;
        //                     delete stocks.transaction_code;
        //                     delete stocks.created_at;
        //                     delete stocks.dealerCode;
        //                     delete stocks.id;
        //                     delete stocks.transaction_date;

        //                     var param = {

        //                         ...stocks,
        //                         quantity: -1,
        //                         transaction_code: 70,
        //                         toDealerCode: transferRequest.toDealerCode,
        //                         fromDealerCode: transferRequest.dealerCode,
        //                         dealerCode: transferRequest.dealerCode,
        //                         transaction_date: new Date(),
        //                         deleted_at:null,
        //                         deleted_by:null
        //                     }

        //                     await batch.set(docRef, StockTransactions.appendDefaultValues(param))

        //                     return batch.commit()

        //                 }

        //                 //Creating inward request
        //                 if (transferRequest.status === 'outward-done') {

        //                     //Creating new Record in Stock Transfer
        //                     var res = StockTransfers.createInwardRequest(transferRequest)
        //                     var stockTransactionReference = StockTransactions.getRecordReference()

        //                     res = {
        //                         ...res,
        //                         end_timestamp: new Date(moment().format('MMMM DD, YYYY')),
        //                         stock_transaction_id: stockTransactionReference.id

        //                     }

        //                     var ref = StockTransfers.getRecordReference()

        //                     await batch.set(ref, StockTransfers.appendDefaultValues(res));

        //                     //Update Status in Vehicles
        //                     const vehicleReference = Vehicles.getRecordReference(transferRequest.vinNo)

        //                     await batch.update(vehicleReference, {

        //                         dealerCode: transferRequest.toDealerCode,
        //                         stock_status: 'completed'

        //                     })

        //                     //Creating new record in Stock Transactions with inward stock details


        //                     delete stocks.quantity;
        //                     delete stocks.transaction_code;
        //                     delete stocks.created_at;
        //                     delete stocks.dealerCode;
        //                     delete stocks.location;
        //                     delete stocks.id;
        //                     delete stocks.transaction_date;

        //                     var q = {

        //                         ...stocks,
        //                         quantity: +1,
        //                         transaction_code: 20,
        //                         transaction_date: new Date(),
        //                         dealerCode: transferRequest.toDealerCode,
        //                         fromDealerCode: transferRequest.dealerCode,
        //                         toDealerCode: transferRequest.toDealerCode,
        //                         location: transferRequest.to,
        //                         deleted_at:null,
        //                         deleted_by:null

        //                     }

        //                     await batch.set(stockTransactionReference, StockTransactions.appendDefaultValues(q))

        //                     return batch.commit()

        //                 }

        //                 message.success('Allocation status of request has been updated');

        //             });
        //     });
        // }
    },

    // Allocation Form
    {
        isForm: true,
        caption: 'Allocation Form',
        path: '/allocation-form/:city/:id',
        model: 'allocation',
        post_status: 'Allocated',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        onSubmit: (request) => {
            // We need to create the delivery_request saved for reporting
            let creation = {
                allocated_at: moment().format('DD/MM/YYYY HH:mm'),
                allocated_date: moment().startOf('day').valueOf(),
                allocated_time: moment().valueOf(),
            };

            let params = {
                allocation: {
                    status: 'completed',
                    ...creation,
                },
            };

            return FirebaseUtils.updateBaseRecords(request.allocation.allocationId, 'allocations', params.allocation).then(() => {
                message.success(`Allocation is done`);
            });
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Vehicle Allocated',
                body: `${request.allocation.vinNo} , ${request.variant} - ${request.color} has been allocated to ${cName}. `,
            };
        },
        extraParams: (userRequest, params) => {
            let creation = {
                allocated_at: moment().format('DD/MM/YYYY HH:mm'),
                allocated_date: moment().startOf('day').valueOf(),
                allocated_time: moment().valueOf(),
            };

            return {
                allocation: {
                    ...params,
                    ...creation,
                },
            };
        },
    },

    // for deallocation
    {
        isForm: true,
        caption: 'Deallocation Form',
        path: '/deallocation-form/:city/:id',
        model: 'allocations',
        endpoint: 'Allocation',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (userRequest, params) => {
            let creation = {
                deallocated_at: moment().format('DD/MM/YYYY HH:mm'),
                deallocated_date: moment().startOf('day').valueOf(),
                deallocated_time: moment().valueOf(),
                status: 'removed',
            };

            return {
                ...params,
                ...creation,
            };
        },
    },

    // for Cancellations
    {
        isForm: true,
        caption: 'Cancellation Form',
        path: '/cancellations-list-form/:city/:id',
        model: 'cancellations',
        endpoint: 'cancellations',
        fields: [
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'edp_remarks',
            },
        ],
        extraParams: (userRequest, params, user) => {
            let creation = {
                dms_cancellation_at: moment().format('DD/MM/YYYY HH:mm'),
                dms_cancellation_date: moment().startOf('day').valueOf(),
                dms_cancellation_time: moment().valueOf(),
                status: 'Cancellation Completed',
                dms_cancellation_by: user.name,
            };

            return {
                ...params,
                ...creation,
            };
        },
        onSubmit: (request) => {
            let bookingParams = {
                cancellation: {
                    id: request.id,
                    status: 'Cancellation Completed',
                },
                isCancelled: true,
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingId, bookingParams).then(() => {
                message.success('Booking Cancel Request Updated');
            });
        },
    },

    // Billing Form
    {
        isForm: true,
        caption: 'Billing Form',
        path: '/billing-form/:city/:id',
        model: 'billing',
        post_status: 'Invoiced',
        extraParams: (userRequest, params) => {
            let creation = {
                invoiced_at: moment().format('DD/MM/YYYY HH:mm'),
                invoiced_date: moment().startOf('day').valueOf(),
                invoiced_time: moment().valueOf(),
            };

            let { booking = {} } = userRequest;

            let insurance = {};

            if (booking.insurance && booking.insurance.status === 'Data Collected') {
                insurance = {
                    ...booking.insurance,
                };
            } else {
            }

            return {
                billing: {
                    ...userRequest.billing,
                    ...params,
                    ...creation,
                },

                // insurance: {
                //     ...insurance,
                //     process: 'incomplete',
                //     status: 'Ready for Issue'
                //     // ...userRequest.insurance,
                //     // status: userRequest.insurance.type === 'inhouse' ? 'Ready for Issue' : 'Done'
                // },
                // accessories: {
                //     ...userRequest.accessories,
                //     status: 'Ready for Fitment'
                // },
                // fasttag: {
                //     ...userRequest.fasttag,
                //     status: 'pending',
                //     process: 'incomplete'
                // },
                delivery: {
                    ...userRequest.delivery,
                    status: 'pending',
                    process: 'incomplete',
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | DMS Billing Complete',
                body: `${request.allocation.vinNo} has been billed for ${cName}. `,
            };
        },
        onSubmit: (request) => {
            let creation = {
                invoiced_date: moment().startOf('day').valueOf(),
                invoiced_time: moment().valueOf(),
            };

            // Billing is followed by a series of steps
            // We need to update the billing request at this point

            let params = {
                billing: {
                    status: 'done',
                    ...creation,
                },
            };

            // We
            return FirebaseUtils.updateBookingGeneric(request.bookingNo, params).then(() => {
                if (request.billing.billingRequest) {
                    // let params = {
                    // //     ...creation,
                    // //     status: 'completed'
                    // //     // status: 'completed'
                    // // }

                    // FirebaseUtils.updateRecord(request.city, 'billing_snapshot', request.billing.billingRequest, params).then(() => {

                    message.success(`Billing is done`);

                    // });
                }
            });
        },
        fields: [
            {
                type: 'input',
                field: 'invoice_no',
                caption: 'Invoice No ',
            },

            {
                type: 'upload',
                field: 'invoice',
                caption: 'Invoice ',
                maxSize: 1,
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },

    {
        isForm: true,
        caption: 'Delivery Note',
        path: '/delivery-form/:city/:id',
        model: 'delivery',
        post_status: 'Delivered',
        extraParams: (userRequest, params) => {
            let creation = {
                delivered_note_at: moment().format('DD/MM/YYYY HH:mm'),
                delivered_note_date: moment().startOf('day').valueOf(),
                delivered_note_time: moment().valueOf(),
            };

            return {
                accessories: {
                    ...userRequest.accessories,
                    status: 'Ready for Billing',
                },
                warranty: {
                    ...userRequest.warranty,
                    status: 'Ready for Punching',
                },
                delivery: {
                    ...userRequest.delivery,
                    ...creation,
                    ...params,
                    // status: 'pending',
                    status: 'Delivery Note Taken',
                    // process: 'complete'
                },
            };
        },
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Delivery Note Taken',
                body: `${request.allocation.vinNo} , ${request.variant} - ${request.color} : Delivery note has been taken for ${cName}. `,
            };
        },
        onSubmit: (request) => {
            // We need to create the delivery_request saved for reporting
            let creation = {
                delivery_note_date: moment().startOf('day').valueOf(),
                delivery_note_time: moment().valueOf(),
            };

            let params = {
                delivery: {
                    status: 'completed',
                    ...creation,
                },
            };

            return FirebaseUtils.updateBookingGeneric(request.bookingNo, params).then(() => {
                message.success(`Delivery is done`);

                // if (request.delivery.deliveryRequest) {

                //     let params = {
                //         status: 'completed',
                //         ...creation
                //     }

                //     FirebaseUtils.updateRecord(request.city, 'delivery_snapshot', request.delivery.deliveryRequest, params).then(() => {

                //     });
                // }
            });
        },
        fields: [
            {
                type: 'upload',
                field: 'note',
                caption: 'Delivery Note ',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },

    // Extended Warranty Punch
    {
        isForm: true,
        caption: 'Punch Extended Warrany Note',
        path: '/extended-warrany-form/:city/:id',
        model: 'warranty',
        post_status: 'Punched',
        extraParams: (userRequest, params) => {
            // We need to create the delivery_request saved for reporting
            let creation = {
                created_date: moment().startOf('day').valueOf(),
                created_time: moment().valueOf(),
                created_at: moment().format('DD/MM/YYYY HH:mm'),
            };

            return {
                warranty: {
                    ...creation,
                    ...params,
                    // ...userRequest.warranty,
                    process: 'complete',
                },
            };
        },
        fields: [
            {
                type: 'radio',
                options: ['Yes', 'No'],
                caption: 'Opting for Extended Warranty?',
                field: 'isPresent',
            },

            {
                type: 'upload',
                field: 'note',
                caption: 'Extended Warranty Note ',
                condition: (values) => {
                    return values.isPresent === 'Yes';
                },
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },
];

export const stockColumns = [
    {
        type: 'input',
        caption: 'Vin Number',
        field: 'Vin Number',
    },
    {
        field: 'Variant',
        type: 'input',
        caption: 'Variant',
    },
    {
        field: 'Stock Status',
        type: 'input',
        caption: 'Stock Status',
    },
    {
        field: 'Exterior Color Name',
        type: 'input',
        caption: 'Color',
    },

    {
        caption: 'Color Type',
        field: 'Color Type',
        type: 'input',
    },

    {
        caption: 'Variant Code',
        field: 'Variant Code',
        type: 'input',
    },

    {
        caption: 'Invoice Date',
        field: 'KMI Invoice Date',
        type: 'input',
    },

    // {
    //     caption: 'Soft Allocation',
    //     field: 'allocated.requestId',
    //     type: 'input',
    // },

    {
        caption: 'Status',
        field: 'update',
        type: 'input',
    },
];

/**
 * Schema used for Import
 */
export const stockSchema = {
    includeFirstRow: true,

    tableHeader: 2,
    tableRange: 0,

    tableIndex: 'Vin Number',
    importAll: true,

    rowIndex: 'Vin Number',
    columns: [
        {
            field: 'partNo',
            from: 'PART NO',
        },
        {
            field: 'partName',
            from: 'PART NAME',
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY',
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5',
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6',
        },
        {
            field: 'price',
            from: '__EMPTY_7',
        },

        {
            field: 'stockValue',
            from: 'AMT',
        },
        {
            field: 'hsn',
            from: 'HSN CD',
        },

        {
            field: 'supplier',
            from: 'SUPPLIER',
        },

        {
            field: 'category',
            default: 'Accessories',
        },
    ],

    custom_actions: [
        {
            onClick: (selected, city, callback) => {
                console.log('jhell', selected);

                Promise.all(
                    selected.map((entry) => {
                        var count = 0;

                        return FirebaseUtils.unblockVehicle(entry).then(() => {
                            count++;
                            console.log(count);
                        });
                    })
                ).then(() => {
                    message.success('Action done');

                    callback();
                });
            },
            caption: 'Reset',
            multiple: true,
        },
    ],
};

/**
 * Structure for Parts Listing and Add
 */
export const partsColumns = [
    {
        type: 'input',
        caption: 'Part No',
        field: 'partNo',
    },
    {
        field: 'partName',
        type: 'input',
        caption: 'Part Name',
    },
    {
        field: 'supplier',
        type: 'input',
        caption: 'Supplier',
    },
    {
        field: 'price',
        type: 'input',
        caption: 'Price',
    },

    {
        caption: 'Model',
        field: 'model',
        type: 'radio',
        options: ['Seltos', 'Sonet', 'Carnival'], // This has to come from db
    },

    {
        caption: 'Category',
        field: 'category',
        type: 'radio',
        options: ['Accessories', 'VAS', 'After Market', 'Extended Warranty'],
    },

    {
        caption: 'Inclusive of Tax',
        field: 'tax_inclusive',
        type: 'radio',
        options: ['Yes', 'No'],
    },

    {
        caption: 'CGST',
        field: 'cgst',
        type: 'input',
    },

    {
        caption: 'SGST',
        field: 'sgst',
        type: 'input',
    },

    {
        caption: 'IGST',
        field: 'igst',
        type: 'input',
    },

    {
        caption: 'Additional Tax',
        field: 'addition_tax',
        type: 'input',
    },
];

/**
 * Schema used for Import
 */
export const partsSchema = {
    tableHeader: 2,
    tableRange: 0,
    uploadIndex: 'PART NO', // Do we required an extra column like this
    tableIndex: 'partNo',
    custom_actions: [],
    rowIndex: 'partNo',
    columns: [
        {
            field: 'PART NO',
            from: 'PART NO',
        },

        {
            field: 'partNo',
            from: 'PART NO',
        },
        {
            field: 'partName',
            from: 'PART NAME',
        },
        {
            field: 'available',
            from: 'INVENTORY QUANTITY',
        },

        {
            field: 'onOrder',
            from: '__EMPTY_5',
        },
        {
            field: 'listPrice',
            from: '__EMPTY_6',
        },
        {
            field: 'price',
            from: '__EMPTY_7',
        },

        {
            field: 'stockValue',
            from: 'AMT',
        },
        {
            field: 'hsn',
            from: 'HSN CD',
        },

        {
            field: 'supplier',
            from: 'SUPPLIER',
        },

        {
            field: 'category',
            default: 'Accessories',
        },
    ],
};
