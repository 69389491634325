/**
 * One of the crucial components in the project ,
 *
 * Should mark one milestone
 *
 * Give the settlement according to ledger as per accounts and as per surge. Any errors notify to user for correction.
 *
 */

import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
    Table,
    Tabs,
    Popover,
    Button,
    Modal,
    Form,
    Select,
    message,
    Input,
    InputNumber,
    Radio,
    Row,
    Col,
    Popconfirm,
    Tag,
    Menu,
    Dropdown,
    Alert,
} from 'antd';

import Rupee from '../rupee/rupee';

import FirebaseUtils from '../../utils/firebase.utils';

import PendingSummary from '../pending-summary/pending-summary';

import { Typography } from 'antd';

import { useReactToPrint } from 'react-to-print';

import { GlobalContext } from '../../Store';

import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import InvoicePreview from '../../templates/invoice-preview/invoice-preview';

import { Requests, Cart, Billtypes, Settings, Bills, Vehicles, DocumentLogs, Users } from '../../models';

import moment from 'moment';

import './payable-entries.scss';

import StockTransferRequestCreator from '../vehicle-detail/stock-transfer-request-creator';

import CustomerEntriesTable from './../payable-entries/components/customer-entries-table';

import { cityKeys } from '../../modules/global-config';

var companyLogo = require('../../assets/images/kia-logo.png');

const { Title, Text } = Typography;

const { TextArea } = Input;

const { Option } = Select;

const { TabPane } = Tabs;

const { confirm } = Modal;

var cityKey = cityKeys;

const bankDetailsRows = [
    {
        key: '1',
        name: 'ACCOUNT NO',
    },
    {
        key: '2',
        name: 'BENEFICIARY NAME',
    },
    {
        key: '3',
        name: 'BANK',
    },
    {
        key: '4',
        name: 'IFSC',
    },
    {
        key: '5',
        name: 'BRANCH',
    },
    {
        key: '6',
        name: 'MICR',
    },
];

const bankDetailColumns = [
    {
        title: 'Bank Details for Fund Transfer',
        dataIndex: 'name',
        key: 'name',
    },
];

function MoreAction({ callback, data }) {
    const [visible, setVisible] = useState(false);

    const handleClick = (params) => {
        // console.log(params);

        switch (params.key) {
            case 'discount':
                setVisible(true);

                break;
            case 'item_1':
                break;
            default:
        }
    };

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="discount">Add Discount</Menu.Item>
            {/* <Menu.Item>Delete Item</Menu.Item> */}
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={menu} placement="bottomLeft">
                <Button size="small">
                    <MoreOutlined />
                </Button>
            </Dropdown>

            {/* Modal for Adding item Discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="More"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <DiscountModal
                    callback={(values) => {
                        setVisible(false);

                        callback(values);
                    }}
                    data={data}
                />
            </Modal>
            {/* Modal for Adding item Discount Ends */}
        </>
    );
}

function DiscountModal({ callback, data }) {
    const onFinish = (values) => {
        callback(values);
        // console.log(data);
    };

    return (
        <>
            <p>Mention the discount</p>

            <Form
                {...layout}
                name="basic"
                initialValues={{
                    remember: true,
                    discount_type: data.discount_type,
                    discount_value: data.discount_value,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="discount_type"
                    label="Discount"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a type',
                        },
                    ]}
                >
                    <Radio.Group defaultValue={data.discount_type}>
                        <Radio value="percent">In %</Radio>
                        <Radio value="amount">In Rs</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Value"
                    name="discount_value"
                    dependencies={['discount_type']}
                    rules={[
                        { required: true, message: 'Please input your amount' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('discount_type') === 'amount') {
                                    if (value > data.net_amount) {
                                        return Promise.reject(new Error('Discount should not exceed net amount.'));
                                    } else return Promise.resolve();
                                } else if (!value || getFieldValue('discount_type') === 'percent') {
                                    if (value > 100) {
                                        return Promise.reject(new Error('Discount should not exceed 100%.'));
                                    } else return Promise.resolve();
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        }),
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    name="remarks"
                    label="Remarks"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter remark',
                        },
                    ]}
                >
                    <TextArea />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
const ledgerColumns = [
    {
        title: '#',
        // dataIndex: 'index',
        render: (value, item, index) => index + 1,
    },

    {
        title: 'Date',
        // dataIndex: 'VDate',
        render: (record) => moment(record.VDate).format('DD/MM/YYYY'),
        sorter: (a, b) => moment(a.VDate, 'DD/MM/YYYY').unix() - moment(b.VDate, 'DD/MM/YYYY').unix(),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },

    {
        title: 'Type',
        // dataIndex: 'On Account Of',
        render: (text, record) => {
            return (
                <div style={{ maxHeight: '36px' }}>
                    <div>{record['OpActualAcHead']}</div>
                    <div style={{ fontSize: '10px', color: 'grey' }}>{record['Type of Payment']}</div>
                </div>
            );
        },
    },

    {
        title: 'Amount',
        dataIndex: 'Amount',
        render: (text, record) => {
            return (
                <div style={{ maxHeight: '36px' }}>
                    <div>{-record['Amount']}</div>
                </div>
            );
        },
    },
];

const receiptColumns = [
    {
        title: '#',
        // dataIndex: 'index',
        render: (value, item, index) => index + 1,
    },

    {
        title: 'Date',
        dataIndex: 'Receipt Date',
        sorter: (a, b) => moment(a['Receipt Date'], 'DD/MM/YYYY').unix() - moment(b['Receipt Date'], 'DD/MM/YYYY').unix(),
        sortDirections: ['ascend', 'descend', 'ascend'],
    },

    {
        title: 'Type',
        // dataIndex: 'On Account Of',
        render: (text, record) => {
            return (
                <div style={{ maxHeight: '36px' }}>
                    <div>{record['On Account Of']}</div>
                    <div style={{ fontSize: '10px', color: 'grey' }}>{record['Type of Payment']}</div>
                </div>
            );
        },
    },

    {
        title: 'Amount',
        dataIndex: 'Receipt Amount',
    },
];

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
};

/**
 *
 * Cart Table
 *
 *
 */
export default function PayableEntries({
    request,
    callback,
    booking,
    refresh,
    saveBreakup,
    ledger,
    snapshot,
    cart,
    columns,

    // Customer Entries
    customerReceipts,
    customerReceiptTotal,

    // Customer Entries Ends

    cs_accounts,
}) {
    console.log(request);
    const [types, setTypes] = useState([]);
    const [vehicle, setVehicle] = useState([]);
    const [access, setAccess] = useState();
    console.log(cs_accounts);
    // Flag for Noting Mahe Customer
    let isMahe = false;

    // For Mahe Cases we neednt add KFC
    if (request.customer && request.customer.place) {
        if (
            request.customer.place &&
            request.customer.place.toLowerCase().indexOf('mahe') === -1 &&
            request.customer.place.toLowerCase().indexOf('pondicherry') === -1 &&
            request.customer.place.toLowerCase().indexOf('puducherry') === -1
        ) {
        } else {
            isMahe = true;
        }
    }

    if (cs_accounts) {
        // Flag to make sure the head is selected
        // If head is not selected , DMS values has to be given preference
        var isHeadSelected = request.accounts && request.accounts.ac_code ? true : false;
    }

    useEffect(() => {
        // Loading the bill types
        Billtypes.get().then((result) => {
            setTypes(result.bill_types);
        });

        return () => { };
    }, []);

    // Below is for assigning the cart values to
    const [breakup, setBreakup] = useState([]);

    const [receipts, setReceipts] = useState([]);

    const [received, setReceived] = useState(0);

    const [settlementVisibility, setSettlementVisibility] = useState(true);

    const [billed, setBilled] = useState(false);

    const [visible, setVisible] = useState(false);

    const [form] = Form.useForm();

    const print = useRef();

    //use for print block
    const enableBankDetails = useRef();

    const { user } = useContext(GlobalContext);

    const [balance, setBalance] = useState(0);

    const [finalvalues, setFinalvalues] = useState({});

    let reOpenClosedSettlement  = Users.hasPermission(user,'open_settlement');

    if (cs_accounts) {
        var ledgerReceipts = ledger.filter((entry) => entry.Trn === -1);
    }

    const address =
        user.locations[0] == 'kozhikode'
            ? { add1: 'Kannur Rd, Athanikkal, West Hill,', add2: 'Kozhikode, Kerala 673005' }
            : user.locations[0] == 'kannur'
                ? { add1: 'Kairali Nagar, Thottada(PO),', add2: 'Kannur, Kerala 670007' }
                : user.locations[0] == 'malappuram'
                    ? { add1: 'N.H.213, Ramapuram, Panangangara,', add2: 'Malappuram, Kerala 679321' }
                    : user.locations[0] == 'kasargod'
                        ? { add1: '4th Mile, Santhosh Nagar,', add2: 'Kasargod, Kerala, 671541' }
                        : user.locations[0] == 'kondotty'
                            ? { add1: 'Near BSNL Office, Edavannappara, Airport Road,', add2: 'Kondotty, Malappuram, Kerala , 673638' }
                            : user.locations[0] == 'vadakara'
                                ? { add1: 'Palolipalam, Puthuppanam,', add2: 'Vadakara, Kozhikode, 673105' }
                                : { add1: 'Near IOC Petrol Pump, Tirur-Malappuram Road,', add2: 'Tirur, Malappuram, Kerala, 676101' };

    useEffect(() => {
        // To assign the cart values
        getBreakup();

        if (request.bookingNo) {
            // Get DMS Receipts
            findMatchingReceipts(request, breakup);

            if (request.delivery && request.delivery.settlementTaken) {
                setSettlementVisibility(false);
            }

            let query = [
                {
                    field: 'bookingNo',
                    value: request.bookingNo,
                },
                {
                    field: 'type',
                    operator: 'in',
                    value: ['Accessories', 'VAS', 'After Market'],
                },
            ];
            Cart.get(query).then((result) => {
                result = result.cart.filter((r) => r.status != 'removed');
                console.log(result);
                if (result.length != 0) {
                    setBilled(false);
                } else {
                    setBilled(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        findBalanceToPay();
        getvehicle();
    }, [customerReceiptTotal, ledger]);

    function getBreakup() {
        // List
        let list = [];

        // We group the cart items to find individual total
        let categories = groupBy(cart, 'type');

        // Iterate to add each items
        cart.sort((a, b) => (a.order ? (a.order > b.order ? 1 : -1) : 1)).forEach((item) => {
            if (item.type === 'basic') {
                if (item.subItems) {
                    item.subItems
                        .sort((a, b) => (a.order ? (a.order > b.order ? 1 : -1) : 1))
                        .forEach((subItem) => {
                            // Remove rejected and removed entries
                            if (['removed', 'rejected'].indexOf(subItem.status) === -1) {
                                if (subItem.description && subItem.description === 'Statutory Charges') {
                                    if (!subItem.status) {
                                        list.push({
                                            ...subItem,
                                            cart: item,
                                        });
                                    }
                                } else {
                                    if (item.table) {
                                        let body = {
                                            // ...item,
                                            ...subItem,
                                            table: 'bill-details',
                                        };

                                        list.push({
                                            ...body,
                                            cart: item,
                                        });
                                    } else {
                                        list.push({
                                            ...subItem,
                                            cart: item,
                                            // ...item,
                                        });
                                    }
                                }
                            }
                        });
                } else {
                    list.push({
                        ...item,
                        cart: item,
                    });
                }
            }
        });

        // Find Acc/Vas Total
        let vasTotal = 0,
            accTotal = 0,
            vasBillsTotal = 0,
            AccBillTotal = 0,
            afterTotal = 0,
            AmBillTotal = 0;

        if (categories && categories['VAS']) {
            vasTotal = groupTotal(categories['VAS']);
        }

        if (categories && categories['VAS Bills']) {
            vasBillsTotal = groupBillTotal(categories['VAS Bills']);
        }

        if (categories && categories['Accessories Bills']) {
            AccBillTotal = groupBillTotal(categories['Accessories Bills']);
        }

        if (categories && categories['After Market Bills']) {
            AmBillTotal = groupBillTotal(categories['After Market Bills']);
        }

        if (categories && categories['After Market'] && !categories['Accessories']) {
            afterTotal = groupTotal(categories['After Market']);
        }

        if (categories && categories['Accessories']) {
            accTotal = groupTotal(categories['Accessories']);

            // accTotal = accTotal + groupTotal(categories['After Market']);

            if (categories && categories['After Market']) {
                accTotal = accTotal + groupTotal(categories['After Market']);
            }

            let cess = 1 / 100;

            let notTaxableTotal = findNonTaxableTotal(categories['Accessories']);

            if (!isMahe) {
                // consider KFC for before july 31
                if (request && request.accessories && request.accessories.invoiced_date <= 1627669800000) {
                    let cessValue = cess * notTaxableTotal;

                    accTotal = accTotal + cessValue;
                }
            }
        }

        // Add the entry if there is acc/vas
        if (vasTotal > 0) {
            list.push({
                description: 'Value Added Services',
                type: 'VAS',
                price: vasTotal.toFixed(2),
            });
        }

        if (vasBillsTotal > 0) {
            list.push({
                description: 'Value Added Services Bills',
                type: 'VAS Bills',
                price: vasBillsTotal.toFixed(2),
            });
        }

        if (AccBillTotal > 0) {
            list.push({
                description: 'Accessory Bills',
                type: 'Accessories Bills',
                price: AccBillTotal.toFixed(2),
            });
        }

        if (AmBillTotal > 0) {
            list.push({
                description: 'After Market Bills',
                type: 'After Market Bills',
                price: AmBillTotal.toFixed(2),
            });
        }

        if (afterTotal > 0) {
            list.push({
                description: 'After Market (Accessories)',
                type: 'After Market',
                price: afterTotal,
            });
        }

        if (accTotal > 0) {
            list.push({
                description: 'Accessories Summary',
                type: 'Accessories',
                price: accTotal.toFixed(2),
            });
        }

        // Is present

        if (categories['Extended Warranty'] && categories['Extended Warranty'].length) {
            list.push(categories['Extended Warranty'][0]);
        } else {
            // Add Extended Warranty
            list.push({
                type: 'Extended Warranty',
                description: 'Extended Warranty',
                price: 0,
            });
        }

        if (categories && categories['insurance']) {
            var item = categories['insurance'][0];

            list.push({
                type: item.type,
                name: item.description || item.name,
                description: item.name,
                price: item.price,
            });
        }

        if (categories['additional']) {
            // Check if there are any additional entries
            if (categories && categories['additional']) {
                categories['additional'].forEach((item) => {
                    list.push({
                        id: item.id,
                        type: item.type,
                        name: item.name || item.description,
                        description: item.description,
                        price: item.price,
                    });
                });
            }
        }

        // Add the basic data to it
        list = list.map((entry) => ({
            ...entry,
            request,
            booking,
            callback: refresh,
        }));

        // return list;

        setBreakup(list);
    }

    /**
     *
     * @param {*} categories
     * @returns
     */
    function groupTotal(categories) {
        return categories.reduce((a, b) => a + parseFloat(parseFloat(b['price'] || 0) * (b['quantity'] ? b['quantity'] : 1)), 0);
    }

    /**
     *
     * @param {*} categories
     * @returns
     */
    function groupBillTotal(categories) {
        return categories.reduce((a, b) => a + parseFloat((b['net_amount'] || 0).toFixed(2)), 0);
    }

    /**
     * Function to find the non taxable value to calculate the scss
     *
     * @param {*} parts
     */
    function findNonTaxableTotal(parts) {
        let total = 0;

        parts
            .filter((entry) => {
                return !entry.subType;
            })
            .forEach((entry) => {
                if (entry.taxable_value) {
                    // Should we roud sum of sgst and cgst
                    let taxableValue = parseFloat(entry.taxable_value);

                    total += taxableValue;
                } else {
                    // Should we roud sum of sgst and cgst
                    let taxableValue =
                        ((parseFloat(entry.price) * (entry.quantity ? entry.quantity : 1)) /
                            (100 + parseFloat(entry.sgst ? entry.sgst : 9) + parseFloat(entry.cgst ? entry.cgst : 9))) *
                        100;

                    total += taxableValue;
                }
            });

        return Math.round(total);
    }

    /**
     * Get Vehicle data
     * @returns
     */
    function getvehicle() {
        return Vehicles.getRecord(request.allocation.vinNo).then((result) => {
            setVehicle(result);
        });
    }

    const showModal = () => {
        setVisible(true);
    };

    let isDelivered = false;

    // To track if the vehicle is delivered
    if (request.delivery) {
        if (['Delivered', 'Delivery Completed'].indexOf(request.delivery.status) !== -1) {
            isDelivered = true;
        }
    }

    /**
     * Add an Adjustment Entry
     */
    const handleItemSubmit = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();

                //Prepare item to add to cart
                let creation = {
                    created_at: moment().format('DD/MM/YYYY HH:mm'),
                    created_date: moment().startOf('day').valueOf(),
                    created_time: moment().valueOf(),
                    deleted_at: null,
                    deleted_by: null,
                };

                let rate = 0;

                if (values.itemType === 'discount') {
                    rate = -1 * values.itemValue;
                } else {
                    rate = values.itemValue;
                }

                let itemToAdd = {
                    ...cart[0],
                    bookingNo: request.bookingNo,
                    requestId: request.id,
                    customerID: request.customer.customerID,
                    type: 'additional',
                    vinNo: request.allocation.vinNo,
                    description: values.itemName,
                    price: rate,
                    dealerCode: booking['Dealer Code'],
                    ...creation,
                };

                FirebaseUtils.addCartItem(itemToAdd).then((res) => {
                    // console.log('Added to cart');
                });
                setVisible(false);
                refresh();
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    /**
     *
     */
    function showConfirm() {
        var condition = balance <= 0;

        confirm({
            title: 'Close the File',
            icon: condition ? <ExclamationCircleOutlined /> : null,
            content: !condition
                ? 'Customer has balance amount to pay. Please collect the amount to close the file'
                : 'Are you sure you want to close this file. This action is one time',
            onOk() {
                closeFile();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    /**
    * Close the ReOpened settlement Method
    */
    const handleReOpenSettlement = () => {

        let creation = {
            settled_at: moment().format('DD/MM/YYYY HH:mm'),
            settled_date: moment().startOf('day').valueOf(),
            settled_time: moment().valueOf(),
        };

        var params = {
            delivery: {
                ...request.delivery,
                ...creation,
                process: 'complete',
                status: 'Settlement Ready',
                settlementTaken: true,
            },
            actual_delivery: {
                ...request.actual_delivery,
                ...creation,
                process: 'complete',
                status: 'Settlement Ready',
                settlementTaken: true,
            },
            is_reopen_settlement: false
        };

        // File Closed
        return Requests.update(request.id, params).then(async () => {
            let batch = FirebaseUtils.getApp().batch();
            const requestRef = Requests.getRecordReference(request.id);
            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs
            await DocumentLogs.recordLog('request', request, params, user, batch, requestRef).then(async () => {
                message.success('Successfully closed re-opened settlement')
                refresh();
            })
        })
    };

    /**
    * Close the settlement Method
    */
    const handleGenericSettlement = () => {
        if (billed) {
            var condition = balance <= 0;

            // If tolerance is met allow closing
            if (condition) {
                showConfirm();
            } else {
                showConfirm();
            }
        } else {
            message.error('Please make sure Accessory /VAS /AfterMarket items are billed before proceeding with settlement!');
        }
    };

    /**
    * Re-Open the settlement Method
    */
    const openSettlement = () => {

        var params = {
            delivery: {
                ...request.delivery,
                process: 'incomplete',
                status: 'Settlement Pending',
                settlementTaken: false,
            },
            actual_delivery: {
                ...request.actual_delivery,
                status: 'Settlement Pending',
                process: 'incomplete',
                settlementTaken: false,
            },
            is_reopen_settlement: true
        };

        return Requests.update(request.id, params).then(async () => {
            let batch = FirebaseUtils.getApp().batch();
            const requestRef = Requests.getRecordReference(request.id);
            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs
            await DocumentLogs.recordLog('request', request, params, user, batch, requestRef).then(async () => {
                message.success('Successfully re-opened settlement for this request')
                refresh();
            })
        })
    };

    /**
     * Close the file
     */

    function closeFile() {
        const hide = message.loading('Closing settlement...', 0);

        setTimeout(hide, 2500);

        let query = [
            {
                field: 'bookingNo',
                value: booking['Booking No'],
            },
        ];

        Cart.get(query).then((result) => {
            Promise.all(
                result.cart
                    .filter((item) => {
                        return (
                            item.type &&
                            (item.table !== 'bills' || item.table !== 'bill-details' || !item.table) &&
                            (item.status !== 'removed' || !item.status)
                        );
                    })
                    .map((item) => itemsDetails(item))
            )
                .then(() => {
                    let total = 0;

                    breakup.forEach((entry) => {
                        total += parseFloat(entry.price);
                    });

                    let billObj = {
                        created_at: moment().format('DD/MM/YYYY HH:mm'),
                        created_date: moment().startOf('day').valueOf(),
                        created_time: moment().valueOf(),
                        deleted_at: null,
                        deleted_by: null,
                        bookingNo: cart[0].bookingNo,
                        customerID: cart[0].customerID,
                        dealerCode: cart[0].dealerCode,
                        authorised_by: user.name,
                        total: total,
                        fund_recieved: received,
                        balance_to_pay: parseFloat(total - received).toFixed(2),
                    };

                    return FirebaseUtils.addBillingStatement(billObj);
                })
                .then((res) => {
                    message.success('Settlement closed successfully.');

                    let creation = {
                        settled_at: moment().format('DD/MM/YYYY HH:mm'),
                        settled_date: moment().startOf('day').valueOf(),
                        settled_time: moment().valueOf(),
                    };

                    var params = {
                        accounts: {
                            ...request.accounts,
                            status: 'File Closed',
                        },
                        delivery: {
                            ...request.delivery,
                            ...creation,
                            status: 'Settlement Ready',
                            settlementTaken: true,
                        },
                        actual_delivery: {
                            ...request.actual_delivery,
                            ...creation,
                            status: 'Settlement Ready',
                            settlementTaken: true,
                        },
                    };

                    // File Closed
                    return FirebaseUtils.updateRequestGeneric(
                        // request.city,
                        request.id,
                        params,
                        'Accouts',
                        'Settlement',
                        'accounts'
                    );
                })
                .then(() => {
                    return Promise.all(
                        cart
                            .filter((item) => {
                                return (item.table !== 'bill-details' || !item.table) && (item.status !== 'removed' || !item.status);
                            })
                            .map((item) => FirebaseUtils.updateCartItem(item.id, { status: 'removed' }))
                    );
                })
                .then(() => {
                    refresh();
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    // Add each item to bill details table
    const itemsDetails = (item) => {
        let obj = {};
        if (item.type == 'basic') {
            obj = {
                bookingNo: item.bookingNo,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_date: moment().startOf('day').valueOf(),
                created_time: moment().valueOf(),
                customerID: item.customerID,
                dealerCode: item.dealerCode,
                description: item.description ? item.description : item.name,
                name: item.name ? item.name : item.description,
                price: item.price ? item.price : null,
                subItems: item.subItems ? item.subItems : [],
                total: item.total ? item.total : null,
                type: item.type,
                vinNo: item.vinNo ? item.vinNo : null,
                color: item.color ? item.color : null,
                deleted_at: null,
                deleted_by: null,
            };
        } else {
            obj = {
                bookingNo: item.bookingNo,
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_date: moment().startOf('day').valueOf(),
                created_time: moment().valueOf(),
                customerID: item.customerID,
                dealerCode: item.dealerCode,
                description: item.description ? item.description : item.name,
                name: item.name ? item.name : item.description,
                order: item.order ? item.order : 1,
                price: item.price,
                quantity: item.quantity ? item.quantity : 1,
                type: item.type,
                deleted_at: null,
                deleted_by: null,
            };
        }
        return FirebaseUtils.addItemToBillDetails(obj)
            .then((res) => { })
            .then(() => {
                item = {
                    ...item,
                    status: 'removed',
                };
                let config = {
                    hideAlert: true,
                };
                return Cart.update(item.id, item, config);
            })
            .then(() => {
                console.log('Added to Bill details');
            });
    };

    /**
     *
     */
    function findMatchingReceipts(preference, breakup) {
        var queries = [
            {
                field: 'bookingId',
                value: preference.bookingNo.substring(5),
            },
        ];

        FirebaseUtils.getReceipts(preference.city, queries).then((result) => {
            setReceipts(result.receipts);

            let total = result.receipts.reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0);

            setReceived(total);

            // We need to save the total
            let breakupTotal = 0;

            breakup.forEach((entry) => {
                breakupTotal += parseFloat(entry.price);
            });

            saveBreakup({
                breakup,
                total: breakupTotal,
                received: total,
                balance: breakupTotal - total,
            });
        });
    }

    const getDateAndTime = () => {
        let date = new Date();
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold' }}>{moment(date).format('DD MMM YYYY')}</div>
                <div>
                    ,{'  '}
                    {moment(date).format('hh:mm a')}
                </div>
            </div>
        );
    };

    // findBalanceToPay();

    /**
     * Find balance to pay
     *
     * Below fucntion is repetition of code on the pending summary
     * component and will have to be refactored. lets finish work
     *
     */
    function findBalanceToPay() {
        var finalTotal,
            finalTotalAfterClearing,
            total = 0,
            receivedTotal = 0;

        breakup.forEach((entry) => {
            total += parseFloat(entry.price);
        });

        if (cs_accounts) {
            // Receipts
            var ledgerReceipts = ledger.filter((entry) => entry.Trn === -1);

            var clearedRecipt = ledgerReceipts.filter((entry) => entry.OpAcPDCStatus === 'R' || entry.OpAcPDCStatus === 'N');

            var clearedTotal = clearedRecipt.reduce((a, b) => a + parseInt(b['Amount']), 0);

            var cleared = -clearedTotal;

            // Cart
            var ledgerCart = ledger.filter((entry) => entry.Trn === 1);

            // Receipts
            var ledgerReceiptTotal = ledgerReceipts.reduce((a, b) => a + parseInt(b['Amount']), 0);

            // Cart Total
            var ledgerCartTotal = ledgerCart.reduce((a, b) => a + parseInt(b['Amount']), 0);

            //  #todo Below section will have to be optimized in future as there is enormous amount of rerendering happending for this
            // component . But it works

            // Below are description prepared for
            if (isHeadSelected) {
                receivedTotal = -ledgerReceiptTotal;
            } else {
                // If head is not selected , we consider dms receipts
                receivedTotal = received;
            }

            // Total Received total after clearing
            finalTotalAfterClearing = cleared;

            finalTotal = total - finalTotalAfterClearing;
        } else {
            //Considering customer receipt

            receivedTotal = customerReceiptTotal;

            finalTotal = total - receivedTotal;
        }

        setBalance(finalTotal);

        setFinalvalues({
            total: total,
            finalTotalAfterClearing: finalTotalAfterClearing,
            balance: finalTotal,
        });
    }

    breakup.map((item) => {
        item.callback = (discount) => {
            // console.log(discount);

            // refreshing after Extended Warranty edition for closed settlement 
            if(item.table==='bill-details'){
                refresh();
            }

            var val = {
                ...item,
                discount,
            };
            // console.log(val);

            // Update the cart and reload the calculation
            Cart.update(item.cart.id, discount).then(() => {
                // getCartItems(request);

                refresh();
            });

            return val;
        };
    });

    useEffect(() => {
        // for Disable print invoice
        Settings.getValue('print-invoice').then((result) => {
            var allowed = result.value.indexOf(user.role) !== -1;
            setAccess(allowed);
        });
        return () => { };
    }, []);


    /**
     * Conditions to allow re-open settlement for closed settlement
    */
    let allowReOpenSettlment =
        user.role === 'accounts' &&
        reOpenClosedSettlement;


    /**
     * Conditions to allow close settlement
     */
    let allowCloseSettlment =
        user.role === 'accounts' &&
        settlementVisibility &&
        request.actual_delivery &&
        request.actual_delivery.status === 'Settlement Pending' &&
        request.accessories &&
        request.accessories.status === 'Invoice' &&
        request.delivery &&
        request.delivery.status === 'Delivery Note Taken';

    return (
        <div className="cart-summary">
            <div className="vehicle-card document-status">
                {/* Summary for Display */}
                <div className="display-block">
                    <>
                        <div className="page-header">
                            <div>
                                <img className="logo-welcome" src={companyLogo} alt="Logo" />

                                <Title level={3}>{!settlementVisibility ? 'Final Settlement' : 'Proforma Invoice'}</Title>
                            </div>
                            <div className="page-blocks">
                                {/* Close Settlment Option */}

                                {allowCloseSettlment ? (
                                    <Popconfirm
                                        title="Are you sure you want to close settlement for this request ? "
                                        onConfirm={() => handleGenericSettlement()}
                                        onCancel={() => { }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="dashed" className="print-button">
                                            Close Settlement
                                        </Button>
                                    </Popconfirm>
                                ) : null}

                                {/* Close Settlment Option Ends */}

                                {/* Settlment Status */}

                                {request && request.delivery && request.delivery.settlementTaken ? (
                                    <Fragment>
                                        <Popconfirm
                                            title="Settlement for this request is closed. "
                                            visible={false}
                                            onVisibleChange={() => {
                                                message.success('Settlement is already closed.');
                                            }}
                                        >
                                            <a style={{ marginRight: '16px' }}>Settlement Closed</a>
                                        </Popconfirm>

                                        {/* Allow re-open settlement for closed settlement */}

                                        {allowReOpenSettlment ?
                                            <Popconfirm
                                                title="Are you sure you want to re-open settlement for this request ? "
                                                onConfirm={() => openSettlement()}
                                                onCancel={() => { }}
                                                okText="Yes"
                                                cancelText="No">
                                                <Button type="dashed" className="print-button">
                                                    Re-open Settlement
                                                </Button>
                                            </Popconfirm>
                                            : null
                                        }
                                    </Fragment>
                                ) : null}

                                {/* Close Settlment Option For Reopened Settlement*/}

                                {request.is_reopen_settlement && reOpenClosedSettlement && user.role === 'accounts' ?
                                    <Popconfirm
                                        title="Are you sure you want to close settlement for this request ? "
                                        onConfirm={() => handleReOpenSettlement()}
                                        onCancel={() => { }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="dashed" className="print-button">
                                            Close Settlement
                                        </Button>
                                    </Popconfirm>
                                    : null
                                }
                                {/* Close Settlment Option For Reopened Settlement Ends */}


                                {request &&
                                    request.actual_delivery &&
                                    request.actual_delivery.settlementTaken &&
                                    request.actual_delivery.status !== 'Gatepass Taken' &&
                                    user.role === 'accounts' ? (
                                    <StockTransferRequestCreator
                                        // style={{ marginRight: '16px' }}
                                        user={user}
                                        vehicle={vehicle}
                                        caption={'Generate Gate Pass'}
                                        mode={'delivery'}
                                        params={request.allocation.vinNo}
                                        required={false}
                                        request={request}
                                        Callback={(values) => { }}
                                    ></StockTransferRequestCreator>
                                ) : null}

                                {/* Actual Billing Ends */}
                                {request.id && ['admin', 'sm', 'tl'].indexOf(user.role) !== -1 && (
                                    <RetailButton booking={booking} request={request} refresh={refresh} balance={balance} finalvalues={finalvalues} />
                                )}

                                {request.id && ['admin', 'sm', 'tl', 'kec'].indexOf(user.role) !== -1 && (
                                    <DeliveryButton booking={booking} request={request} refresh={refresh} />
                                )}
                                {access ? (
                                    <Button type="dashed" className="print-button" onClick={handlePrint}>
                                        Print Invoice
                                    </Button>
                                ) : null}

                                {request && request.pricing && request.pricing.status == 'completed' ? (
                                    <Tag color="green">Verified by accounts</Tag>
                                ) : null}
                            </div>
                        </div>

                        {/* In/Out Summary */}

                        <div className="breakup">
                            <div className="table-card" title="Fund to be Collected">
                                <div className="breakup-wrapper">
                                    <div>
                                        <p level={3}>
                                            <strong>Fund to be Collected</strong>
                                        </p>
                                    </div>
                                    <div className="button-container">
                                        {(user.role === 'accounts' || user.role === 'accessories' || user.role === 'sm') && !isDelivered ? (
                                            <Button type="dashed" onClick={showModal}>
                                                Add/Discount
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <PendingSummary
                                            cs_accounts={cs_accounts}
                                            ledger={ledger}
                                            cart={cart}
                                            columns={columns}
                                            received={received}
                                            breakup={breakup}
                                            request={request}
                                            user={user}
                                            isHeadSelected={isHeadSelected}
                                            customerReceiptTotal={customerReceiptTotal}
                                            refresh={() => {
                                                refresh();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div title="Fund Received" className="table-card fund-received">
                                <p level={3}>
                                    <strong>Fund Received</strong>
                                </p>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Tabs defaultActiveKey={isHeadSelected ? 0 : 1}>
                                            {/* {ledgerReceipts.length ? ( */}
                                            {isHeadSelected && cs_accounts ? (
                                                <>
                                                    <TabPane tab="Summary" key="0">
                                                        <LedgerSummary ledgerReceipts={ledgerReceipts} receipts={ledgerReceipts} />
                                                    </TabPane>

                                                    {/* Customer Entries Display */}
                                                    {/* {
                                                        customerReceipts && customerReceipts.length
                                                            ?
                                                            <TabPane tab="Customer Receipts" key="1">
                                                                <CustomerEntriesTable receipts={customerReceipts} />
                                                            </TabPane>
                                                            :
                                                            null
                                                    } */}
                                                    {/* Customer Entries Display Ends */}
                                                </>
                                            ) : (
                                                <>
                                                    {/* Customer Entries Display */}
                                                    {customerReceipts && customerReceipts.length ? (
                                                        <TabPane tab="Customer Receipts" key="0">
                                                            <CustomerEntriesTable receipts={customerReceipts} />
                                                        </TabPane>
                                                    ) : null}
                                                    {/* Customer Entries Display Ends */}
                                                </>
                                            )}

                                            {/* ) : null} */}

                                            <TabPane tab="DMS Receipts" key="1">
                                                <ReceivedSummary receipts={receipts} />
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* In/Out Summary Ends */}
                    </>
                </div>
                {/* Summary for Display Ends */}

                {/* Below is only for printing */}
                <div className="print-block">
                    <WrapperClass ref={print}>
                        <div className="page-header" style={{ borderBottom: '1px dashed #2f3e4c' }}>
                            <div>
                                <img className="logo-welcome" src={companyLogo} alt="Logo" />

                                <Title level={3}>{!settlementVisibility ? 'Final Settlement' : 'Proforma Invoice'}</Title>
                            </div>

                            <div>
                                <div className="address-block">
                                    <div style={{ fontSize: '12px' }}>DKH Kia ,</div>
                                    <div style={{ fontSize: '10px' }}>{address['add1']} </div>
                                    <div style={{ fontSize: '10px' }}>{address['add2']}</div>
                                </div>
                                <div className="date-and-time" style={{}}>
                                    {getDateAndTime()}
                                </div>
                            </div>
                        </div>

                        {/* In/Out Summary */}

                        <div gutter={0} className="detail-wrapper" style={{ margin: '25px 0px 25px 0px' }}>
                            <Row>
                                <Col span={request.billing && request.billing.invoice_no ? 8 : 12}>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>Customer</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {request.customer.customerName.toUpperCase()}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>Place</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px',
                                                    width: '50%',
                                                }}
                                            >
                                                {request.customer.place}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>KEC</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {request.kec.toUpperCase()}
                                            </h3>
                                        </Row>
                                    </div>
                                </Col>

                                <Col span={request.billing && request.billing.invoice_no ? 8 : 12}>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>Phone</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {request.customer && request.customer.phone}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>Variant</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {request.variant}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span style={{ fontSize: '10px' }}>Vin Number</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3
                                                style={{
                                                    margin: '2px 0px',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {request.allocation.vinNo}
                                            </h3>
                                        </Row>
                                    </div>
                                </Col>

                                {request.billing && request.billing.invoice_no ? (
                                    <Col span={8}>
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <span style={{ fontSize: '10px' }}>Invoice No</span>
                                            </Row>
                                            <Row span={12}>
                                                <h3
                                                    style={{
                                                        margin: '2px 0px',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {request.billing.invoice_no}
                                                </h3>
                                            </Row>
                                        </div>
                                    </Col>
                                ) : null}
                            </Row>
                        </div>

                        <Row>
                            <Col span={12}>
                                <div title="Fund to be Collected">
                                    <div gutter={0} className="detail-wrapper">
                                        <div className="detail-element">
                                            <PendingSummary
                                                cs_accounts={cs_accounts}
                                                ledger={ledger}
                                                // cart={cart}
                                                // columns={columns}
                                                received={received}
                                                breakup={breakup}
                                                // request={request}
                                                // user={user}
                                                enableBankDetails={enableBankDetails}
                                                isHeadSelected={isHeadSelected}
                                                customerReceiptTotal={customerReceiptTotal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={12}>
                                <div title="Fund Received">
                                    <div gutter={0} className="detail-wrapper">
                                        <div className="detail-element">
                                            {isHeadSelected && cs_accounts ? (
                                                <LedgerSummary ledgerReceipts={ledgerReceipts} receipts={ledgerReceipts} />
                                            ) : (
                                                <CustomerEntriesTable receipts={customerReceipts} />
                                                // <ReceivedSummary receipts={receipts} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {enableBankDetails.current < -2000 ? (
                            <div
                                className="bank-details-section"
                                style={{
                                    position: 'absolute',
                                    bottom: settlementVisibility ? '125px' : '235px',
                                    right: 0,
                                    left: 0,
                                }}
                            >
                                <div style={{ marginBottom: '5px', fontSize: '10px' }}>
                                    Please fill the bank details for us to send the refund amount
                                </div>
                                <Table dataSource={bankDetailsRows} columns={bankDetailColumns} pagination={false} />
                            </div>
                        ) : null}

                        <div
                            className="contact-block"
                            style={{
                                position: 'relative',
                                bottom: settlementVisibility ? 0 : '0px',
                                right: 0,
                                left: 0,
                            }}
                        >
                            <div style={{ color: 'black' }}>For queries please contact : +91 9947445566</div>
                        </div>

                        {!settlementVisibility ? (
                            <div style={{ borderBottom: '1px dashed #2f3e4c', position: 'relative', bottom: '-5px', left: 0, right: 0 }}> </div>
                        ) : null}

                        <div
                            className="gate-pass-block"
                            style={{
                                border: '1px solid #f0f0f0',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                textAlign: 'center',
                                height: '300px',
                                position: 'relative',
                                bottom: '5px',
                                left: 0,
                                right: 0,
                            }}
                        >
                            {!settlementVisibility ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        textAlign: 'center',
                                        height: '90px',
                                        position: 'relative',
                                        bottom: '-20px',
                                        marginLeft: '10px',
                                        left: 0,
                                        right: 0,
                                    }}
                                >
                                    <div>
                                        <Title style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'left' }}>Gate Pass</Title>

                                        <div
                                            className="details-section"
                                            style={{
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginBottom: '10px',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <div style={{ fontSize: '14px', marginRight: '75px' }}>
                                                Vehicle Number: {<div style={{ fontWeight: 'bold' }}> {request.allocation.vinNo}</div>}
                                            </div>
                                            <div style={{ fontSize: '14px', marginLeft: '75px' }}>
                                                Name: {<div style={{ fontWeight: 'bold' }}> {request.customer.customerName.toUpperCase()}</div>}
                                            </div>
                                            {/* <div style={{ fontSize: '14px' }}>Valid till : 3PM : 2PM April 22</div> */}
                                            {/* <div style={{ fontSize: '12px', color: 'grey' }}>This gate pass has to be presented at the security.</div> */}
                                        </div>
                                        {/* <div className="qr-section" style={{ flexBasis: '10%', display: 'flex' }}> */}
                                        {/* <img
                                    style={{ width: '100%', justifyContent: 'center' }}
                                    className="qr-logo"
                                    src={qrImage}
                                /> */}
                                        {/* </div> */}

                                        <div
                                            className="details-section"
                                            style={{
                                                flexBasis: '50%',
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly',
                                                marginTop: '5px',
                                            }}
                                        >
                                            <div style={{ fontSize: '14px', marginRight: '50px' }}>Model: {request.model}</div>
                                            <div style={{ fontSize: '14px', marginLeft: '20px', marginRight: '50px' }}>
                                                Variant: {request.variant}
                                            </div>
                                            <div style={{ fontSize: '14px', marginLeft: '20px' }}>Color: {request.color}</div>
                                            <div style={{ fontSize: '14px', marginLeft: '20px', marginRight: '50px' }}>Kec: {request.kec}</div>
                                        </div>
                                        <div
                                            className="details-section"
                                            style={{
                                                textAlign: 'left',
                                                flexDirection: 'row',
                                                marginTop: '10px',
                                            }}
                                        >
                                            Date: {moment().format('DD/MM/YYYY')}
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div
                                className="signature-block"
                                style={{
                                    border: '1px solid #f0f0f0',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    textAlign: 'center',
                                    height: '90px',
                                    position: 'absolute',
                                    bottom: settlementVisibility ? '25px' : '25px',
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <div
                                    className="signature-item"
                                    style={{
                                        flexBasis: '25%',
                                        borderRight: '1px solid #f0f0f0',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '25px',
                                            borderBottom: '1px solid #f0f0f0',
                                            padding: '1px 0px',
                                        }}
                                    >
                                        Accessories Department
                                    </div>
                                </div>
                                <div
                                    className="signature-item"
                                    style={{
                                        flexBasis: '25%',
                                        borderRight: '1px solid #f0f0f0',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '25px',
                                            borderBottom: '1px solid #f0f0f0',
                                            padding: '1px 0px',
                                        }}
                                    >
                                        Accounts Department
                                    </div>
                                    <div
                                        style={{
                                            color: 'grey',
                                            fontSize: '10px',
                                            position: 'absolute',
                                            bottom: '2px',
                                            left: '26%',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Authorised by{' '}
                                        <div
                                            style={{
                                                color: 'black',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {user && user.name}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="signature-item"
                                    style={{
                                        flexBasis: '25%',
                                        borderRight: '1px solid #f0f0f0',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '25px',
                                            borderBottom: '1px solid #f0f0f0',
                                            padding: '1px 0px',
                                        }}
                                    >
                                        Sales Department
                                    </div>
                                </div>
                                <div className="signature-item" style={{ flexBasis: '25%' }}>
                                    <div
                                        style={{
                                            height: '25px',
                                            borderBottom: '1px solid #f0f0f0',
                                            padding: '1px 0px',
                                        }}
                                    >
                                        Customer
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* In/Out Summary Ends */}
                    </WrapperClass>
                </div>
                {/* Print Block Ends */}

                {/* Bill Preview Modal */}
                {/* <Modal
                    visible={preview}
                    title="Vehicle Tax Invoice"
                    width="60%"
                    onCancel={() => {
                        setPreview(false);
                    }}
                    footer={[
                        <Button key="submit" type="primary" onClick={handleProceed}>
                            Proceed
                        </Button>,
                    ]}
                >



                </Modal> */}
                {/* Bill Preview Modal Ends */}

                {/* Add/Discount Modal */}
                <Modal
                    visible={visible}
                    title="Add An Invoice Item "
                    onOk={handleItemSubmit}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="submit" type="primary" onClick={handleItemSubmit}>
                            Submit
                        </Button>,
                    ]}
                >
                    <Form form={form} {...layout} name="new-record" layout="vertical" initialValues={{}}>
                        <Form.Item
                            name="itemType"
                            label="Invoice Stats"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a type',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="addition">Addition</Radio>
                                <Radio value="discount">Discount</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {/* Bill Types */}
                        <Form.Item
                            name="bill_type"
                            label="Bill Type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a type',
                                },
                            ]}
                        >
                            <Select placeholder="Select a bill type">
                                {types.map((type, index) => (
                                    <Option value={type.id} key={index}>
                                        {type.description}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* Bill Types */}

                        <Form.Item
                            name={'itemName'}
                            label="Item Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter an item name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name={'itemValue'}
                            label="Item Value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter an item value',
                                },
                            ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>

                        <Form.Item
                            name={'remarks'}
                            label="Remarks"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter an remarks',
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Form>
                </Modal>
                {/* Add/Discount Modal Ends */}
            </div>
        </div>
    );
}

/**
 *
 * Table to show summary of all the received data
 * DMS Receipts
 *
 * @param {*} param0
 */
function ReceivedSummary({ receipts }) {
    return (
        <Table
            pagination={false}
            // scroll={{ x: true }}

            // scroll={{ x: true, y: 750 }}
            rowKey={(record, key) => key}
            columns={receiptColumns}
            dataSource={receipts}
            size="small"
            summary={(pageData) => {
                let total = pageData.reduce((a, b) => a + parseInt(b['Receipt Amount'].split(',').join('')), 0);

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>Total</Table.Summary.Cell>

                            {/* <Table.Summary.Cell>
                            </Table.Summary.Cell> */}

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={total}></Rupee>
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        />
    );
}

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const tailLayout = {
    wrapperCol: { span: 16 },
};

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

/**
 *
 * Table to show summary of all the received data
 *
 * @param {*} param0
 */
function LedgerSummary({ receipts, received }) {
    return (
        <Table
            pagination={false}
            // scroll={{ x: true }}

            // scroll={{ x: true, y: 750 }}
            rowKey={(record, key) => key}
            columns={ledgerColumns}
            dataSource={receipts}
            size="small"
            summary={(pageData) => {
                let total = pageData.reduce((a, b) => a + parseInt(b['Amount']), 0);

                return (
                    <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell></Table.Summary.Cell>

                            <Table.Summary.Cell>Total</Table.Summary.Cell>

                            {/* <Table.Summary.Cell>
                            </Table.Summary.Cell> */}

                            <Table.Summary.Cell>
                                <Text>
                                    <Rupee value={-total}></Rupee>
                                </Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                );
            }}
        />
    );
}

/**
 *
 *
 * @returns
 */
function DeliveryButton({ request, refresh }) {
    const [access, setAccess] = useState();

    const { user } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    var completion = [
        {
            step: 'Insurance Issued',
            department: 'insurance',
        },
        {
            step: 'Invoice',
            department: 'accessories',
        },
        {
            department: 'rto',
            step: 'HSRP Fitment Completed',
        },
        {
            department: 'delivery',
            step: 'Delivery Scheduled',
        },
    ];

    var isCompleted = true;

    completion.forEach((process) => {
        let { department, step } = process;

        // Only if the process has reached there

        if (department === 'delivery') {
            if (isCompleted && request[department] && request[department].schedule !== step) {
                isCompleted = false;
            }
        } else {
            if (isCompleted && request[department] && request[department].status !== step) {
                isCompleted = false;
            }
        }
    });

    useEffect(() => {
        // Load who all can initiate
        Settings.getValue('billing-initiator').then((result) => {
            var allowed = result.value.indexOf(user.role) !== -1;

            setAccess(allowed);
        });

        return () => { };
    }, []);

    /**
     *
     */
    function initiateDelivery() {
        let creation = Requests.getCreation();

        var params = {
            actual_delivery: {
                ...request.actual_delivery,
                status: 'Settlement Pending',
                ...creation,
            },
        };

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        Requests.updateRequest(request.id, params, 'Delivery Initiated', 'Delivery has been initiated', 'delivery', batch).then(async () => {
            setVisible(false);

            await batch.commit();

            refresh();
        });
    }

    return (
        <>
            {request.actual_delivery && request.actual_delivery.status === 'pending' ? (
                <>
                    <Button
                        type="dashed"
                        className="print-button"
                        onClick={() => {
                            setVisible(true);
                        }}
                    >
                        Initiate Delivery
                    </Button>
                </>
            ) : (
                <Tag color={'orange'}>{request.actual_delivery && request.actual_delivery.status}</Tag>
            )}

            {/* Modal for Adding item Discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="More"
                visible={visible}
                okText="Okay"
                onOk={() => { }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                {/* Completion Status */}
                {!isCompleted ? <CompletionStatus milestone={'delivery'} conditions={completion} request={request} /> : null}
                {/* Completion Status Ends */}

                {isCompleted ? (
                    <Popconfirm
                        title="Are you sure you want to proceed with delivery ? "
                        onConfirm={() => initiateDelivery()}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="dashed" disabled={!isCompleted} style={{ marginLeft: '10px' }}>
                            Initiate Delivery
                        </Button>
                    </Popconfirm>
                ) : null}
            </Modal>
            {/* Modal for Adding item Discount Ends */}
        </>
    );
}

/**
 *
 *
 * @returns
 */
function RetailButton({ request, booking, refresh, balance, finalvalues }) {
    const [access, setAccess] = useState();

    const [data, setData] = useState([]);

    const [lastNumber, setLastNumber] = useState({});

    const { user } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const [alert, setAlert] = useState(false);

    const [vehicle, setVehicle] = useState();

    const [tolerance, setTolerance] = useState(100);

    var completion = [
        {
            step: 'Disbursement',
            department: 'finance',
        },
    ];

    var isCompleted = true;

    completion.forEach((process) => {
        let { department, step } = process;

        // Only if the process has reached there
        if (isCompleted && request[department] && request[department].status !== step) {
            isCompleted = false;
        }
    });

    useEffect(() => {
        // Get cart items
        getCartItems(request);

        // Get the invoice number
        getInvoiceNumber();

        // Load who all can initiate
        Settings.getValue('billing-initiator').then((result) => {
            var allowed = result.value.indexOf(user.role) !== -1;

            setAccess(allowed);
        });

        Settings.getValue('invoice-tolerance').then((result) => {
            var percentage = result.value;

            setTolerance(percentage);
        });

        return () => { };
    }, []);

    function getCartItems(request) {
        var queries = [
            {
                field: 'customerID',
                value: request.customer.customerID,
            },
            {
                field: 'dealerCode',
                value: request.dealerCode,
            },
        ];

        // console.log(request);

        Cart.getVehicleDetailed(queries, request)
            .then((result) => {
                //  console.log(result[0]);
                var res = result[0];

                // console.log(result);
                setData(res);

                return res;

                //setLoading(false);
            })
            .then((cart) => {
                Vehicles.getRecord(cart.vinNo).then((v) => {
                    setVehicle(v);
                });
            });
    }

    function getInvoiceNumber() {
        // setInvoiceLoading(true);

        FirebaseUtils.getBillOrder(request.city, 'basic', moment().valueOf())
            .then((res) => {
                let id = res.prefix + res.branch_id + res.post_fix + (res.last_number + 1);

                setLastNumber({ id: res.id, lastNumber: id, numberOnly: res.last_number + 1 });

                //setInvoiceLoading(false);
            })
            .catch(() => {
                message.error('Getting Bill Number breaks, Try again! .');
            });
    }

    const transferAlert = (message) => {
        return <Alert message={message} type="warning" showIcon style={{ margin: '10px 0px' }} />;
    };

    /**
     *
     */
    function initiateBilling() {
        Requests.initiateBilliing(request.id).then(() => {
            refresh();

            message.success('Request has been send for  verification  to finance');
        });
    }

    /**
     * sendForApproval()
     */
    function sendForApproval() {
        let creation = {
            sm_initiated_at: moment().format('DD/MM/YYYY HH:mm'),
            sm_initiated_date: moment().startOf('day').valueOf(),
            sm_initiated_time: moment().valueOf(),
        };

        var values = {
            ...creation,

            actual_billing: {
                ...request.actual_billing,
                status: 'Finance Verification Pending',
                approvalRequired: true,
            },
        };
        Requests.update(request.id, values).then(() => {
            refresh();

            message.success('Request has been send for  bill approval to finance');
        });
    }

    /**
     * requestForSM()
     */
    function requestForSM() {
        let creation = {
            pending_at: moment().format('DD/MM/YYYY HH:mm'),
            pending_date: moment().startOf('day').valueOf(),
            pending_time: moment().valueOf(),
        };
        var values = {
            actual_billing: {
                status: 'pending',
                ...creation,
            },
        };
        Requests.update(request.id, values).then(() => {
            refresh();

            message.success('billing approval has been reverted');
        });
    }
    let inTransit = false;

    if (vehicle && vehicle.stock_status === 'in-transit') {
        inTransit = true;
    }

    let transferCompleted = true;

    if (vehicle && vehicle.dealerCode !== request.dealerCode) {
        transferCompleted = false;
    }

    let { total, finalTotalAfterClearing } = finalvalues;

    let showAlert = (tolerance * total) / 100 > finalTotalAfterClearing;

    return (
        <>
            {request.actual_billing && request.actual_billing.status === 'pending' ? (
                <>
                    <Button type="dashed" className="print-button" onClick={() => setVisible(true)}>
                        Raise Invoice
                    </Button>
                </>
            ) : (
                <Tag color={'orange'}>{request.actual_billing && request.actual_billing.status}</Tag>
            )}

            {request.actual_billing && request.actual_billing.status === 'Waiting for Approval' ? (
                <>
                    <Popconfirm
                        title="Are you sure you want to revert approval ? "
                        onConfirm={() => requestForSM()}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" style={{ marginLeft: '10px' }}>
                            Revert Approval
                        </Button>
                    </Popconfirm>
                </>
            ) : null}

            {/* Modal for
             Adding item Discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                width={'60%'}
                title="Vehicle Tax Invoice"
                visible={visible}
                okText="Okay"
                onOk={() => { }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                {/* Completion Status */}
                {!isCompleted ? <CompletionStatus milestone={'retail'} conditions={completion} request={request} /> : null}
                {/* Completion Status Ends */}

                <InvoicePreview request={request} data={data} booking={booking} lastNumber={lastNumber} />

                {showAlert ? (
                    <>
                        <Alert
                            message={`Please make sure you have received ${tolerance} % payment before billing`}
                            type="warning"
                            showIcon
                            style={{ margin: '10px 0px' }}
                        />
                        {request.finance ? (
                            <Popconfirm
                                disabled={!access}
                                title="Are you sure you want to proceed ? "
                                onConfirm={() => sendForApproval()}
                                onCancel={() => { }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" style={{ marginLeft: '10px' }}>
                                    Send for Billing with Approval
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </>
                ) : inTransit || !transferCompleted ? (
                    <Alert message={`Stock transfer process not completed!`} type="warning" showIcon style={{ margin: '10px 0px' }} />
                ) : (
                    <Popconfirm
                        disabled={!isCompleted || !access}
                        title="Are you sure you want to raise invoice ? "
                        onConfirm={() => initiateBilling()}
                        onCancel={() => { }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" style={{ marginLeft: '10px' }}>
                            Initiate Billing
                        </Button>
                    </Popconfirm>
                )}
            </Modal>
            {/* Modal for Adding item Discount Ends */}
        </>
    );
}

/**
 *
 *
 * @returns
 */
function CompletionStatus({ milestone, conditions = [], request = {} }) {
    return (
        <>
            <p>All the below steps has to be completed to proceed with {milestone}</p>

            {conditions
                .filter((process) => {
                    // Filter steps that are completed

                    let { department, step } = process;
                    if (department === 'delivery') {
                        return request[department] && request[department].schedule !== step;
                    } else {
                        return request[department] && request[department].status !== step;
                    }
                })
                .map((process) => {
                    let { department, step } = process;

                    return (
                        <Alert
                            message={`${department} process is incomplete . It has to reach ${step} to proceed with ${milestone}. Current status is ${request[department] && request[department].status
                                }`}
                            type="warning"
                            showIcon
                            style={{ margin: '10px 0px' }}
                        />
                    );
                })}
        </>
    );
}
