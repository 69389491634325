import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom';

import { Statistic, Skeleton, Typography, Row, Col } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';
import { getPendingMatchingReceipts } from '../../../../utils/reconcilation.utils';

import './reconcilation-overview.scss';
import { Departments } from '../../../../models';

const { Title, Text } = Typography;

export default function ReconcileOverview() {
    let defaultReceipt = {
        accounts: [],
        sm: [],
        edp: [],
        new: [],
        exact: [],
        match: []
    };

    const [loader, setLoader] = useState(true);

    const [receipts, setReceipts] = useState(defaultReceipt);

    const { user, isMobile } = useContext(GlobalContext);

    const [location, setLocation] = useState(user.locations[0]);


    useEffect(() => {
        getCustomerCredits();
    }, []);

    //get all the departments
    async function getAllDepartments(query) {
        return Departments.get(query).then((res) => {
            return res.departments;
        });
    }


    function getCustomerCredits(location) {
        let dept;

        dept = 'Department Handling Sales';

        setLoader(true);

        //Getting all departments according to description(can be either sales or service)
        var query = [
            {
                field: 'description',
                value: dept,
            },
        ];

        getAllDepartments(query).then((departments) => {
            var deptID = [];
            // deptID will give an array of all department ids of sales or service
            departments.forEach((dep) => {
                deptID.push(dep.id);
            });

            var queries = [
                {
                    field: 'reconcile_status',
                    value: 'pending'
                },
                {
                    field: 'isCredit',
                    value: true
                },
                // to get only sales or service data
                {
                    field: 'department_id',
                    operator: 'in',
                    value: deptID,
                },
            ];


            FirebaseUtils.getBankCredits(queries).then((result) => {

                if (result && result.length) {
                    getPendingMatchingReceipts(
                        result.filter((item) => !item.debit)
                    ).then((data) => {
                        setReceipts({
                            ...defaultReceipt,
                            ...data
                        });

                        setLoader(false);

                    });

                } else {
                    setReceipts(defaultReceipt);

                    setLoader(false);

                }
            });
        })
    }

    return (
        <div className="second-content summary-content" style={{marginRight:'700px'}}>

            <div className="list-header">
                <div>
                    <Title level={4}>Reconcilation Overview</Title>

                    {loader ? (

                        <Skeleton />
                    ) : (
                        <div className="reconcilation-container">
                            <RenderCards
                                title="Pending To Reconcile"
                                length={
                                    receipts['new'].length +
                                    receipts['match'].length
                                }
                            />

                            <RenderCards
                                title="Pending With Sales Team"
                                length={receipts['sm'].length}
                            />

                            <RenderCards
                                title="Matching Receipts"
                                length={receipts['match'].length}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    /**
     * Renders a Statistic component with reconcilation information
     * @param {Object} props - consisits of title and lenght for rendering cards
     */
    function RenderCards(props) {
        if (props.length > 0) {
            return (
                <div className="card dashboard-item">
                    <Statistic title={props.title} value={props.length} />

                    <Link to={`/pending-summary`}>View</Link>
                </div>
            );
        } else {
            return <></>;
        }
    }
}
