import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import {
    Table,
    Dropdown,
    Menu,
    Typography,
    Button,
    Space,
    Progress,
    Tag
} from 'antd';

import { GlobalContext } from '../../../../Store';
import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import {
    OrderedListOutlined,
    DownOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';
import { ExportXLS } from './ExportXLS';

import moment from 'moment';

import './delivery-tracker.scss';
import { cityKeys } from '../../../global-config';

const { Title, Text } = Typography;

let filters = {
    accessories: [
        { text: 'pending', value: 'pending' },
        { text: 'Data Collected', value: 'Data Collected' },
        { text: 'Ready for Fitment', value: 'Ready for Fitment' },
        { text: 'Accessories Fitted', value: 'Accessories Fitted' },
        { text: 'Ready for Billing', value: 'Ready for Billing' }
    ],
    finance: [
        { text: 'pending', value: 'pending' },
        { text: 'Data Collected', value: 'Data Collected' },
        { text: 'Documents Collected', value: 'Documents Collected' },
        { text: 'Finance Approval Pending', value: 'Finance Approval Pending' },
        { text: 'Delivery Order Pending', value: 'Delivery Order Pending' },
        { text: 'Disbursement Pending', value: 'Disbursement Pending' }
    ],
    retail: [
        { text: 'pending', value: 'pending' },
        { text: 'requested', value: 'requested' },
        { text: 'approved', value: 'approved' },
        { text: 'Invoiced', value: 'Invoiced' }
    ],
    insurance: [
        { text: 'Ready for Issue', value: 'Ready for Issue' },
        { text: 'Payment Pending', value: 'Payment Pending' },
        { text: 'Payment Done', value: 'Payment Done' },
        { text: 'Insurance Issued', value: 'Insurance Issued' }
    ],
    rto: [
        { text: 'pending', value: 'pending' },
        { text: 'Payment Pending', value: 'Payment Pending' },
        { text: 'Waiting for TP', value: 'Waiting for TP' },
        { text: 'Road Tax Issued', value: 'Road Tax Issued' }
    ],
    delivery: [
        { text: 'requested', value: 'requested' },
        { text: 'Approved', value: 'Approved' },
        { text: 'Delivery Note Taken', value: 'Delivery Note Taken' },
        { text: 'Delivery Completed', value: 'Delivery Completed' }
    ]
};

var cityCode = cityKeys
export default function VehicleOverview({ history, report, index }) {
    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [result, setResults] = useState([]);
    const [vehResult, setVehResult] = useState();

    const [location, setLocation] = useState(user.locations[0]);

    useEffect(() => {
        // console.log(result, 'sdsddssd');

        if (user) {
            getRequests(location);
        }
    }, [user]);

    useEffect(() => {
        // Select required fields from result json array. (for exporting to csv document)
        setVehResult(
            result &&
            result
                .slice(0)
                .reverse()
                .map((item) => {
                    // console.log(item);
                    return {
                        Vehicle: item['Vin Number'],
                        Variant: item['Variant'],
                        color: item['Exterior Color Name'],
                        // +" "+item['Variant']+" "+item['Exterior Color Name'],
                        Customer:
                            item.request && item.request.id
                                ? item.request.customer.customerName
                                : item['Cust Name'],
                        KEC: item.request && item.request.kec,
                        TeamLeader: item.request && item.request.teamLeader,
                        Finance:
                            item.request &&
                            item.request.finance &&
                            item.request.finance.status,
                        Retail:
                            item.request &&
                            item.request.billing &&
                            item.request.billing.status,
                        Accessories:
                            item.request &&
                            item.request.accessories &&
                            item.request.accessories.status,
                        Insurance:
                            item.request &&
                            item.request.insurance &&
                            item.request.insurance.status,
                        RTO:
                            item.request &&
                            item.request.rto &&
                            item.request.rto.status,
                        Delivery:
                            (item.request &&
                                item.request.delivery &&
                                item.request.delivery.status) +
                            (item.request &&
                                item.request.delivery &&
                                item.request.delivery.schedule ===
                                'Delivery Scheduled'
                                ? ('Scheduled_At :' + item.request &&
                                    item.request.delivery &&
                                    moment(item.request.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A'))
                                : '')
                    };
                })
        );
    }, [result]);

    /**
     * Get stock Data
     */
    function getRequests(location) {
        setLoading(true);

        var queries = [
            {
                field: 'update',
                operator: 'in',
                value: ['new', 'status']
            }
        ];

        FirebaseUtils.getVehicleOverview(user.locations[0], queries, 'vehicles').then(
            (result) => {
                console.log('here overview', result);
                setLoading(false);

                setResults(result);
            }
        );
    }

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function handleMenuClick(e) {
        setLocation(e.key);

        getRequests(e.key);
    }

    return (
        <div className="vehicle-overview listing">
            <div className="page-header">
                <div className="left">
                    <Title level={3}>Vehicle Overview</Title>
                </div>

                <div className="right">
                    <div className="page-actions">
                        <Dropdown overlay={menu}>
                            <Button>
                                {location} <DownOutlined />
                            </Button>
                        </Dropdown>
                    </div>

                    <div>
                        <Button
                            onClick={() => {
                                getRequests(location);
                            }}
                            type="secondary"
                            size={'small'}
                        >
                            Refresh
                        </Button>
                    </div>
                    <div>
                        {/*table data export to csc component*/}
                        {vehResult && <ExportXLS csvData={vehResult} />}
                    </div>
                </div>
            </div>

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                    <>
                        <TableView data={result} />
                    </>
                )}
        </div>
    );
}

function TableView({ data, callback }) {

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1
            render: (value, item, index) => index + 1
        },
        {
            title: 'Vehicle',
            render: (text, record) => {
                return (
                    <div>
                        <div style={{ fontSize: '12px' }}>
                            {record['Vin Number']}
                        </div>
                        <div style={{ fontSize: '10px' }}>
                            {record['Variant']}
                        </div>
                        <div style={{ fontSize: '10px', color: 'grey' }}>
                            {record['Exterior Color Name']}
                        </div>

                        <div style={{ fontSize: '10px' }}>
                            {record['KMI Invoice Date']}
                        </div>

                    </div>
                );
            },
            sorter: (a, b) => {
                if (a && a.request && b && b.request) {
                    return (
                        a.request.allocation.status.length -
                        b.request.allocation.status.length
                    );
                } else if (a && a.request) {
                    return a.request.allocation.status.length - 0;
                } else if (b && b.request) {
                    return 0 - b.request.allocation.status.length;
                } else {
                    return 0 - 0;
                }
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend'],
            width: 200
        },
        {
            title: 'Customer',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.request && record.request.id ? (
                            <Link
                                style={{ fontSize: '12px' }}
                                to={`/requests/${record.request.id}`}
                            >
                                {/* {record['Cust Name']} */}

                                {record.request.customer.customerName}
                            </Link>
                        ) : (
                                <div>{record['Cust Name']}</div>
                            )}
                    </Space>
                );
            },
            width: 200
        },

        {
            title: 'KEC',
            // dataIndex: 'Cust Name',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <div>{record.request && record.request['kec']}</div>
                    </Space>
                );
            },
            width: 200
        },

        {
            title: 'Finance',
            render: (text, record) => {
                let progressPercent;
                if (record.request && record.request.finance) {
                    if (record.request.finance.status == 'pending') {
                        progressPercent = 0;
                    } else if (
                        record.request.finance.status == 'Data Collected'
                    ) {
                        progressPercent = 17;
                    } else if (
                        record.request.finance.status == 'Documents Collected'
                    ) {
                        progressPercent = 34;
                    } else if (
                        record.request.finance.status ==
                        'Finance Approval Pending'
                    ) {
                        progressPercent = 50;
                    } else if (
                        record.request.finance.status ==
                        'Delivery Order Pending'
                    ) {
                        progressPercent = 67;
                    } else if (
                        record.request.finance.status == 'Disbursement Pending'
                    ) {
                        progressPercent = 84;
                    } else {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {record.request &&
                                record.request.finance &&
                                record.request.finance.status}
                        </div>
                        <Progress
                            percent={progressPercent}
                            steps={6}
                            showInfo={progressPercent == 0 ? false : true}
                        />
                    </div>
                );
            },
            filters: filters.finance,
            onFilter: (value, record) =>
                record.request &&
                record.request.finance &&
                record.request.finance.status.indexOf(value) === 0,
            width: 150
        },
        {
            title: 'Retail',
            render: (text, record) => {
                let tagColor = 'gold';
                if (record.request && record.request.billing) {
                    if (record.request.billing.status == 'pending') {
                        tagColor = 'gold';
                    } else if (record.request.billing.status == 'requested') {
                        tagColor = 'red';
                    } else if (record.request.billing.status == 'approved') {
                        tagColor = 'blue';
                    } else if (record.request.billing.status == 'Invoiced') {
                        tagColor = 'green';
                    }
                }
                return (
                    record.request &&
                    record.request.billing && (
                        <Tag color={tagColor}>
                            {record.request.billing.status}
                        </Tag>
                    )
                );
            },
            filters: filters.retail,
            onFilter: (value, record) =>
                record.request &&
                record.request.billing &&
                record.request.billing.status.indexOf(value) === 0
        },
        {
            title: 'Accessories',
            render: (text, record) => {
                let progressPercent;
                if (record.request && record.request.accessories) {
                    if (record.request.accessories.status == 'pending') {
                        progressPercent = 0;
                    } else if (
                        record.request.accessories.status == 'Data Collected'
                    ) {
                        progressPercent = 20;
                    } else if (
                        record.request.accessories.status == 'Ready for Fitment'
                    ) {
                        progressPercent = 40;
                    } else if (
                        record.request.accessories.status ==
                        'Accessories Fitted'
                    ) {
                        progressPercent = 60;
                    } else if (
                        record.request.accessories.status == 'Ready for Billing'
                    ) {
                        progressPercent = 80;
                    } else {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {record.request &&
                                record.request.accessories &&
                                record.request.accessories.status}
                        </div>
                        <Progress
                            percent={progressPercent}
                            steps={5}
                            showInfo={progressPercent == 0 ? false : true}
                        />
                    </div>
                );
            },
            filters: filters.accessories,
            onFilter: (value, record) =>
                record.request &&
                record.request.accessories &&
                record.request.accessories.status.indexOf(value) === 0,
            width: 200
        },
        {
            title: 'Insurance',
            render: (text, record) => {
                let progressPercent;
                if (record.request && record.request.insurance) {
                    if (record.request.insurance.status == 'Ready for Issue') {
                        progressPercent = 0;
                    } else if (
                        record.request.insurance.status == 'Payment Pending'
                    ) {
                        progressPercent = 34;
                    } else if (
                        record.request.insurance.status == 'Payment Done'
                    ) {
                        progressPercent = 67;
                    } else if (
                        record.request.insurance.status == 'Insurance Issued'
                    ) {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div className="insurance-block">
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {record.request &&
                                record.request.insurance &&
                                record.request.insurance.status}
                        </div>
                        <Progress
                            percent={progressPercent}
                            steps={3}
                            showInfo={progressPercent == 0 ? false : true}
                        />
                        {record.request && record.request.fasttag && (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                fast tag
                            </Tag>
                        )}
                    </div>
                );
            },
            filters: filters.insurance,
            onFilter: (value, record) =>
                record.request &&
                record.request.insurance &&
                record.request.insurance.status.indexOf(value) === 0,
            width: 150
        },
        {
            title: 'RTO',
            render: (text, record) => {
                let progressPercent;
                if (record.request && record.request.rto) {
                    if (record.request.rto.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.request.rto.status == 'Payment Pending') {
                        progressPercent = 34;
                    } else if (record.request.rto.status == 'Waiting for TP') {
                        progressPercent = 67;
                    } else if (record.request.rto.status == 'Road Tax Issued') {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {record.request &&
                                record.request.rto &&
                                record.request.rto.status}
                        </div>
                        <Progress
                            percent={progressPercent}
                            steps={3}
                            showInfo={progressPercent == 0 ? false : true}
                        />
                    </div>
                );
            },
            filters: filters.rto,
            onFilter: (value, record) =>
                record.request &&
                record.request.rto &&
                record.request.rto.status.indexOf(value) === 0,
            width: 150
        },
        {
            title: 'Delivery',
            render: (text, record) => {
                let progressPercent;
                if (record.request && record.request.delivery) {
                    if (record.request.delivery.status == 'pending') {
                        progressPercent = 0;
                    } else if (record.request.delivery.status == 'requested') {
                        progressPercent = 25;
                    } else if (record.request.delivery.status == 'Approved') {
                        progressPercent = 50;
                    } else if (
                        record.request.delivery.status == 'Delivery Note Taken'
                    ) {
                        progressPercent = 75;
                    } else if (
                        record.request.delivery.status == 'Delivery Completed'
                    ) {
                        progressPercent = 100;
                    }
                } else {
                    progressPercent = 0;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '10px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {record.request &&
                                record.request.delivery &&
                                record.request.delivery.status}
                        </div>
                        <Progress
                            percent={progressPercent}
                            steps={3}
                            showInfo={progressPercent == 0 ? false : true}
                        />
                        {record.request &&
                            record.request.delivery &&
                            record.request.delivery.schedule ===
                            'Delivery Scheduled' ? (
                                <div>
                                    <div
                                        style={{
                                            fontSize: '10px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Scheduled_At :
                                </div>
                                    {/* <div
                                        style={{ fontSize: '10px', color: 'grey' }}
                                    >
                                        {record.request &&
                                            record.request.delivery &&
                                            record.request.delivery
                                                .scheduled_delivery_date}
                                    </div> */}
                                    <div
                                        style={{ fontSize: '10px', color: 'grey' }}
                                    >
                                        {record.request &&
                                            record.request.delivery &&
                                            moment(record.request.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A')}
                                    </div>
                                </div>
                            ) : null}
                    </div>
                );
            },
            filters: filters.delivery,
            onFilter: (value, record) =>
                record.request &&
                record.request.delivery &&
                record.request.delivery.status.indexOf(value) === 0,
            sorter: (a, b) => {
                if (
                    a &&
                    a.request &&
                    a.request.delivery &&
                    b &&
                    b.request &&
                    b.request.delivery
                ) {
                    return (
                        a.request.delivery.status.length -
                        b.request.delivery.status.length
                    );
                } else if (a && a.request && a.request.delivery) {
                    return a.request.delivery.status.length - 0;
                } else if (b && b.request && b.request.delivery) {
                    return 0 - b.request.delivery.status.length;
                } else {
                    return 0 - 0;
                }
            },
            defaultSortOrder: 'descend',
            sortDirections: ['descend'],
            width: 150
        }
    ];

    return (
        <Table
            width="100%"
            size="small"
            // scroll={{ x: true, y: 750 }}
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            // onChange={handleTableChange}
            pagination={false}
        ></Table>
    );
}
