/**
 * @description
 *  This Page  is used to Approve stock Transfer Request NVI cor-ordinator
 *
 */

import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Button, Row, message, Card, Steps } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { StockTransfers, Stocks } from '../../../../models';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

export default function StockOutwardDetails({ history, match }) {

    const [stocktransfer, setStockTransfer] = useState([]);

    const { params } = match;

    const { id } = params;
    // set varible page loading
    const [loader, setLoader] = useState(true);
    // varible for button loading
    const [btnloading, setBtnLoading] = useState(false);

    const [location, setLocation] = useState(false);

    // const [stockdisable, setStockDisable] = useState(true);

    const [outward, setOutWard] = useState(false);

    const [inward, setInward] = useState(false);

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        loadData(id);
    }, []);

    /**
     *
     * Load Stock transfer data based on Stock transfer id
     */
    function loadData() {
        let result;

        StockTransfers.getRecordStock(id).then(async (res) => {
            result = res;

            // If the stock transfer from same location then the state will true
            if (res.location_from && res.location_to) {
                if (res.location_from === res.location_to) {
                    setLocation(true);
                }
            } else {
                setLocation(false);
            }
            // The stocktransfer status is requested then the stock is outward
            if (res.status === 'outward-pending') {
                setOutWard(true);
            }

            // In case of virtual transfer when sm has approved, outward should be true
            if(res.sub_mode==="virtual"&&res.status==="approved"){
                setOutWard(true)
            }

            // The stocktransfer status is outward-done the stock is inward
            if (res.status === 'outward-done') {
                setInward(true);
            }

            // if stock to_loaction and from_locations are same .Then the dearcode want to change
            // dearcode must user dearcode
            if (res.to && res.from) {
                if (res.to !== res.from) {
                    result = {
                        ...result,
                        dealerCode: user.dealerCode,
                    };
                }
            }

            // set state values
            setStockTransfer(result);
            // set varible loading false
            setLoader(false);
        });
    }

    /**
     *
     *
     */

    function statusLogUpdate(value) {
        let identifier

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        let values = null
        // identifier of statusmaster in outward case 
        if (outward) {
            identifier = 'STCKOUTDONE'
        } else {
            // identifier of statusmaster in inward  case 
            console.log(stocktransfer)
            if (stocktransfer.sub_mode === "virtual" && stocktransfer.status === "outward-done") {
                identifier = 'DONE'
            } else {
                identifier = 'STCKTRANCOMPLETED'
            }
        }
        // identifier  when reject the stocktransfer   

        if (value && value.status === 'cancelled') {
            identifier = 'STCKCANCELLED'
        }

        // creating a record in status log 
        return StockTransfers.createStatusLog(identifier, stocktransfer, batch, values).then((res) => {
            batch.commit().then(() => {
                // message.success('Request For Stock Transfer Created');
            });
        });

    }
    /**
     * Function to create stocktransactions and update status of stocktransfer && Vehicles
     * 
     */
    function onSubmit() {
        // values for submit 
        let values = {
            user: user,
            outward: outward,
            inward: inward,
            vehicleNumber: stocktransfer.vinNo,
            sameLocation: location,
            stockTransferId: id,
            dealerCode: stocktransfer.dealerCode,
            from_stock_point_id: stocktransfer.from_stock_point_id,
            to_stock_point_id: stocktransfer.to_stock_point_id,
            fromDealerCode: stocktransfer.dealerCode,
            toDealerCode: stocktransfer.toDealerCode,
            to: stocktransfer.to,
            from: stocktransfer.from,
            sub_mode: stocktransfer.sub_mode
        };

        statusLogUpdate()
        setBtnLoading(true);
        //Function to create stocktransactions and update status of stocktransfer && Vehicles
        Stocks.loadFunction('onTransferRequestApproval', values).then((result) => {
            if (result.success) {
                setBtnLoading(false);
                message.success('Success')
                history.goBack();
            } else {
                message.error('Failed');
            }
        });
    }

    /**
     * when rejecting the Stocktransfer .
     * Upadting status of stock_transfers is cancelled
     *
     */

    function rejectStockTransfer() {
        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        StockTransfers.cancelStockTransferRequest(stocktransfer,batch).then((res) => {
            batch.commit()
        }).then(() => {
            history.goBack();
        })

    }

    return (
        <div className="review-request-detail">
            {/* <Header onBack={() => props.history.goBack()} /> */}

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <>
                        <div className="about-us page-content">
                            <h1 className="page-heading">StockTransfer Request Approvel</h1>
                        </div>

                        <p>Update any necessary information and see the status of your request below</p>
                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <Row span={12}>
                                        <h3>Vin Number</h3>
                                    </Row>
                                </Row>
                                <Row span={12}>
                                    <h2 className="title amount">{stocktransfer.vinNo} </h2>
                                </Row>
                            </div>
                            <div className="detail-element">
                                <Row span={12}>
                                    <h3>From Location</h3>
                                </Row>
                                <Row span={12}>
                                    <span>{stocktransfer.from}</span>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <Row span={12}>
                                    <h3>To Location </h3>
                                </Row>
                                <Row span={12}>
                                    <span>{stocktransfer.to}</span>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <Row span={12}>
                                    <h3>From Yard </h3>
                                </Row>
                                <Row span={12}>
                                    <span>{stocktransfer.from_yard_name}</span>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <Row span={12}>
                                    <h3>To Yard</h3>
                                </Row>
                                <Row span={12}>
                                    <span>{stocktransfer.to_yard_name}</span>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <Row span={12}>
                                    <h3>Staff Name</h3>
                                </Row>
                                <Row span={12}>
                                    <span>{stocktransfer.staff_name}</span>
                                </Row>
                            </div>
                        </div>
                        <Button htmlType="submit" type="primary" onClick={onSubmit} loading={btnloading}>
                            Approve
                        </Button>

                        <Button htmlType="submit" type="primary" onClick={rejectStockTransfer} >
                            Reject
                        </Button>
                    </>
                </Fragment>
            )}
        </div>
    );
}
