/**
 * 
 * 
 */


import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import XLSX from 'xlsx';

import { Space, Switch, message, Table, Typography, Tag, Button, Upload, Form, DatePicker, Select, Badge } from 'antd';
import { Menu, Dropdown, Drawer } from 'antd';

import { GlobalContext } from '../../../../Store';

import { DownOutlined, UploadOutlined, ReloadOutlined } from '@ant-design/icons';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { Employees, Enquirys, StatusMasters } from '../../../../models'

import moment from 'moment';
import { cityKeys, dealerKeys } from '../../../global-config';
import StatusChange from '../../../../components/status-change/status-change';
import { ExportReactCSV } from '../../resources/generic/generic-list/ExportReactCSV';

const { Text, Title } = Typography;

const { Option } = Select;

var cityCode = cityKeys

const { RangePicker } = DatePicker;

export default function ManageEnquiries({ history, report, index }) {
    const [bucket, setBucket] = useState([]);

    const { user, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [files, setFiles] = useState([]);

    const [form] = Form.useForm();

    const [btnloading, setBtnLoading] = useState(false)

    const [result, setResults] = useState([]);

    const [location, setLocation] = useState(user.locations[0]);

    const [enquiry, setEnquiry] = useState({});

    const [newenquiry, setNewEnquiry] = useState({});

    const [page, setPage] = React.useState(1);

    const [view, setView] = useState(isMobile);

    const [visible, setVisible] = useState(false);

    const [status, setStatus] = useState();

    const [filterChoices, setFilterChoices] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [option, setOption] = useState('enquiry_date');


    const [range, setRange] = useState([moment().startOf('month'), moment().endOf('day')])
    const [masters, setMasters] = useState([]);
    const [expRecord, setExptRecords] = useState();
    const [diffrentDealercode, setDiffrentDealercode] = useState(false);

    const [diffrentDealercodeCount, setdiffrentDealercodeCount] = useState(0);  // State to count wrong location occurrences


    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const [options, setOptions] = useState({
        page: 1,
        current: 1,
        pageSize: 20,
    });

    const [head, setHead] = useState({});

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
        },
        {
            dataIndex: 'Enquiry No.',
            key: 'input',
            title: 'Enquiry No.',
        },
        {
            width: 200,
            dataIndex: 'Name of the Customer',
            key: 'input',
            title: 'Name of the Customer',
        },
        // Column definitions
        {
            title: 'KEC',
            dataIndex: 'consultant',
            key: 'consultant',
            width: 150,
            filters: filterChoices && filterChoices[0],
            onFilter: (value, record) => (record['consultant'] ? record['consultant'].indexOf(value) === 0 : null),
            filterDropdownVisible: user.role == 'kec' ? false : null,
        },
        {
            title: 'Team Leader',
            dataIndex: 'teamleader',
            key: 'teamleader',
            filters: filterChoices && filterChoices[1],
            onFilter: (value, record) => (record['teamleader'] ? record['teamleader'].indexOf(value) === 0 : null),
            filterDropdownVisible: user.role == 'tl' ? false : null,
            width: 150,
        },
        {
            width: 150,
            title: 'Enquiry Date',
            key: 'enquiry_date',
            render: (record) => {
                if (record.enquiry_date) {
                    return (moment.tz(record.enquiry_date, 'Asia/Calcutta').format('DD/MM/YYYY'));
                }

            },
            exportDefinition: (record) => {

                if (record.enquiry_date) {
                    return (moment.tz(record.enquiry_date, 'Asia/Calcutta').format('DD/MM/YYYY'));
                }


            },
        },
        {
            dataIndex: 'Interested in Exchange(Y/N)',
            key: 'input',
            title: 'Interested in Exchange(Y/N)',
        },
        {
            dataIndex: 'Source',
            key: 'input',
            title: 'Source',
        },

        {
            title: 'Status',
            render: (record) => {
                return (
                    <Space size="middle" style={{ minWidth: '180px' }}>
                        <StatusChange
                            referenceNumber={record.id}
                            caption={'Enquiry Status'}
                            mode="enquiry_status"
                            description={record.enquiry_status ? record.enquiry_status : 'Not updated'}
                            callback={(status) => {
                                updateEnquiryField(record.id, status.name, 'enquiry_status');
                            }}
                        />
                    </Space>
                );
            },
            exportDefinition: (record) => {
                return record['enquiry_status'];
            },
        },
        {
            title: 'Type',
            key: 'type',
            render: (record) => {
                if (record && record.Type) {

                    let statusType = 'default';

                    switch (record.Type) {
                        case 'RETAIL':
                            statusType = 'processing'; // Blue color
                            break;
                        case 'HOT':
                            statusType = 'success'; // Green color
                            break;
                        case 'WARM':
                            statusType = 'error'; // Red color
                            break;
                        case 'COLD':
                            statusType = 'warning'; // Yellow color
                            break;
                        default:
                            statusType = '';
                    }

                    return <Badge status={statusType} />;
                }
            },
            exportDefinition: (record) => {
                return record['Type'];
            }
        },

        {
            width: 300,
            dataIndex: 'follow_up_status',
            key: 'input',
            title: 'Follow Up Status',
        },

        {
            width: 150,
            title: 'Next follow up date',
            key: 'next_follow_up_date',
            render: (record) => {
                if (record.next_follow_up_date) {
                    const formattedDate = moment.tz(record.next_follow_up_date, 'Asia/Calcutta').format('DD/MM/YYYY');
                    return (formattedDate)
                }

            },
            exportDefinition: (record) => {

                if (record.next_follow_up_date) {
                    return (moment.tz(record.next_follow_up_date, 'Asia/Calcutta').format('DD/MM/YYYY'));
                }


            },
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => {
                var id = text['Dealer Code'] + text['Enquiry No.']
                return (
                    <Space size="middle">
                        <Link to={`/enquiries/${id}`}>View</Link>
                    </Space>
                );
            },
        },
    ];


    useEffect(() => {
        var exporData = getExportData(filteredData, columns);

        setExptRecords(exporData);
    }, [filteredData]);


    useEffect(() => {
        if (user) {
            getEnquiries(location, range, status, option);
        }
        getStatusMaster();
    }, [user]);

    const FiteringValue = (data) => {
        let arrKEC1 = [];
        let arrTL1 = [];

        data.forEach((ele) => {
            if (ele['consultant'] && !arrKEC1.some(item => item.value === ele['consultant'])) {
                arrKEC1.push({ text: ele['consultant'], value: ele['consultant'] });
            }
            if (ele['teamleader'] && !arrTL1.some(item => item.value === ele['teamleader'])) {
                arrTL1.push({ text: ele['teamleader'], value: ele['teamleader'] });
            }
        });

        let body = [arrKEC1, arrTL1];
        setFilterChoices(body);
    };

    function handleChange(value) {
        setOption(value)
        getEnquiries(location, range, status, value)
    }

    /**
     * Enquiries Data dispalying based their status of below
     * @param {*} location 
     * @param {*} range 
     * @param {*} status 
     * @param {*} value 
     */
    function getEnquiries(location, range, status, value) {
        setLoading(true);

        var queries = [{
            field: 'dealerCode',
            value: cityCode[location]
        },
        {
            field: value,
            operator: '>=',
            value: moment(range[0]).startOf('day').valueOf(),
        },
        {
            field: value,
            operator: '<=',
            value: moment(range[1]).endOf('day').valueOf(),
        },
        ]

        // filtering for teamleader's enquiries
        if (user.role === 'tl') {
            queries.push({
                field: 'teamleader_id',
                value: user.employee_id
            })
        }

        // filtering for kec's enquiries

        if (user.role === 'kec') {
            queries.push({
                field: 'consultant_id',
                value: user.employee_id
            })
        }

        if (status) {
            queries.push({
                field: 'enquiry_status',
                value: status
            })
        }

        Enquirys.getOrderRecord(location, queries).then((result) => {

            let b = Object.keys(result.enquiries).map((enquiry) => {
                let b = result.enquiries[enquiry];

                return { ...b };
            });
            Employees.getKECandTeamleader(b).then(async (res) => {
                setLoading(false);

                FiteringValue(res);
                setEnquiry(res);

                // for download
                setFilteredData(res)

                setResults(res);

                if (result.end) {
                    options.end = result.end;

                    head.end = result.end;

                    setHead({ ...head });
                }

                bucket.push(result.start);

                setBucket(bucket);

                if (result.start) {
                    options.start = result.start;

                    head.start = result.start;

                    // setOptions({ ...options });

                    setHead({ ...head });
                }
            });
        })
    }

    function updateTime(dt) {
        setRange(dt);
        getEnquiries(location, dt, status, option);
    }


    /**
     * Update the vehicle field
     *
     * @param {*} vehicle
     * @param {*} formValues
     * @param {*} field
     */
    async function updateEnquiryField(id, value, field) {
        await Enquirys.update(id, { [field]: value, }).then(() => {
            refresh()
        })
    }

    function getExportData(data, columns) {
        return data.map((record, index) => {

            var content = {
                '#': index + 1,
            };
            columns.forEach((column, index) => {

                if (column.title === '#') {
                }
                else if (column.exportDefinition) {
                    content[column.title] = column.exportDefinition(record, index);
                } else {
                    content[column.title] = record[column.dataIndex]
                }
            })
            return content
        })
    }
    /**
    * Load the status Master
    */
    async function getStatusMaster() {
        let queries = [
            {
                field: 'mode',
                value: 'enquiry_status',
            },
        ];
        const result = await StatusMasters.get(queries);
        setMasters(result.status_masters || []);
    }

    // function getNextEnquiry(location, options = {}) {
    //     setLoading(true);

    //     let params = {
    //         pageSize: options.pageSize,
    //     };

    //     var queries = [{
    //         field: 'Dealer Code',
    //         value: cityCode[location]
    //     }]

    //     var config

    //     config = {
    //         pageSize: options.pageSize,
    //         end: options.end,
    //         orderBy: 'enquiry_date',
    //         direction: 'desc'
    //     }


    //     params.start = options.end;

    //     Enquirys.getListingData(location, queries, config).then((result) => {

    //         // FirebaseUtils.getNextBookings(location, options).then((result) => {

    //         setEnquiry(result);

    //         console.log(result, options);

    //         setLoading(false);

    //         let b = Object.keys(result.enquiries).map((enquiry) => {
    //             return result.enquiries[enquiry];
    //             // return { ...result.bookings[booking], requestId: request }
    //         });

    //         setResults(b);

    //         bucket.push(result.start);

    //         setBucket(bucket);

    //         if (result.end) {
    //             options.end = result.end;

    //             head.end = result.end;

    //             // setOptions({ ...options });

    //             setHead({ ...head });
    //         }

    //         if (result.start) {
    //             options.start = result.start;

    //             head.start = result.start;

    //             // setOptions({ ...options });

    //             setHead({ ...head });
    //         }
    //     });
    // }

    // function getPrevEnquiry(location, options = {}) {
    //     setLoading(true);

    //     let params = {
    //         pageSize: options.pageSize,
    //     };

    //     bucket.pop();

    //     params.start = bucket[bucket.length - 1];

    //     var queries = [{
    //         field: 'dealerCode',
    //         value: cityCode[location]
    //     }]

    //     var config

    //     config={
    //         limit:options.pageSize,
    //         startAt: bucket[bucket.length - 1],
    //         orderBy: 'enquiry_date',
    //         direction:'desc'
    //     }


    //     Enquirys.getOrderRecord(location,queries).then((result)=>{
    //     // FirebaseUtils.getPrevBookings(location, params).then((result) => {
    //         setEnquiry(result);

    //         console.log(result, options);

    //         setLoading(false);

    //         let b = Object.keys(result.enquiries).map((enquiry) => {
    //             return result.enquiries[enquiry];
    //             // return { ...result.bookings[booking], requestId: request }
    //         });

    //         setResults(b);

    //         if (result.end) {
    //             options.end = result.end;

    //             head.end = result.end;

    //             // setOptions({ ...options });

    //             setHead({ ...head });
    //         }

    //         if (result.start) {
    //             options.start = result.start;

    //             head.start = result.start;

    //             // setOptions({ ...options });

    //             setHead({ ...head });
    //         }
    //     });
    // }

    function refresh() {

        getEnquiries(location, range, status, option);
    }

    function handleFile(f) {
        // var files = event.target.files, f = files[0];

        var reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = function (e) {
            const bstr = e.target.result;

            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            const data = XLSX.utils.sheet_to_json(ws, { header: 0 });

            // uploadPastBookings(data);

            processData(data);

            console.log(data)

        };

        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
    }

    /**
   *
   * Prepare the data according to the configuration
   *
   * @param {*} data
   */
    function processData(data) {
        var modified = [];
        let count = 0;  // Initialize counter for wrong location

        var columns = data[0];

        console.log(columns);
        //validation for Enquiries upload(only can upload user exact location's Enquiries)

        data.forEach((element, index) => {
            if (cityCode[location] === element['Dealer Code']) {

                var key;

                // At some cases we have to derive the index from two columns hence we use function
                key = findIndex(element);

                if (typeof key !== 'undefined') {
                    // Trim to avoid empty spaces
                    key = key.trim();


                }

                // delete element['Booking No']
                modified[key] = {
                    // Whatever is mentioned in the extra Params
                    ...modified[key],

                    // This is the new element
                    ...element,
                };


                modified[key] = {
                    ...modified[key],
                    'Enquiry No': key,
                };

            } else {

                count++;  // Increment wrong location counter
                setDiffrentDealercode(true)

            }
            setdiffrentDealercodeCount(count)

        })

        console.log(modified)


        var b = {};

        Promise.all(
            Object.keys(modified).map(async (enquiry) => {
                console.log(enquiry)

                if (modified[enquiry]['Enquiry No']) {
                    b[modified[enquiry]['Enquiry No']] = modified[enquiry]
                }
                await Enquirys.getRecord(enquiry).then((res) => {
                    if (res && res['Enquiry No']) {
                        modified[enquiry].duplicate = true;
                        return modified[enquiry];
                    }
                });
            })
        ).then(() => {
            console.log(modified);

            return setNewEnquiry(modified);
        });

        // var queries = [{
        //     field: 'dealerCode',
        //     value: cityCode[location]
        // }]

        // setNewEnquiry(modified)


        //Get previous enquiries to check for duplicate
        // Enquirys.get(queries).then((res) => {
        //     console.log(res.enquiries)

        //     var oldenquiry=[]
        //     res.enquiries.map((ele)=>{
        //         oldenquiry[ele.id]=ele
        //     })

        //     return checkExistingBookings(oldenquiry,modified)
        // })

    }


    /**
     *
     * Derive the index of the table from element
     *
     * @param {]} element
     */
    function findIndex(element) {
        var key;

        key = cityCode[location] + element['Enquiry No.'];



        return key;
    }


    //Check for duplicate enquiries
    async function checkExistingBookings(enquiry, newEnquiry) {
        const previousKeys = Object.keys(enquiry);
        console.log(previousKeys);

        const latestKeys = Object.keys(newEnquiry);
        console.log(latestKeys);

        latestKeys.forEach((key) => {
            console.log(key);
            console.log(previousKeys.indexOf(key));

            if (previousKeys.indexOf(key) !== -1) {
                newEnquiry[key].duplicate = true;
            }
        });

        return setNewEnquiry(newEnquiry);
    }



    async function uploadPastEnquirys(data) {

        setBtnLoading(true)

        let b = {};

        let duplicate = 0;

        console.log(Object.keys(newenquiry))

        await Promise.all(Object.keys(newenquiry).map(async (no) => {
            // console.log(newparts[no]['Consultant Name'])
            if (newenquiry[no] && newenquiry[no].duplicate) {

                duplicate++;

                b[no] = {
                    ...newenquiry[no],
                    'Contact Number': newenquiry[no]['Contact Number'].toString(),
                    deleted_at: null,
                    deleted_by: null,
                };

                return await getEmployyeMatch(newenquiry[no]['Consultant Name'], newenquiry[no]['Dealer Code']).then((employee) => {

                    if (employee.length) {
                        b[no] = {
                            ...b[no],
                            consultant_id: employee[0].id,
                            teamleader_id: employee[0].header_id

                        }
                    }
                })

            } else {

                return await getEmployyeMatch(newenquiry[no]['Consultant Name'], newenquiry[no]['Dealer Code']).then((employee) => {

                    console.log(employee.length)
                    /**
                     *  split the date string 
                     */
                    let date = newenquiry[no]['Enquiry Date'].split()[0]

                    b[no] = {
                        // Here we need to specify any by default fields that we need to add
                        ...newenquiry[no],
                        deleted_at: null,
                        deleted_by: null,
                        dealerCode: newenquiry[no]['Dealer Code'],

                        enquiry_date: moment(date, 'DD/MM/YYYY').tz('Asia/Calcutta').startOf('day').valueOf(),

                        // 'Contact Number':parseInt(newenquiry[no]['Contact Number'])
                        'Consultant Name': newenquiry[no]['Consultant Name'].trim(),
                        enquiry_status: 'LIVE',
                        next_follow_up_date: moment.tz('Asia/Calcutta').startOf('day').valueOf(),
                        follow_up_status: null,
                        Type: null

                        // ...records[model][no]
                    };
                    if (employee.length) {
                        b[no] = {
                            ...b[no],
                            consultant_id: employee[0].id,
                            teamleader_id: employee[0].header_id

                        }
                    }

                })
            }
            return b[no];
        }))

        console.log(b)
        let total = Object.keys(b).length;

        let count = total - duplicate;

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        Object.keys(b).forEach(async (key) => {

            var docRef = Enquirys.getRecordReference(key)

            await batch.set(docRef, b[key], { merge: true });
        });

        await Enquirys.addUploadsDetails(user)

        return await batch.commit().then(() => {
            setBtnLoading(false)
            message.success(`${total} ${'enquiries'} updated`);

            if (diffrentDealercode) {
                message.error(`${diffrentDealercodeCount} ${'records are'} not uploaded because of different dealercode`);
            }
            
            refresh()
            setNewEnquiry({});

        })

    }

    function getEmployyeMatch(employee, dealerCode) {

        var queries = [{
            field: 'dms_name',
            value: employee.trim()
        },
        {
            field: 'branch',
            value: dealerKeys[dealerCode]
        }]

        return Employees.get(queries).then((result) => {
            return result.employees
        })

    }



    const uploadProps = {
        onRemove: (file) => {
            var index = files.indexOf(file);

            var newFileList = files.slice();

            newFileList.splice(index, 1);

            setFiles({ ...newFileList });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);

                handleFile(info.file);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload: (file) => {
            setFiles([...files, file]);

            return false;
        },
        files,
    };

    function changeView(result) {
        setView(result);
    }

    const SheetJSFT = [
        'xlsx',
        'xlsb',
        'xlsm',
        'xls',
        'xml',
        'csv',
        'txt',
        'ods',
        'fods',
        'uos',
        'sylk',
        'dif',
        'dbf',
        'prn',
        'qpw',
        '123',
        'wb*',
        'wq*',
        'html',
        'htm',
    ]
        .map(function (x) {
            return '.' + x;
        })
        .join(',');

    const menu = () => {
        return (
            <Menu>
                {user.locations.map((location, index) => (
                    <Menu.Item key={index}>
                        <span
                            onClick={() => {
                                setLocation(location)

                                getEnquiries(location, range, status, option);
                            }}
                        >
                            {location}
                        </span>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    function handleTableChange(pagination, filters, sorter) {

        const filtered = result.filter((item) => {
            // Implement your filtering logic based on the filters object
            let match = true;
            if (filters.consultant) {
                match = match && filters.consultant.includes(item.consultant);
            }
            if (filters.teamleader) {
                match = match && filters.teamleader.includes(item.teamleader);
            }
            return match;
        });
        // for download
        setFilteredData(filtered);
    }

    return (
        <div className="manage-bookings listing">
            <div className="page-header">
                <div>
                    <Select
                        defaultValue={location}
                        style={{ width: '180px' }}
                        onChange={(location) => {
                            setLocation(location);

                            getEnquiries(location, range, status, option);
                        }}
                    >
                        {user.locations.map((location) => {
                            return <Option value={location}>{location} Enquiries</Option>;
                        })}
                    </Select>

                    <div>
                        {enquiry.lastUpdated && enquiry.lastUpdated.timestamp ? (
                            <p>
                                <small>
                                    Last Updated :{' '}
                                    <Text type="danger">{`${moment(enquiry.lastUpdated.timestamp, 'DD/MM/YYYY HH:mm').fromNow()}`}</Text>
                                </small>
                            </p>
                        ) : null}
                    </div>
                </div>

                <div className="table-hint right">
                    <div className="page-actions">
                        {loading ? <Text type="secondary">Loading Enquiries</Text> : <Text type="secondary">{/* {`${start}-${end}`} */}</Text>}

                        <div className="button-container">
                            {/* Date filter option for Enquiry Date & Next follow up date*/}
                            <div className="page-actions">
                                <>
                                    <RangePicker
                                        allowClear={false}
                                        inputReadOnly
                                        format={'DD/MM/YYYY'}
                                        value={range}
                                        onChange={updateTime}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                            'This Week': [moment().startOf('week'), moment().endOf('week')],
                                            'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().endOf('week')],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().endOf('month')],
                                        }}
                                    />

                                    <Select
                                        style={{ width: 200, marginLeft: 16 }}
                                        placeholder="Enquiry Date"
                                        onChange={handleChange}
                                    >
                                        <Option value="enquiry_date">Enquiry Date</Option>
                                        <Option value="next_follow_up_date">Next follow up date</Option>
                                    </Select>
                                </>

                                {/* Download */}
                                {expRecord && <ExportReactCSV csvData={expRecord} />}
                                {/** select using vehicle status  */}
                                <Select
                                    allowClear
                                    style={{ width: '180px' }}
                                    onChange={(status) => {
                                        setStatus(status)
                                        getEnquiries(location, range, status, option);
                                    }}
                                >
                                    {masters.map((master, index) => (
                                        <Option value={master.identifier} key={index}>
                                            {master.name}
                                        </Option>
                                    ))}
                                </Select>

                                {user.role === 'edp' ? (
                                    <>
                                        <Upload previewFile={null} accept={SheetJSFT} {...uploadProps}>
                                            <Button size={'small'} icon={<UploadOutlined />}>
                                                Select File
                                            </Button>
                                        </Upload>
                                    </>
                                ) : null}

                            </div>

                            {/* } */}

                            {/* <Button onClick={showDrawer} type="secondary" size={'small'}>
                                <SearchOutlined />
                            </Button> */}

                            <Button onClick={refresh} type="secondary" size={'small'}>
                                <ReloadOutlined />
                            </Button>

                            {/* <Button
                                disabled={options.page <= 1}
                                size="small"
                                onClick={() => {
                                    options.page--;

                                    // setOptions(options);

                                    getPrevEnquiry(location, options);

                                    // getBookings(location, options, true);
                                }}
                            >
                                <CaretLeftOutlined />
                            </Button> */}
                            {/* 
                            <Button
                                size="small"
                                onClick={() => {
                                    getNextEnquiry(location, options);

                                    options.page++;

                                    // setOptions(options);
                                }}
                            >
                                <CaretRightOutlined />
                            </Button> */}

                        </div>
                    </div>
                </div>
            </div>

            {Object.keys(newenquiry).length ? (
                <div className="file-review">
                    <div className="action-header">
                        <h4>{Object.keys(newenquiry).length} Enquiries</h4>
                        <p>{Object.keys(newenquiry).filter((entry) => newenquiry[entry].duplicate).length} are duplicate.</p>
                        <Button loading={btnloading} onClick={uploadPastEnquirys} key="3">
                            Approve
                        </Button>
                        <Button
                            key="2"
                            onClick={() => {
                                setNewEnquiry({});
                            }}
                        >
                            Decline
                        </Button>
                    </div>
                </div>
            ) : null}

            {loading ? (
                <PlaceHolder type="listing" />
            ) : (
                <>
                    <>
                        {/* {!view ? ( */}
                        <Table
                            loading={loading}
                            size="small"
                            // scroll={{ x: true, y: 750 }}

                            // scroll={{ x: true, y: 750 }}
                            rowKey={(record) => record.index}
                            dataSource={result}
                            columns={columns}
                            onChange={handleTableChange}
                            pagination={false}
                        ></Table>
                        {/* ) : (
                            <CardList url={location} data={result} />
                        )} */}
                    </>

                    <div></div>
                </>
            )}

            <Drawer placement="left" closable={false} onClose={onClose} visible={visible} className="search-drawer">
                <div className="intro">
                    <Title level={4}>Search</Title>
                </div>
            </Drawer>
        </div>
    );
}

function CardList({ data, url }) {
    return data.map((report, index) => {
        return <BookingCard city={url} booking={report} index={index} key={index} />;
    });
}

function BookingCard({ city, booking = {} }) {
    return (
        <Link className="booking-card" to={`${city}/booking/${booking['Booking No']}`}>
            <div className="card">
                <h2 className="title amount ">{booking['Name of the Customer']}</h2>
                <h4 className="title">{booking['Contact Number']}</h4>
                <h3 className="title ">{booking['Variant']}</h3>
                <div>
                    <small> {booking['Color']}</small>
                </div>
                <h4>{booking['Booking No']}</h4>
                <p className="address">
                    Booked on {booking['Booking Date']}
                    <Tag color="magenta">{booking.status || 'Initial'}</Tag>
                </p>
                Committed Delivery Date {booking['Committed Delivery Date']}
            </div>
        </Link>
    );
}
