import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from '../../../../Store';

import { Link } from 'react-router-dom';

import { Skeleton, Space, Switch, Tabs, Button, Badge, Tag, Table, DatePicker, Statistic, Typography, Drawer, Alert, Descriptions } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import moment from 'moment';

import './customer-receipts.scss';
import { Departments } from '../../../../models';
import { cityKeys } from '../../../global-config';

const { TabPane } = Tabs;

const { Title, Text } = Typography;

const dateFormat = 'DD/MM/YYYY';

export default function CustomerReceipts({ history, department, match }) {
    const { user } = useContext(GlobalContext);

    const [today, setToday] = useState(moment());

    const [dep, setDep] = useState();

    function callback(key) {
        console.log(key);
    }

    useEffect(() => {
        if (department) {
            setDep(department[0]);
        } else if (match.params.category === 'sales') {
            setDep('Department Handling Sales');
        } else if (match.params.category === 'service') {
            setDep('Department Handling Services');
        }
    }, [dep]);

    const actions = (
        <DatePicker
            inputReadOnly
            allowClear={false}
            format={dateFormat}
            value={today}
            onChange={(result) => {
                setToday(result);
            }}
        />
    );

    return (
        <section className="customer-receipts">
            <Title level={3}>Customer Receipts</Title>

            {dep === 'Department Handling Sales' && user.role !== 'finance' ? (
                <>
                    <Alert
                        message="Below are Customer Collections for which accounts verification is pending. Once verified Please use the Accounts Overview to make sure DMS receipts are shared for the customers without delays."
                        type="warning"
                        showIcon
                    />

                    <div className="score">
                        {user.locations.map((location, index) => {
                            return (
                                <div className={`dashboard-item`} key={index}>
                                    <PendingList url={location} />
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : null}

            <Tabs onChange={callback} tabBarExtraContent={actions}>
                {user.locations.map((location, index) => {
                    return (
                        <TabPane tab={`${location} Customer Receipts`} key={index}>
                            <CollectionList today={today} history={history} url={location} department={dep} />
                        </TabPane>
                    );
                })}
            </Tabs>
        </section>
    );
}

const CollectionList = ({ url, history, department, today }) => {
    var cityKey = cityKeys

    const { user, isMobile } = useContext(GlobalContext);

    const [view, setView] = useState(isMobile);

    const [collections, setCollections] = useState([]);

    const [loader, setLoader] = useState(true);

    const [depID, setdepID] = useState();

    useEffect(() => {
        getDepartment();
        // getData();
    }, [today, department]);

    function getDepartment() {
        if (department) {
            var query = [
                {
                    field: 'description',
                    value: department,
                },

                {
                    field: 'dealer_code',
                    value: cityKey[url],
                },
            ];

            Departments.get(query)

                .then((result) => {
                    setdepID(result.departments[0].id);

                    let x = result.departments[0].id;

                    return x;
                })
                .then((x) => getData(x));
        }
    }

    function getData(x) {
        setLoader(true);

        let deptID = depID || x;

        var queries = [
            {
                field: 'transaction_date',
                value: moment(today).startOf('day').valueOf(),
            },

            {
                field: 'department_id',
                value: deptID,
            },
        ];

        queries.push({
            field: 'source',
            operator: 'in',
            value: ['kec', 'cashier', 'finance', 'sm', 'tl', 'KIA Website', 'Online'],
        });
        // }

        FirebaseUtils.getCollections(url, queries).then((result) => {
            if (result.length) {
                getMatching(result);
            } else {
                setCollections([]);

                setLoader(false);
            }
        });
    }

    /**
     *
     * Get matching Bank Entries
     *
     * @param {*} customer_receipts
     */
    function getMatching(customer_receipts) {
        setLoader(true);

        var queries = [
            {
                field: 'source',
                operator: '==',
                value: 'bank',
            },
            {
                field: 'reconcile_status',
                value: 'pending',
            },
            {
                field: 'isCredit',
                value: true,
            },
        ];

        FirebaseUtils.getBankCredits(queries).then((result) => {
            customer_receipts
                .filter((entry) => !entry.verified)
                .forEach((entry) => {
                    entry.matching = [];

                    result.forEach((receipt) => {
                        var condition;

                        if ((entry.utr !== '' || entry.ref !== '') && parseInt(receipt['credit']) === entry.amount) {
                            condition =
                                receipt['description'].indexOf(entry.ref) !== -1 ||
                                receipt['description'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.ref) !== -1;

                            if (condition) {
                                // Push the entries to matching
                                entry.matching.push(receipt);
                            }
                        }
                    });
                });

            console.log(customer_receipts);

            setCollections([...customer_receipts]);

            setLoader(false);
        });
    }

    function changeView(result) {
        setView(result);
    }

    return (
        <>
            <div className="list-header">
                <p className="page-desc">
                    {/* Listing collections from */}
                    {loader ? '. Loading data' : `. ${Object.keys(collections).length} results`}
                </p>

                <div className="list-actions">
                    <Button
                        onClick={() => {
                            getData();
                        }}
                        type="secondary"
                        size={'small'}
                    >
                        Refresh
                    </Button>

                    {['accounts', 'finance', 'service_accounts'].indexOf(user.role) !== -1 ? (
                        <Button
                            onClick={() => {
                                // setVisible(true);
                                history.push(`/customer-receipts/add/`);
                            }}
                            type="primary"
                            size={'small'}
                        >
                            Add
                        </Button>
                    ) : null}

                    <Switch
                        defaultChecked
                        onChange={changeView}
                        checked={view}
                        checkedChildren={<OrderedListOutlined />}
                        unCheckedChildren={<PicCenterOutlined />}
                    />
                </div>
            </div>

            <div className="report-listing-wrapper">
                {loader ? (
                    <PlaceHolder type="listing" />
                ) : (
                    <>{!view ? <TableView data={collections} department={department} /> : <CardList url={url} data={collections} />}</>
                )}
            </div>
        </>
    );
};

/**
 *
 * Showing the pending list
 *
 * @param {*} param0
 */
export const PendingList = ({ url }) => {
    const [collections, setCollections] = useState([]);

    const [loader, setLoader] = useState(true);

    const { user } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        setLoader(true);

        var queries = [
            {
                field: 'source',
                operator: 'in',
                value: ['kec', 'cashier', 'finance', 'sm', 'tl', 'KIA Website', 'Online'],
            },
            {
                field: 'verified',
                value: false,
            },
        ];

        FirebaseUtils.getCollections(url, queries).then((result) => {
            setLoader(false);

            if (result.length) {
                getMatching(result);
            } else {
                setCollections([]);
            }
        });
    }

    /**
     *
     * Get matching Bank Entries
     *
     * @param {*} customer_receipts
     */
    function getMatching(customer_receipts) {
        setLoader(true);

        var queries = [
            {
                field: 'source',
                operator: '==',
                value: 'bank',
            },
            {
                field: 'reconcile_status',
                value: 'pending',
            },
            {
                field: 'isCredit',
                value: true,
            },
        ];

        FirebaseUtils.getBankCredits(queries).then((result) => {
            customer_receipts
                .filter((entry) => !entry.verified)
                .forEach((entry) => {
                    entry.matching = [];

                    result.forEach((receipt) => {
                        var condition;

                        if ((entry.utr !== '' || entry.ref !== '') && entry.amount === parseInt(receipt['credit'])) {
                            condition =
                                receipt['description'].indexOf(entry.ref) !== -1 ||
                                receipt['description'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.utr) !== -1 ||
                                receipt['ref'].indexOf(entry.ref) !== -1;

                            if (condition) {
                                // Push the entries to matching
                                entry.matching.push(receipt);
                            }
                        }
                    });
                });

            // console.log(customer_receipts);

            setCollections(customer_receipts);

            setLoader(false);
        });
    }

    return (
        <>
            <div className="report-listing-wrapper">
                {loader ? (
                    <div className="">
                        <Skeleton />
                    </div>
                ) : (
                    <>
                        <div className={`dashboard-item`}>
                            <div className="card dashboard-item">
                                <Statistic title={`${url}`} value={collections.length} />

                                <a onClick={showDrawer}>View</a>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Drawer width={'80%'} placement="right" closable={false} onClose={onClose} visible={visible}>
                <Title level={3}>Pending List</Title>

                <Alert
                    message="Please make sure all the customer receipts are verified without delays so that it can proceed for DMS"
                    type="warning"
                    showIcon
                    closable
                />

                <TableView data={collections} />
            </Drawer>
        </>
    );
};

function TableView({ data, department }) {
    const salesColumns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Customer Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },

        {
            title: 'Type',
            key: 'type',
            onFilter: (value, record) => record.type.indexOf(value) !== -1,
            filters: [
                {
                    text: 'Cash',
                    value: 'Cash',
                },
                {
                    text: 'Net Banking',
                    value: 'Net Banking',
                },
                {
                    text: 'Cheque',
                    value: 'Cheque',
                },
                {
                    text: 'Debit Card',
                    value: 'Debit Card',
                },
                {
                    text: 'Credit Card',
                    value: 'Credit Card',
                },
            ],
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Tag color={'magenta'}>{text.type || 'Online Transfer'}</Tag>
                    </Space>
                );
            },
        },

        {
            title: 'Source',
            key: 'source',
            onFilter: (value, record) => record.source.indexOf(value) !== -1,
            filters: [
                {
                    text: 'Cashier',
                    value: 'cashier',
                },
                {
                    text: 'KEC',
                    value: 'kec',
                },
                {
                    text: 'Finance',
                    value: 'finance',
                },
                {
                    text: 'SM',
                    value: 'sm',
                },
                {
                    text: 'TL',
                    value: 'tl',
                },
            ],
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Tag color="blue">{text.source}</Tag>
                    </Space>
                );
            },
        },

        {
            title: 'On Account',
            dataIndex: 'account',
            key: 'account',
            onFilter: (value, record) => record.account.indexOf(value) !== -1,
            filters: [
                { text: 'BOOKING', value: 'BOOKING' },
                { text: 'PAYMENT', value: 'PAYMENT' },
                { text: 'FINANCE', value: 'FINANCE' },
                { text: 'EXCHANGE', value: 'EXCHANGE' },
                { text: 'INSURANCE', value: 'INSURANCE' },
                { text: 'ACCESSORIES', value: 'ACCESSORIES' },
            ],
        },

        {
            title: 'KEC',
            dataIndex: 'kec',
            key: 'kec',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },

        {
            title: 'Verified',
            dataIndex: 'verified',
            render: (text, record) => {
                let cls, caption;

                if (text === 'approved') {
                    cls = 'success';
                    caption = 'Approved';
                } else if (text === false) {
                    caption = 'Pending';
                    cls = 'warning';
                } else if (text === 'rejected') {
                    caption = 'Rejected';

                    cls = 'red';
                }

                return (
                    <Space size="middle">
                        <Tag color={cls}>{caption}</Tag>

                        {record.matching && record.matching.length ? <Badge status="success" text={`${record.matching.length} Matching`} /> : null}
                    </Space>
                );
            },
        },

        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/${text.city}/customer-receipts/${text.collectionId}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    const serviceColumns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Customer Name',
            dataIndex: 'name',
            key: 'name',
        },

        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },

        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },

        {
            title: 'Verified',
            dataIndex: 'verified',
            render: (text, record) => {
                let cls, caption;

                if (text === 'approved') {
                    cls = 'success';
                    caption = 'Approved';
                } else if (text === false) {
                    caption = 'Pending';
                    cls = 'warning';
                } else if (text === 'rejected') {
                    caption = 'Rejected';

                    cls = 'red';
                }

                return (
                    <Space size="middle">
                        <Tag color={cls}>{caption}</Tag>

                        {record.matching && record.matching.length ? <Badge status="success" text={`${record.matching.length} Matching`} /> : null}
                    </Space>
                );
            },
        },

        {
            title: '',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link to={`/${text.city}/customer-receipts/${text.collectionId}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    let columns;

    if (department === 'Department Handling Sales') {
        columns = salesColumns;
    } else {
        columns = serviceColumns;
    }

    return (
        <Table
            width="100%"
            size="small"
            scroll={{ x: true, y: 750 }}
            rowKey={(record) => record.index}
            dataSource={data}
            columns={columns}
            pagination={false}
        ></Table>
    );
}

function CardList({ data, url }) {
    console.log(data);
    return data.map((report, index) => {
        return <CollectionCard city={url} request={report} index={index} key={index} />;
    });
}

function CollectionCard({ city, request = {} }) {
    return (
        <Link className="request-card" to={`${request.city}/customer-receipts/${request.collectionId}`}>
            <div className="card">
                <h2 className="title">{request.name}</h2>

                <h3 className="title amount">{request.amount}</h3>

                <h4 className="title ">{request.city}</h4>

                <h4 className="title ">{request.phone}</h4>

                <h4>{request.remarks}</h4>

                <p className="address">
                    {/* <Tag color="magenta">{request.status || 'Initial'}</Tag> */}
                    {/* 
                <Badge status="processing" text={`${request.matching} Vehicles Matching`} /> */}
                </p>
                {/* Created at {request.created_at} by {request.created_by.name} */}
            </div>
        </Link>
    );
}
