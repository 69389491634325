/**
 *
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState } from 'react';

import moment from 'moment';

import { Button, Form, Input, Radio, InputNumber, DatePicker, TimePicker, Checkbox, Select, Row, Col } from 'antd';

import { withRouter } from 'react-router-dom';

import './form-creator.scss';

import FileUpload from './../../../../components/file-upload/file-upload';

import HeadSelector from './../../components/head-selector/head-selector';

import ModeSelector from './../../components/mode-selector/mode-selector';

import ReferenceSelector from './../../components/reference-select/reference-select';
import StockPoints from '../../components/stock-points/stock-points';
import FollowUpStatus from '../../components/follow_up_status/follow_up_status';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
};

const { TextArea } = Input;

const { Option } = Select;

/**
 * Component accepts fields to render the necessary components and returns the values on submission
 *
 * @param {*} param0
 * @returns
 */
function FormCreator({ history, config, model, formContent, modelIndex, onSubmit, callback, department }) {
    let [additional, setAdditional] = useState({});

    const [form] = Form.useForm();

    // Variable used for loading state
    const [loading, setLoading] = useState(false);

    // Variable stores the form body values
    const [content, setContent] = useState(() => {
        // Body for the files
        let body = formContent || { [model]: {} };

        config.fields.forEach((entry) => {
            if (entry.transform) {
                body[entry.field] = entry.transform(body[entry.field]);
            }
        });

        return body;
    });

    /**
     * Function handles trigger of onupload
     *
     * @param {*} element
     * @param {*} attachments
     */
    function onUpdate(element, attachments, selected) {
        form.setFieldsValue({ [element.field]: attachments });

        // Added temporary support
        // for updating additional column for select
        if (element.onUpdate) {
            let formBody = element.onUpdate(element, attachments, selected);

            // form.setFieldsValue(formBody)
            // let arr = [];

            // arr.push(formBody);

            setAdditional({ ...additional, ...formBody });
        }
    }

    return (
        <section className="form-creator">
            <Form
                form={form}
                {...layout}
                name="new-record"
                onFinish={(values) => {
                    setLoading(true);

                    onSubmit({ ...additional, ...values }).then(() => {
                        setLoading(false);
                    });
                }}
                onFieldsChange={(fields) => {
                    fields.forEach((field) => {
                        if (field.name[0].includes('Date')) {
                            content[field.name[0]] = moment(field.value).format('DD/MM/YYYY');
                        } else {
                            content[field.name[0]] = field.value;
                        }
                    });

                    setContent({ ...content });
                }}
                layout="vertical"
                // validateMessages={validateMessages}
                initialValues={{
                    ...content,
                    remarks: '',
                }}
            >
                {config.fields.map((field, key) => {
                    if (field.condition) {
                        return field.condition(content) ? (
                            <UserInput content={content} key={key} onUpdate={onUpdate} field={field} department={department} />
                        ) : null;
                    } else {
                        return <UserInput content={content} key={key} field={field} onUpdate={onUpdate} department={department} />;
                    }
                })}

                <Button loading={loading} type="primary" htmlType="submit" className="submit-button">
                    SUBMIT
                </Button>
            </Form>
        </section>
    );
}

export default withRouter(FormCreator);

/**
 * Component accepts user input according to type
 *
 * @param {*} param0
 */
function UserInput({ field, onUpdate, content, department }) {
    /**
     * According to the type render each element
     *
     * @param {*} field
     */
    const inputElement = (field) => {
        switch (field.type) {
            case 'number':
                return <InputNumber />;

            case 'input':
                return <Input />;

            case 'radio':
                return (
                    <Radio.Group>
                        {field.options.map((option, key) => (
                            <Radio key={key} value={option}>
                                {option}
                            </Radio>
                        ))}
                    </Radio.Group>
                );
            case 'radioText':
                return (
                    <Radio.Group>
                        {field.options.map((option, key) => (
                            <Radio key={key} value={option.value}>
                                {option.text}
                            </Radio>
                        ))}
                    </Radio.Group>
                );
            case 'checkbox':
                return (
                    <Checkbox.Group style={{ width: '100%' }}>
                        <Col>
                            {field.options.map((option, key) => {
                                let opt = typeof option === 'string' ? option : option.value;

                                return (
                                    <Row>
                                        <Checkbox key={key} value={opt}>
                                            {opt}
                                        </Checkbox>
                                    </Row>
                                );
                            })}
                        </Col>
                    </Checkbox.Group>
                );

            // case 'checkbox':
            //     return <Checkbox.Group options={field.options}/>
            case 'textarea':
                return <TextArea rows={4} />;

            case 'selectText':
                return (
                    <Select style={{ width: 120 }}>
                        {field.options.map((option, key) => (
                            <Option key={key} value={option.value}>
                                {option.text}
                            </Option>
                        ))}
                    </Select>
                );

            case 'select':
                return (
                    <Select style={{ width: 120 }}>
                        {field.options.map((option, key) => (
                            <Option key={key} value={option}>
                                {option}
                            </Option>
                        ))}
                    </Select>
                );

            // case 'checkbox':
            //     return <Checkbox.Group options={field.options} />

            case 'datetime':
                return <DatePicker format={'DD/MM/YYYY'} />;

            case 'date':
                return <DatePicker format={'DD/MM/YYYY'} />;

            case 'time':
                return <TimePicker format={'HH:mm A'} />;

            case 'upload':
                return (
                    <FileUpload
                        initial={content[field.field]}
                        multiple={field.multiple}
                        maxSize={field.maxSize || 3}
                        callback={(attachment) => onUpdate(field, attachment)}
                        onProgress={() => {}}
                    />
                );

            case 'head-selector':
                return <HeadSelector callback={(value) => onUpdate(field, value)} department={department} />;

            case 'stock-pointer':
                return <StockPoints callback={(value) => onUpdate(field, value)} department={department} />;
            case 'follow_up_status':
                return <FollowUpStatus callback={(value) => onUpdate(field,value)} department={department} />;
            case 'mode-selector':
                return <ModeSelector callback={(value) => onUpdate(field, value)} department={department} />;

            case 'reference-selector':
                // Load the queries for the content
                let queries = [];

                if (field.queries) {
                    queries = field.queries(content);
                } else {
                }

                return <ReferenceSelector queries={queries} table={field.table} callback={(value, selected) => onUpdate(field, value, selected)} />;

            default:
                return <Input />;
        }
    };

    let props = {};

    // The extra text
    if (field.extra) {
        props.extra = field.extra;
    }

    return (
        <Form.Item
            {...props}
            name={field.field}
            label={field.caption}
            rules={[{ required: true, message: field.placeholder || 'Please enter ' + field.caption }]}
        >
            {inputElement(field)}
        </Form.Item>
    );
}
