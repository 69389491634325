import Base from './base';

import { Link } from 'react-router-dom';

import { Requests, Uploads, Allocations, StockTransfers, Cart, StockTransactions, BillDetails, Bills, Cancellations, Bookings, StatusMasters } from './index';

import { message } from 'antd';

import FirebaseUtils from '../utils/firebase.utils';

import moment, { calendarFormat } from 'moment';
import { cityKeys } from '../modules/global-config';

var cityKey = cityKeys

class Vehicles extends Base {
    constructor() {
        super();

        this.fields = [];

        this.columns = [];
    }

    get getEndpoint() {
        return 'vehicles';
    }

    get path() {
        return `vehicles`;
    }

    get getName() {
        return `vehicles`;
    }

    /**
     * Get Stock of entire location filtering high priority requests
     *
     * @param {*} q
     * @returns
     */
    getAllAvailableStock = (q = []) => {
        return new Promise((resolve, reject) => {
            var queries = [
                {
                    field: 'isHighPriority',
                    value: true,
                },
                {
                    field: 'highPriorityStatus',
                    value: 'approved',
                },
                {
                    field: 'billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];

            Requests.get(queries).then((vip) => {
                var queries = [
                    {
                        field: 'update',
                        operator: "not-in",
                        value: ['remove', "bbnd"],
                        // value: ['Free Stock', 'In transit', 'Allocated'],
                    },
                ];

                q = [].concat(...q, ...queries);

                this.get(q).then((result) => {
                    // Remove the vehicles that are billed or not in stock
                    let cars = result.vehicles.filter((vehicle) => vehicle.update !== 'remove');
                    
                    // vip=vip.requests.filter((data) => data.status !== 'removed' &&  data.status !== 'removed');

                    cars = filterMatching(
                        vip.requests.filter((req) => !req.allocation.vinNo &&  req.status != 'removed'),
                        cars
                    );

                    //filter vehicles that are allocated
                    var filtered = cars.filter((vehicle) => vehicle.allocated);

                    Promise.all(
                        filtered.map((vehicle) => {
                            //get request for each alloacted vehicle
                            return Requests.getRecord(vehicle.allocated.requestId).then((result) => {
                                return {
                                    ...vehicle,
                                    ...result,
                                };
                            });
                        })
                    ).then((res) => {
                        // Filter cars that re not allocated
                        let filteredCars = cars.filter((car) => !car.allocated);

                        //add the allocated cars with request and unallocated cars into single array
                        var vehicles = [...res, ...filteredCars];

                        var models = groupBy(vehicles, 'Model');

                        var queries = [
                            {
                                field: 'model',
                                value: 'vehiclces',
                            },
                        ];

                        var config = {
                            orderBy: 'created_time',
                            limit: 1,
                        };

                        Uploads.get(queries, config).then((result) => {
                            resolve({
                                vehicles: models,
                                lastUpdated: result.lastUpdated,
                                all: vehicles,
                            });
                        });
                    });
                });
            });
        });
    };

    /**
     * Function to upload stock and create stock transaction
     * records
     *
     * @param {*} records
     * @param {*} rec
     * @param {*} location
     * @returns
     */
    writestock = (records, rec, location) => {
        return this.uploadStock(records, rec, location);
    };

    /**
     * Update Stock
     * @param {*} records
     * @returns
     */
    uploadStock = (records, rec, location) => {
        var user = this.getUser();

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        Object.keys(records).forEach((key) => {
            var docRef = app.collection(this.endpoint).doc(key);
            if (records[key].stock) {
                var stockRef = app.collection('stock_transactions').doc();

                batch.set(stockRef, rec[key], { merge: true });

                delete records[key].stock;

                records[key] = {
                    ...records[key],
                    stock_transactions_id: stockRef.id,
                };
            }
            batch.set(docRef, records[key], { merge: true });
        });

        let body = {
            model: this.endpoint,
            created_by: {
                name: user.name,
                role: user.role,
            },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_time: moment().valueOf(),
            dealerCode: this.dealerCode(location),
            city: location,
            deleted_at: null,
            deleted_by: null,
        };

        //Update Uploads with recent updation of Tables(Vehicles and stock_transactions)
        return Uploads.add(body, { hideAlert: true }).then(() => {
            body.model = 'stock_transactions';

            console.log(body);

            return Uploads.add(body, { hideAlert: true }).then(() => {
                return batch.commit();
            });
        });
    };

    /**
     * Get available stock of city
     *
     * @param {*} city
     * @returns
     */
    getAvailableStock = (dealerQueries = [], vehicleQueries = []) => {
        return new Promise((resolve, reject) => {
            var q = [
                {
                    field: 'isHighPriority',
                    value: true,
                },
                {
                    field: 'highPriorityStatus',
                    value: 'approved',
                },
                {
                    field: 'billing.status',
                    value: 'pending',
                },
                {
                    field: 'status',
                    operator: '!=',
                    value:'removed'
                },
            ];

            q = [].concat(...q, ...dealerQueries);

            // Get all high prioriy requests
            Requests.get(q).then((vips) => {
                var local_queries = [
                    {
                        field: 'update',
                        operator: 'not-in',
                        value: ['remove', 'bbnd'],
                        // field: 'Stock Status',
                        // operator: 'in',
                        // value: ['Free Stock', 'In transit', 'Allocated'],
                    },
                ];

                var q = [].concat(...vehicleQueries, ...local_queries, ...dealerQueries);

                this.get(q).then((result) => {
                    // Remove the vehicles that are billed or not in stock
                    let cars = result.vehicles.filter((vehicle) => vehicle.update !== 'remove');

                    //filter vehicles that are not in vip requests
                    cars = filterMatching(vips.requests, cars);

                    //filter vehicles that are allocated
                    var filtered = cars.filter((vehicle) => vehicle.allocated);

                    Promise.all(
                        filtered.map((vehicle) => {
                            //get request for each alloacted vehicle
                            return Requests.getRecord(vehicle.allocated.requestId).then((result) => {
                                return {
                                    ...vehicle,
                                    ...result,
                                };
                            });
                        })
                    ).then((res) => {
                        //filter cars that re not allocated
                        let filteredCars = cars.filter((car) => !car.allocated);

                        //add the allocated cars with request and unallocated cars into single array
                        var vehicles = [...res, ...filteredCars];

                        //group the vehicles by their model
                        var models = groupBy(vehicles, 'Model');

                        var q = [
                            {
                                field: 'model',
                                value: 'vehicles',
                            },
                        ];

                        q = [].concat(...q, ...dealerQueries);

                        var config = {
                            orderBy: 'created_time',
                            limit: 1,
                            order: 'desc',
                        };

                        Uploads.get(q, config).then((result) => {
                            resolve({
                                vehicles: models,
                                lastUpdated: result.uploads[0],
                                all: vehicles,
                            });
                        });
                    });
                });
            });
        });
        //  });
    };

    /**
     * For the city passed load all vehicles
     *
     * @param {*} city
     * @returns
     */
    getVehicles = (city, status) => {

        var queries = [
            {
                field: 'dealerCode',
                value: cityKey[city],
            },]

        if (status) {

            queries.push({
                field: 'update',
                value: status,
            })

        } else {
            queries.push({
                field: 'update',
                operator: '!=',
                value: 'remove',
            })
        }


        return this.get(queries);
    };

    /**
     * Function to get stock with value
     */
    getStockWithValue = (city, pricing, status) => {
        return this.getVehicles(city, status).then((result) => {
            // vehicles
            let vehicles = result.vehicles.map((record) => this.getVehicleValue(record, pricing));

            return vehicles;
        });
    };

    /**
     *
     */
    getVehicleValue = (vehicle, pricing) => {
        var basicPrice = vehicle['Basic Price'];

        // Get the Model
        // var model = vehicle['Model'].toLowerCase();

        // From the price master find the matching variant
        var pricing = pricing.filter((item) => item.key === vehicle['Variant Code'] + '-' + vehicle['Color Type'])[0];

        var totalPrice = 0;

        if (pricing) {
            var igst = pricing['igst'];

            var cess = pricing['cess'];

            //var tcs = 1 / 100;

            // Find the price of vehicle with tax
            // totalPrice += basicPrice + ((basicPrice * igst) / 100) + ((basicPrice * cess) / 100) + (basicPrice * tcs);
            var stockValue = basicPrice
            var stockValueGst = basicPrice + (basicPrice * igst) / 100 + (basicPrice * cess) / 100;

            // let final_amount = amount + amount * tcs;

            // totalPrice = totalPrice + amount + amount * tcs;

            // stockValue[city] += parseFloat(totalPrice);

            // total += parseInt(entry['Basic Price']);

            // stockValue[city] += parseFloat(totalPrice);

            return {
                ...vehicle,
                stock_value: stockValue,
                stock_value_including_gst: stockValueGst
            };
        } else {
            // arr.push(entry);

            return {
                ...vehicle,
                // stock_value: 'M'
                // stock_value: final_amount
            };
        }
    };

    unblock = async (vin, batch) => {
        var user = this.getUser();

        var params = {
            allocated: this.firebase().firestore.FieldValue.delete(),
            status: 'new',
            updated_by: { name: user.name, role: user.role },
            updated_at: moment().format('DD/MM/YYYY HH:mm'),
        };

        // To update vehicles record of id 'vin' and updated with params
        var vehicleRef = await this.getRecordReference(vin);

        await batch.update(vehicleRef, params);
    };

    /**
     * Block the request for a vehicle
     *
     * @param {*} request
     * @param {*} vehicle
     */
    blockVehicle = (request, vehicle, values, batch) => {
        console.log('request', request.bookingNo)
        var user = this.getUser();

        return new Promise(async (resolve, reject) => {
            let creation = {
                created_by: { name: user.name, role: user.role },
                created_at: moment().format('DD/MM/YYYY HH:mm'),
                created_date: moment().startOf('day').valueOf(),
                created_time: moment().valueOf(),
            };

            let newBooking = {
                requestId: request.id,
                request_dealerCode: request.dealerCode,
                ...creation,
            };

            // To update the vehicles record of id 'Vin Number' with newbooking
            var vehicleRef = await this.getRecordReference(vehicle['Vin Number']);

            // Get vehicle record to check if the vehicle is allocated before
            const vehicleDetails = await this.getRecord(vehicle['Vin Number'])
            // If vehicle is not allocated
            if (vehicleDetails && !vehicleDetails.allocated) {
                await batch.update(vehicleRef, { allocated: newBooking });

                request = {
                    ...request,
                    vinNo: vehicle['Vin Number'],
                };

                let allocationBody = {
                    status: 'approved',
                    vinNo: vehicle['Vin Number'],
                    ...newBooking,
                    // customerID: request.customer.customerID,
                    bookingNo: request.bookingNo,
                    dealerCode: request.dealerCode,
                    deleted_at: null,
                    deleted_by: null,
                };

                // If its a stock transfer request
                if (request.destination_city) {
                    allocationBody = {
                        ...allocationBody,
                        isTransfer: true,
                        destination_city: request.destination_city,
                    };
                }

                // blocking create new record from allocation table
                var allocationRef = await Allocations.getRecordReference();

                await batch.set(allocationRef, Allocations.appendDefaultValues(allocationBody));

                // Get booking status from status master
                await Bookings.updateModelStatus(request.bookingNo, 'ALLOCATED')

                let params = {
                    allocation: {
                        status: 'approved', // get approval
                        vinNo: vehicle['Vin Number'],
                        allocationId: allocationRef.id, // saving created allocation record Id
                        ...creation,
                    },
                    pricing: {
                        status: 'pending',
                    },

                    ccm: {
                        status: 'pending',
                    },
                };

                return await Requests.updateRequest(
                    request.id,
                    params,
                    'Soft Allocation',
                    'Soft Allocation Done by ' + user.name,
                    'allocation',
                    batch
                ).then(async () => {
                    // If there is another location set
                    if (request.dealerCode !== vehicle.dealerCode) {
                        return await StockTransfers.createRequest(request, vehicle, user, values, batch).then(async (result) => {
                            let params1 = {
                                transferRequest: result.id,
                                updated_by: { name: user.name, role: user.role },
                                updated_at: moment().format('DD/MM/YYYY HH:mm'),
                            };

                            // To update the request record of id 'request.id' with params1
                            var requestRef = await Requests.getRecordReference(request.id);

                            await batch.update(requestRef, params1);
                            // Return stock tramsfer id, to generate gate pass
                            newBooking = {
                                ...newBooking,
                                stockTransferId: result.id
                            }

                            resolve(newBooking);
                        });
                    } else {
                        resolve(newBooking);
                    }
                });
            } else {
               message.error("Sorry! The Vehicle is Already Allocated")
            }
        });
    };

    //Cancel Invoice for invoice  with type basic (vehilce invoice)
    cancelInvoice = async (cancelValues, values, record, matching, cartItems, request, vehicle) => {
        var app = FirebaseUtils.getApp();

        var batch = app.batch();
        //Create a new record in cancellations

        let setRef = Cancellations.getRecordReference();

        await batch.set(setRef, this.appendDefaultValues(cancelValues));

        values.cancellation_id = setRef.id;

        //Update bill with cancellation_status and id
        let billRef = Bills.getRecordReference(record.id);

        await batch.update(billRef, values);

        let bill_id = values.id;

        var stocks;

        var queries = [
            {
                field: 'item_identity_number',
                value: vehicle['Vin Number'],
            },
        ];

        var config = {
            orderBy: 'transaction_code',
        };

        //Get stock transaction data
        return StockTransactions.get(queries, config).then(async (result) => {
            //If the opening was done after bill invoicing, record in stock transaction wont be there. In such cases a record in stock-transactions is to be added to cancel the invoice
            if (result.stock_transactions.length !== 0) {
                stocks = result.stock_transactions[0];

                delete stocks.id;

                var params = {
                    ...stocks,
                    quantity: 1,
                    transaction_code: 45,
                    dealerCode: record.dealerCode,
                    transaction_date: new Date(),
                    deleted_at: null,
                    deleted_by: null,
                };

                //Create a new record in stock transaction with +1 quantity
                var stockRef = StockTransactions.getRecordReference();

                await batch.set(stockRef, StockTransactions.appendDefaultValues(params));

                var arr = [];

                var query = [
                    {
                        field: 'bill_id',
                        value: bill_id,
                    },
                ];

                //Update Bill Details with cancalletion_status
                return BillDetails.get(query)
                    .then((detail) => {
                        let det = detail['bill-details'];

                        arr.push(det[0]);

                        return arr;
                    })
                    .then((arr) => {
                        arr.map(async (item) => {
                            let params = {
                                ...item,
                                cancellation_status: true,
                            };

                            let billDetailsRef = BillDetails.getRecordReference(item.id);

                            await batch.update(billDetailsRef, params);
                        });
                    })
                    .then(async (result) => {
                        let config = {
                            hideAlert: true,
                        };

                        if (cartItems.length) {
                            cartItems[0].subItems.forEach((item) => {
                                if (['Ex Showroom Price'].indexOf(item.description) !== -1) {
                                    item.status = 'active';

                                    return item;
                                }
                            });
                        }
                        let cart;

                        if (cartItems[0]) {
                            cart = {
                                ...cartItems[0],
                                status: 'active',
                            };
                        }

                        let actualBilling = {
                            ...request.actual_billing,
                            status: 'cancelled',
                        };

                        //Update Cart with status active
                        if (cartItems.length) {
                            let cartRef = Cart.getRecordReference(cartItems[0].id);

                            await batch.update(cartRef, Cart.appendUpdateDefaultValues(cart));
                        }

                        //Update Vehicles
                        let vehicleRef = this.getRecordReference(vehicle['Vin Number']);

                        await batch.update(vehicleRef, { update: 'new', status: this.firebase().firestore.FieldValue.delete() });

                        if (values.attached_with != 'independent') {
                            //Update Requests
                            let requestRef = Requests.getRecordReference(request.id);

                            await batch.update(requestRef, { actual_billing: actualBilling });
                        }

                        // Update biling status in booking
                        if (request.bookingNo) {
                            let bookingRef = Bookings.getRecordReference(request.bookingNo)

                            let booking = await Bookings.getRecord(request.bookingNo)

                            await batch.update(bookingRef, { billing: { ...booking.billing, status: 'pending' }, actual_billing: { ...booking.actual_billing, status: 'pending' } })
                        }

                        return batch.commit().then(() => {
                            message.success(`Invoice ${record.invoice_number} has cancelled successfully. `);
                        });
                    });
            } else {
                message.error('No Record in Stock Transaction found');
                return false;
            }
        });
    };

    /**
     * Unblocking the vehicle and request
     */
    unblockVehicle = (vehicle, request, batch) => {
        var user = this.getUser();

        let creation = {
            unblocked_at: moment().format('DD/MM/YYYY HH:mm'),
            unblocked_date: moment().startOf('day').valueOf(),
            unblocked_time: moment().valueOf(),
            unblocked_by: { name: user.name, role: user.role },
        };

        let params = {
            allocation: {
                status: 'cancelled',
                ...creation,
            },
            pricing: { status: 'cancelled' },
        };

        return new Promise(async (resolve, reject) => {
            if (request) {
                // unblocking allocation record from allocation table
                var allocationRef = await Allocations.getRecordReference(request.allocation.allocationId);

                await batch.update(allocationRef, params.allocation);

                // Reset the vehicle for allocation
                await this.unblock(vehicle['Vin Number'], batch);

                // Revert the allocation status
                await Requests.unassign(vehicle.allocated.requestId, batch);

                // Clear the items in cart
                await Cart.clearBasic(request, batch);

                // If there is a stock transfer requested
                if (request.transferRequest) {

                    //Stock transfer request is cancelled
                    StockTransfers.getRecord(request.transferRequest).then(async (result) => {

                        const transfer = result

                        // If transfer request is present then it is to be cancelled on unblock if it is virtual transfer
                        if (transfer.active) {
                            // if (transfer.sub_mode === "virtual") {


                            //Cancel stock transfer request
                            await StockTransfers.cancelStockTransferRequest(transfer, batch)

                            //Update transferRequest in request 
                            var requestRef = await Requests.getRecordReference(request.id);

                            await batch.update(requestRef, { transferRequest: null });

                            resolve()

                            // } else {

                            //     message.warning("Actual stock transfer request for this vehicle is not cancelled")

                            //     resolve();

                            // // If there is an actual stock transfer that is just initiated, cancel it
                            // if (request.dealerCode !== vehicle.dealerCode) {
                            //     let params = {
                            //         status: 'cancelled',
                            //         updated_by: { name: user.name, role: user.role },
                            //         updated_at: moment().format('DD/MM/YYYY HH:mm'),
                            //     };

                            //     // To update the stock_transfer rcord of id 'request.transferRequest' with params
                            //     var stockRef = await StockTransfers.getRecordReference(request.transferRequest);

                            //     await batch.update(stockRef, params);

                            //     resolve();
                            // } else {
                            // }
                            // }
                        }
                        else {
                            resolve()
                        }
                    });

                }
                else {
                    resolve()
                }
            }


        });
    };
}

export default Vehicles;

function filterMatching(vips, vehicles) {
    // Filter any that is required for vips
    Object.keys(vips).forEach((id) => {
        var preference = vips[id];

        let match = vehicles.find((vehicle, index) => {
            // Todo here we have to filter all matches
            if (vehicle['Variant'] === preference['variant'] && vehicle['Exterior Color Name'] === preference['color']) {
                vehicles.splice(index, 1);

                return true;
            }

            return false;
        });
    });

    return vehicles;
}

function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);

        return rv;
    }, {});
}
