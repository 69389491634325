import React from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import { Tag, Badge, message, Space } from 'antd';
import { Bookings, Requests, StatusLogs, StatusMasters, StockTransactions, StockTransfers, Vehicles } from '../../models';

import FirebaseUtils from '../../utils/firebase.utils';

function DeliveryScheduleCard({ vehicle, index, step }) {
    return (
        <Link className="report-item" to={`${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">
                    {/* {vehicle.delivery.scheduled_delivery_date} {vehicle.delivery.scheduled_delivery_time} */}
                    {moment(vehicle.delivery.scheduled_delivery_time).format('DD/MM/YYYY  HH:mm A')}
                </h2>
                {/* {vehicle.finance && vehicle.finance.documentStatus ? <Tag color="orange">{vehicle.finance && vehicle.finance.documentStatus}</Tag> : null} */}
                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}
                <h3 className="title">{vehicle.customer.customerName}</h3>
                <h2 className="title">{vehicle.customer.phone}</h2>
                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}
                </h3>
                <p className="address">
                    KEC {vehicle.kec} , {vehicle.created_at}
                </p>
                <p>Delivery scheduled on : {moment(vehicle.delivery.scheduled_time).format('DD/MM/YYYY  HH:mm A')}</p>
                Delivery Note :{' '}
                <Tag color="magenta"> {vehicle.delivery.note && vehicle.delivery.note.length ? 'Delivery Note Taken' : 'Pending'}</Tag>
                Settlement :{' '}
                <Tag color={vehicle.delivery.settlementTaken ? 'green' : 'red'}> {vehicle.delivery.settlementTaken ? 'Ready' : 'Pending'}</Tag>
            </div>
        </Link>
    );
}


function TransferCard({ city, vehicle = {}, index, step }) {
    return (
        <Link className="request-card" to={`/${step.redirect_to}/${vehicle.from}/${index}`}>
            <div className="card">
                <h2 className="title amount ">{vehicle.from + ' -> ' + vehicle.to}</h2>
                <h4 className="title">Vin No : {vehicle.vinNo}</h4>
                <h3 className="title ">Booking : {vehicle.bookingNo}</h3>
                <h4>{vehicle.remarks}</h4>
                <Tag color="purple">{vehicle.status || 'Initial'}</Tag>
                Created at {vehicle.created_at} by {vehicle.created_by_name}
            </div>
        </Link>
    );
}

function TPCard({ vehicle, index, step }) {
    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">{vehicle.rto && vehicle.rto.hsrp_no}</h2>
                <h3 className="title">{vehicle.allocation.vinNo}</h3>
                <h2 className="title">{vehicle['variant']}</h2>
                {/* <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}
                </h3> */}
                <p className="address">KEC on {vehicle.kec}</p>

                {waitingTime && waitingTime.toString().length > 0 ? (
                    <p className="date-time" style={{ display: 'flex' }}>
                        Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (
                        <div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                    </p>
                ) : null}

                {vehicle.finance && vehicle.finance.documentStatus ? <Badge status="processing" text={vehicle.finance.documentStatus} /> : null}
            </div>
        </Link>
    );
}

/**
 * Columns for Request Table
 */
export const requestColumns = [
    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName',
    },
    {
        type: 'input',
        caption: 'Part Name',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'warranty.status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant',
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
];

export const deliverySteps = [
    {
        // caption: 'Scheduled Delivery',
        // submenus: [
        //     {
        path: '/scheduled-delivery',
        caption: 'Upcoming Delivery',
        //status: 'Login',
        redirect_to: 'delivery-checklist',
        queries: [
            {
                field: 'delivery.schedule',
                value: 'Delivery Scheduled',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        Card: DeliveryScheduleCard,
        columns: [
            {
                title: '#',
                dataIndex: 'index',
                render: (value, item, index) => index + 1,
                disableExport:true
            },
            {
                title: 'Name',
                dataIndex: 'Name',

                render: (record, item, index) => {
                    return item.customer.customerName;

                },
                //For download data
                exportDefinition: (record) => {
                    
                    return record.customer.customerName;
                },
            },
            {
                title: 'Scheduled Delivery Time',
                dataIndex: 'scheduled_delivery_time',

                render: (record, item, index) => {
                    return moment(item.delivery.scheduled_delivery_time).format('DD/MM/YYYY  HH:mm A');

                },

                //For download data
                exportDefinition: (record) => {
                    return moment(record.delivery.scheduled_delivery_time).format('DD/MM/YYYY  HH:mm A');
                },
            },
            {
                title: 'Customer Phone',
                dataIndex: 'phone',

                render: (record, item, index) => {
                    return item.customer.phone             
                },
                 //For download data
                 exportDefinition: (record) => {
                    return record.customer.phone  ;
                },
            },
             {
                title: "Vin Number",
                dataIndex: "Vin Number",
                render: (record, item, index) => {
                    return item.allocation.vinNo
                },
                 //For download data
                 exportDefinition: (record) => {
                    return record.allocation.vinNo  ;
                },
            },
            {
                title: 'Action',
                key: 'action',
                 disableExport: true,
                render: (text, item, index) => {
                    return (
                        <Space size="middle">
                            <Link to={`delivery-checklist/${item.city}/${item.id}`}>View</Link>
                        </Space>
                    );
                },

            }
        ]
    },

    {
        // caption: 'Scheduled Delivery',
        // submenus: [
        //     {
        path: '/delivery-list',
        caption: 'Delivery  List',
        //status: 'Login',
        redirect_to: 'delivery-checklist',
        model: 'stock_transfers',
        queries: ({ user }) => {
            return [
                {
                    field: 'city',
                    value: user.locations[0],
                },
                {
                    field: 'status',
                    value: 'requested',
                }, {
                    field: 'mode',
                    value: 'delivery',
                }
            ];
        },

        Card: TransferCard,
        //     }
        // ]
    },

    {
        path: '/hsrp-fitment-waiting',
        caption: 'HSRP Fitment Pending',
        // model: 'rto',
        redirect_to: 'hsrp-fitment',
        queries: [
            {
                field: 'rto.status',
                value: 'HSRP Fitment Pending',
            },
            {
                field: 'status',
                operator: '!=',
                value:'removed'
            },
        ],
        field_time: 'rto.hsrp_upload_time',
        Card: TPCard,
    },

    // {
    //     caption: 'Before Retail',
    //     submenus: [
    //         {
    //             path: '/data-collection',
    //             caption: 'Data Collection',
    //             status: 'pending',
    //             redirect_to: 'warranty',
    //             queries: [{
    //                 field: 'warranty.process',
    //                 value: 'incomplete'
    //             },
    //             {
    //                 field: 'warranty.status',
    //                 value: 'pending'
    //             }]
    //         }
    //     ]
    // },

    // {
    //     caption: 'After Retail',
    //     submenus: [
    //         {
    //             path: '/login',
    //             caption: 'Login',
    //             status: 'Login',
    //             redirect_to: 'warranty-login',
    //             queries: [{
    //                 field: 'warranty.process',
    //                 value: 'incomplete'
    //             },
    //             {
    //                 field: 'warranty.status',
    //                 value: 'Login'
    //             }]

    //         },
    //     ]
    // },

    // // Data Collection Form
    // {
    //     isForm: true,
    //     caption: 'Collection Form',
    //     path: '/warranty/:city/:id',
    //     post_status: 'Data Collected',
    //     fields: [

    //         {
    //             type: 'radio',
    //             options: ['Yes', 'No'],
    //             caption: 'Opting for Extended Warranty',
    //             field: 'type'
    //         },

    //         {
    //             type: 'input',
    //             caption: 'No of Years',
    //             field: 'years',
    //             condition: (values) => {
    //                 return values.type === 'Yes'
    //             }
    //         },

    //         {
    //             type: 'textarea',
    //             caption: 'Remarks',
    //             field: 'remarks'
    //         },
    //     ]
    // },

    // // Login Form
    // {
    //     isForm: true,
    //     caption: 'Collection Form',
    //     path: '/warranty-login/:city/:id',
    //     post_status: 'Payment Pending',
    //     fields: [

    //         {
    //             type: 'upload',
    //             field: 'attachment',
    //             caption: 'Insurance '
    //         },

    //         {
    //             type: 'textarea',
    //             caption: 'Remarks',
    //             field: 'remarks'
    //         },
    //     ]
    // },

    // Delivery Checklist
    {
        isForm: true,
        model: 'delivery',
        caption: 'Delivery Checklist',
        path: '/delivery-checklist/:city/:id',
        formValidation: {
            message: 'To complete this task, delivery note has to be taken and Settlement has to be approved by Accounts and Gate pass should be generated',
            validation: (request) => {
                return request.delivery.note && request.delivery.settlementTaken && request.delivery.status === 'Gatepass Taken' ? false : true;
            },
        },

        post_status: 'Delivered',
        notification: (request, user) => {
            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Congrats , Vehicle Delivered',
                body: `Delivery Completed for ${request.allocation.vinNo}, ${request.variant} of ${cName}. `,
            };
        },

        onSubmit: async (request, values) => {

            var app = FirebaseUtils.getApp();

            var batch = app.batch();

            return Bookings.getRecord(request.bookingNo).then(async (res) => {

                let config = {
                    hideAlert: true
                };

                let body = {
                    status: 'done',
                };
                let creation = {
                    actual_delivery_at: moment().format('DD/MM/YYYY HH:mm'),
                    actual_delivery_date: moment().startOf('day').valueOf(),
                    actual_delivery_time: moment().valueOf(),
                };

                let delivery = {
                    ...request.delivery,
                    ...creation,
                    ...values,
                    schedule: 'Delivery Completed',
                    status: 'Delivery Completed',
                    process: 'complete',

                }
                let actual_delivery = {
                    ...request.actual_delivery,
                    ...creation,
                    status: 'Delivery Completed',
                    process: 'complete',
                }

                // Update booking
                const bookingRef = Bookings.getRecordReference(request.bookingNo)

                await batch.update(bookingRef, { actual_delivery: body }, config);
                 
                // On delivery confirm, we update request's  status to Delivery Completed
                const requestsRef = Requests.getRecordReference(request.id)

                await batch.update(requestsRef, { actual_delivery: actual_delivery, delivery: delivery }, config);

                const stockRef = StockTransfers.getRecordReference(request.actual_delivery.stock_transfer_id)
                // On delivery confirm, we update stock transfer status to done
                await batch.update(stockRef, { status: 'done', fulfilled: true }, config)

                // A new record in stock transaction is created with quantity -1, indicating the removal of stock
                const query = [{
                    field: 'item_identity_number',
                    value: request.allocation.vinNo
                },
                {
                    field: 'transaction_code',
                    value: 15,
                },]

                var stockDetails = await StockTransactions.get(query)
                stockDetails = stockDetails.stock_transactions

                delete stockDetails[0].id
                const params = {
                    ...stockDetails[0],
                    quantity: -1,
                    transaction_date: new Date(),
                    transaction_code: 60

                }
                // Create stock out record in stock transactions
                const stockTransactionRef = StockTransactions.getRecordReference()
                await batch.set(stockTransactionRef, params, config)


                const vehicleRef = Vehicles.getRecordReference(request.allocation.vinNo)
                // Update in vehicles is updated to remove
                await batch.update(vehicleRef, { update: 'remove' }, config)

                var content = {
                    id: request.actual_delivery.stock_transfer_id,
                    vinNo: request.allocation.vinNo,
                    remarks: values.remarks
                }

                await StockTransfers.createStatusLog('DELIVERY', content, batch, values)

                try {
                    await batch.commit();
                    message.success('Successfully Completed Delivery');
                } catch (commitError) {
                    message.error('Failed to complete delivery. Please try again.');
                }

            });
        },
        fields: [
            {
                type: 'checkbox',
                options: ['Offer tea/coffee', 'Seat customer', 'Introduce executives'],
                field: 'checklist',
                caption: 'Tasks to complete',
            },

            {
                type: 'upload',
                field: 'delivery_photo',
                caption: 'Delivery Photo',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
    },
    {
        isForm: true,
        caption: 'HSRP Fitment',
        path: '/hsrp-fitment/:city/:id',
        model: 'rto',
        post_status: 'HSRP Fitment Completed',
        fields: [
            // {
            //     type: 'upload',
            //     caption: 'Upload Photo',
            //     field: 'hsrp-photo'
            // },

            {
                type: 'upload',
                caption: 'Upload BarCode',
                field: 'hsrp-barcode',
            },

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks',
            },
        ],
        extraParams: (request, params) => {
            let creation = {
                hsrp_upload_at: moment().format('DD/MM/YYYY HH:mm'),
                hsrp_upload_date: moment().startOf('day').valueOf(),
                hsrp_upload_time: moment().valueOf(),
            };

            return {
                rto: {
                    ...creation,
                    ...params,
                    process: 'complete',
                },
            };
        },
    },
];
