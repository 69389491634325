import React, { useState, useContext, useEffect } from 'react';

import {
    Collapse,
    Typography,
    Badge,
    Alert,
    List,
    Tabs,
    Select,
    Button,
    Statistic,
    Row,
    Col,
    Dropdown,
    Menu
} from 'antd';

import { RightOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons';

import Rupee from '../../../../components/rupee/rupee';

import { DatePicker, Space, Spin } from 'antd';

import { Link } from 'react-router-dom';

import moment from 'moment';

import FirebaseUtils from '../../../../utils/firebase.utils';

import { GlobalContext } from '../../../../Store';

import './request-dashboard.scss';

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const dateFormat = 'DD/MM/YYYY';

const { Panel } = Collapse;

const { Option } = Select;

const { RangePicker } = DatePicker;

export default function RequestDashboard(props) {
    const { user } = useContext(GlobalContext);

    const [range, setRange] = useState([
        moment().startOf('day'),
        moment().endOf('day')
    ]);

    const [option, setOption] = useState('transaction_date');

    const [currentLocation, setCurrentLocation] = useState(user.locations[0]);

    const menus = user.locations.map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
    });

    const menu = <Menu onClick={handleMenuClick}>{menus}</Menu>;

    function loadData(range) {
        setRange(range);
    }

    function updateTime(dt) {
        setRange(dt);

        loadData(dt);
    }

    function handleMenuClick(e) {
        setCurrentLocation(e.key);
    }

    return (
        <section className="request-dashboard listing">
            <div className="page-header">
                <Title level={4}>Request Status</Title>

                <div className="page-actions">
                    <Dropdown overlay={menu}>
                        <Button>
                            {currentLocation} <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>

            {/* <Tabs>

                {
                    user.locations.length === 3 ?
                        <TabPane tab="All" key="0">
                            <DailyDashboard option={option} range={range} url="all" />
                        </TabPane>
                        :
                        null
                }

                {
                    user.locations.map((location, index) => {

                        return (

                            <TabPane tab={location} key={index + 1}>
                                <DailyDashboard option={option} range={range} url={location} />
                            </TabPane>
                        )
                    })
                }
            </Tabs> */}

            {
                // currentLocation.map((location, index) => {
                // return (

                <DailyDashboard
                    option={option}
                    range={range}
                    url={currentLocation}
                />

                // )
                //})
            }
        </section>
    );
}

const departments = [
    {
        name: 'Finance',
        steps: [
            {
                dept: 'finance',
                field: 'finance.data_collected_date',
                value: 'pending',
                caption: 'Data Collection'
            },

            {
                dept: 'finance',
                field: 'finance.documents_collected_date',
                value: 'Data Collected',
                caption: 'Documents Collected'
            },

            {
                dept: 'finance',
                field: 'finance.logged_in_date',
                value: 'Documents Collected',
                caption: 'Logged In'
            },

            {
                dept: 'finance',
                field: 'finance.finance_status_date',
                value: 'Finance Approval Pending',
                caption: 'Finance Approved/Rejected'
            },

            {
                dept: 'finance',
                field: 'finance.do_date',
                value: 'Delivery Order Pending',
                caption: 'Delivery Order'
            },

            {
                dept: 'finance',
                field: 'finance.disbursed_date',
                value: 'Disbursement Pending',
                caption: 'Disbursed'
            }
        ]
    },
    {
        name: 'Insurance',
        steps: [
            {
                dept: 'insurance',
                field: 'insurance.issued_date',
                value: 'Ready for Issue',
                caption: 'Ready for Issue'
            },
            {
                dept: 'insurance',
                field: 'insurance.acknowledged_date',
                value: 'Payment Pending',
                caption: 'Insurance Payments'
            },
            {
                dept: 'insurance',
                field: 'insurance.paid_date',
                value: 'Payment Done',
                caption: 'Payment Done'
            }
        ]
    },
    {
        name: 'Accessories',
        steps: [
            {
                dept: 'accessories',
                field: 'accessories.data_collected_date',
                value: 'pending',
                caption: 'Data Collection'
            },

            {
                dept: 'accessories',
                field: 'accessories.fitted_date',
                value: 'Ready for Fitment',
                caption: 'Ready for Fitment'
            },

            {
                dept: 'accessories',
                field: 'accessories.invoiced_date',
                value: 'Ready for Billing',
                caption: 'For Billing'
            }
        ]
    },

    {
        name: 'RTO',
        steps: [
            {
                dept: 'rto',
                field: 'rto.issued_date',
                value: 'pending',
                caption: 'For Login'
            },

            {
                dept: 'rto',
                field: 'rto.paid_date',
                value: 'HSRP Pending',
                caption: 'HSRP Pending'
            },

            // { 
            //     dept: 'rto', 
            //     field: 'rto.tp_issued_date', 
            //     value: 'pending',
            //     caption: 'TP Issued' 
            // }
        ]
    },

    {
        name: 'Accounts',
        steps: [
            {
                dept: 'accounts',
                field: 'accounts.entered_date',
                caption: 'Road Tax',
                queries: [
                    {
                        field: 'rto.status',
                        value: 'Pending Payment'
                    }
                ],
            },
            {
                dept: 'accounts',
                field: 'accounts.settled_date',
                caption: 'Ready for Settlement',
                queries: [
                    {
                        field: 'delivery.status',
                        value: 'Delivery Note Taken'
                    },
                    {
                        field: 'accessories.status',
                        value: 'Invoice'
                    }
                ],
            },
            {
                dept: 'accounts',
                field: 'accounts.settled_date',
                caption: 'Insurance Payments',
                queries: [
                    {
                        field: 'insurance.status',
                        value: 'Payment Pending'
                    }
                ]
            },
        ]
    },
    {
        name: 'EDP',
        steps: [
            {
                dept: 'edp',
                field: 'allocation.allocated_date',
                caption: 'For Allocation',
                queries: [
                    {
                        field: 'allocation.status',
                        value: 'approved'
                    }
                ],
            },
            {
                dept: 'edp',
                field: 'billing.invoiced_date',
                caption: 'For Billing',
                queries: [
                    {
                        field: 'billing.status',
                        value: 'approved'
                    }
                ],
            },
            {
                dept: 'edp',
                field: 'delivery.delivered_note_date',
                caption: 'For Delivery',
                queries: [
                    {
                        field: 'delivery.status',
                        value: 'approved'
                    }
                ],
            },
            {
                dept: 'edp',
                field: 'delivery.delivered_note_date',
                caption: 'Punch Extended Warranty',
                queries: [
                    {
                        field: 'warranty.status',
                        value: 'Ready for Punching'
                    }
                ],
            }
        ]
    },
    {
        name: 'Delivery Coordinator',
        steps: [
            {
                dept: 'delivary',
                field: 'delivery.delivered_note_date',
                caption: 'Upcoming Delivery',
                queries: [
                    {
                        field: 'delivery.schedule',
                        value: 'Delivery Scheduled'
                    }
                ],
            },
            {
                dept: 'delivary',
                field: 'delivery.actual_delivery_date',
                caption: 'HSRP Fitment Pending',
                queries: [{
                    field: 'rto.status',
                    value: 'HSRP Fitment Pending'
                }],
            }
        ]
    }
];

function DailyDashboard({ url, option }) {
    const [pending, setPending] = useState([]);

    const [delivery, setDelivery] = useState([]);

    useEffect(() => {
        if (url === 'all') {
            getAllPending();

            getAllPendingForDelivery();
        } else {
            getPending();

            getPendingForDelivery();
        }

        return () => { };
    }, []);

    function getAllPending() {
        var billingPending = [
            {
                field: 'billing.status',
                value: 'pending'
            },
            {
                field: 'delivery.status',
                value: 'pending'
            }
        ];

        FirebaseUtils.getAllListing(billingPending, 'bookings').then(
            (result) => {
                setPending(result.all);
            }
        );
    }

    /**
     * Get Pending For Delivery
     */
    function getAllPendingForDelivery() {
        var deliverPending = [
            {
                field: 'billing.status',
                value: 'done'
            },
            {
                field: 'delivery.status',
                value: 'pending'
            }
        ];

        FirebaseUtils.getAllListing(deliverPending, 'bookings').then(
            (result) => {
                setDelivery(result.all);
            }
        );
    }

    /**
     * Get Pending Bookings
     */
    function getPending() {
        var deliverPending = [
            {
                field: 'billing.status',
                value: 'pending'
            },
            {
                field: 'delivery.status',
                value: 'pending'
            }
        ];

        FirebaseUtils.getListing(url, deliverPending, 'bookings').then(
            (result) => {
                setPending(result.bookings);
            }
        );
    }

    /**
     * Get Pending For Delivery
     */
    function getPendingForDelivery() {
        var deliveryPending = [
            {
                field: 'billing.status',
                value: 'done'
            },
            {
                field: 'delivery.status',
                value: 'pending'
            }
        ];

        FirebaseUtils.getListing(url, deliveryPending, 'bookings').then(
            (result) => {
                setDelivery(result.bookings);
            }
        );
    }

    return (
        <>
            {/* <Alert
            description="Below are the status of each requests by departments"
            type="info"
            showIcon
            closable
        /> */}
            <div className="status-container">
                {/* {['finance', 'insurance', 'accessories', 'rto', 'fasttag'].map(
                    (dept) => (
                        <RequestStatus dept={dept} url={url} />
                    )
                )}
                {['accounts', 'edp'].map((dept) => (
                    <PendingStatus dept={dept} url={url} />
                ))} */}
                {
                    departments.map((dept) => {
                        return (
                            <div className="card status-card capitalize">
                                <Title level={5}>{dept.name}</Title>
                                <div className="">
                                    {
                                        dept.steps.map((dept) => (
                                            <DoneTasks
                                                dept={dept}
                                                url={url}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>

            <div className="score">
                <div className="card dashboad-item">
                    <Badge status={'success'} />

                    <Statistic
                        className=""
                        title="Pending Bookings"
                        value={pending.length}
                    />

                    <p>
                        {`${
                            pending.filter((item) => item.requestId).length
                            } Requests created`}
                    </p>
                </div>

                <div className="card dashboad-item">
                    <Badge status={'success'} />

                    <Statistic
                        className=""
                        title="Pending for Delivery"
                        value={`${delivery.length} `}
                    />

                    <p>
                        {`${
                            delivery.filter((item) => item.requestId).length
                            } Requests created`}
                    </p>
                </div>
            </div>
        </>
    );
}

function DoneTasks({ url, dept, range }) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        if (url === 'all') {
            getAllDone();
        } else {
            getDone();
        }

        return () => { };
    }, [url]);

    /**
     * Get Done Tasks
     */
    function getDone() {
        setLoading(true);
        var queries = [
            // {
            //     field: dept.field,
            //     operator: '==',
            //     value: moment(range).valueOf()
            // },
            // {
            //     field: dept.dept + '.process',
            //     value: 'incomplete'
            // },
            // {
            //     field: 'allocation.status',
            //     value: 'Allocated'
            // },
            // {
            //     field: dept.dept + '.status',
            //     value: dept.value
            // }
        ];
        if (dept.queries) {
            dept.queries.map((item) => {
                return queries.push(item)
            })
        } else {
            queries = [
                {
                    field: dept.dept + '.process',
                    value: 'incomplete'
                },
                {
                    field: 'allocation.status',
                    value: 'Allocated'
                },
                {
                    field: dept.dept + '.status',
                    value: dept.value
                }
            ];
        }

        FirebaseUtils.getRecords(url, queries, 'requests').then((result) => {
            setList(result.requests);
            setLoading(false);
        });
    }

    /**
     * Get All
     */
    function getAllDone() {
        setLoading(true);
        var queries = [
            // {
            //     field: dept.field,
            //     operator: '==',
            //     value: moment(range).valueOf()
            // }
        ];

        FirebaseUtils.getAllRecords(queries, 'requests').then((result) => {
            setList(result.requests);
            setLoading(false);
        });
    }

    return (
        <Link to={{
            pathname: `${url}/request`,
            search: dept.value ?
                `?${dept.dept}.status=${createSlug(dept.value)}`
                :
                `?${dept.queries[0].field}=${createSlug(dept.queries[0].value)}`
        }}>
            <List>
                <List.Item>
                    <List.Item.Meta title={dept.caption} />
                    <div>
                        {loading ? <Spin indicator={antIcon} /> : list.length}
                    </div>
                </List.Item>
            </List>
        </Link>
    );
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// function RequestStatus({ url, dept }) {
//     const [list, setList] = useState({ [dept]: [] });

//     var groupBy = function (xs, key) {
//         let el = key.split('.');

//         return xs.reduce(function (rv, x) {
//             (rv[x[el[0]][el[1]]] = rv[x[el[0]][el[1]]] || []).push(x);
//             return rv;
//         }, {});
//     };

//     useEffect(() => {
//         if (url === 'all') {
//             getAllPending();
//         } else {
//             getPending();
//         }

//         return () => { };
//     }, [url]);

//     /**
//      * Get Pending Bookings
//      */
//     function getPending() {
//         var queries = [
//             {
//                 field: dept + '.process',
//                 value: 'incomplete'
//             },
//             {
//                 field: 'allocation.status',
//                 value: 'Allocated'
//             }
//         ];

//         FirebaseUtils.getRecords(url, queries, 'requests').then((result) => {
//             var status = groupBy(result.requests, dept + '.status');
//             setList(status);
//         });
//     }

//     /**
//      * Get Pending For Delivery
//      */
//     function getAllPending() {
//         var queries = [
//             {
//                 field: dept + '.process',
//                 value: 'incomplete'
//             },
//             {
//                 field: 'allocation.status',
//                 value: 'Allocated'
//             }
//         ];

//         FirebaseUtils.getAllRecords(queries, 'requests').then((result) => {
//             var status = groupBy(result.all, dept + '.status');

//             setList(status);
//         });
//     }

//     return (
//         <div className="card status-card capitalize">
//             <Title level={5}>
//                 {dept}
//                 {/* ({list[dept].length}) */}
//             </Title>

//             <Row gutter={16}>
//                 <Col span={12}>
//                     <Statistic value={21} />
//                 </Col>
//             </Row>

//             <div className="status-link-container">
//                 {Object.keys(list).map((status) => {
//                     var ele = list[status];

//                     return (
//                         <>
//                             <Link
//                                 to={{
//                                     pathname: `${url}/request`,
//                                     search: `?${dept}.status=${createSlug(
//                                         status
//                                     )}`
//                                 }}
//                             >
//                                 <div className="status-link">
//                                     {StatusThumbnail({
//                                         status,
//                                         list: list[status]
//                                     })}
//                                 </div>
//                             </Link>
//                         </>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// }

// function PendingStatus({ url, dept }) {
//     const [list, setList] = useState({ [dept]: [] });

//     var finalResult = {};

//     useEffect(() => {
//         if (url === 'all') {
//             getAllRequests();
//         } else {
//             getRequests();
//         }
//     }, [url]);

//     const deptData = {
//         edp: [
//             {
//                 queries: [
//                     {
//                         field: 'pending_at',
//                         value: 'edp'
//                     },
//                     {
//                         field: 'verified',
//                         value: 'approved'
//                     }
//                 ],
//                 model: 'customer_entries',
//                 title: 'Customer Receipts'
//             },
//             {
//                 queries: [
//                     {
//                         field: 'status',
//                         value: 'approved'
//                     }
//                 ],
//                 model: 'stock_transfers',
//                 title: 'Stock Transfers'
//             },
//             {
//                 queries: [
//                     {
//                         field: 'allocation.status',
//                         value: 'approved'
//                     }
//                 ],
//                 model: 'requests',
//                 title: 'For Allocation'
//             },
//             {
//                 queries: [
//                     {
//                         field: 'billing.status',
//                         value: 'approved'
//                     }
//                 ],
//                 model: 'requests',
//                 title: 'For Billing'
//             },
//             {
//                 queries: [
//                     {
//                         field: 'warranty.status',
//                         value: 'Ready for Punching'
//                     }
//                 ],
//                 model: 'requests',
//                 title: 'Puch Extended Warranty'
//             }
//         ],
//         accounts: [
//             // {
//             //     queries: [
//             //         {
//             //             field: 'pending_at',
//             //             value: 'accounts'
//             //         },
//             //         {
//             //             field: 'verified',
//             //             value: 'approved'
//             //         }
//             //     ],
//             //     model: 'customer_entries',
//             //     title: 'Pending for Entries'
//             // },
//             // {
//             //     queries: [
//             //         {
//             //             field: 'pending_at',
//             //             value: 'other-credits'
//             //         }
//             //     ],
//             //     model: 'bank_credits',
//             //     title: 'Other Credits'
//             // },
//             {
//                 queries: [
//                     {
//                         field: 'insurance.status',
//                         value: 'Payment Pending'
//                     }
//                 ],
//                 model: 'requests',
//                 title: 'Insurance Payment'
//             },
//             {
//                 queries: [
//                     {
//                         field: 'rto.status',
//                         value: 'Pending Payment'
//                     }
//                 ],
//                 model: 'requests',
//                 title: 'Road Tax'
//             }
//         ]
//     };

//     /**
//      * Get Requests
//      */
//     function getRequests() {
//         deptData[dept].map((item) => {
//             FirebaseUtils.getRecords(url, item.queries, item.model).then(
//                 (result) => {
//                     finalResult[item.title] = result[item.model];
//                 }
//             );
//         });

//         setTimeout(() => {
//             setList(finalResult);
//         }, 2000);
//     }

//     /**
//      * Get All Requests
//      */
//     function getAllRequests() {
//         deptData[dept].map((item) => {
//             return FirebaseUtils.getAllRecords(item.queries, item.model).then(
//                 (result) => {
//                     finalResult[item.title] = result[item.model];
//                 }
//             );
//         });
//         setTimeout(() => {
//             setList(finalResult);
//         }, 2000);
//     }

//     return (
//         <div className="card status-card capitalize">
//             <Title level={5}>{dept}</Title>
//             <div className="status-link-container">
//                 {Object.keys(list).map((status) => {
//                     return (
//                         <>
//                             <div className="status-link">
//                                 {StatusThumbnail({
//                                     status,
//                                     list: list[status]
//                                 })}
//                             </div>
//                         </>
//                     );
//                 })}
//             </div>
//         </div>
//     );
// }

/**
 * Creates a slug format text for passing along with URLs
 * @param {*} word - Any words seperated by whitespace
 */
function createSlug(word) {
    return word.replace(/\s/g, '-');
}

// function StatusThumbnail({ status, list }) {
//     return (
//         <div className="vehicle-header" style={{ fontWeight: 'bold' }}>
//             <p>
//                 {status}  <span className="">{list.length}</span>
//             </p>
//         </div>
//     );
// }
