import React, { useState, useContext } from 'react';

import { Button, message, Modal } from 'antd';

import { GlobalContext } from './../../../../Store';

import VehicleUtils from './../../../../utils/vehicles.utils';

import { Vehicles } from '../../../../models';

import './block-vehicle.scss';

import { CheckCircleOutlined } from '@ant-design/icons';

import FirebaseUtils from '../../../../utils/firebase.utils';

import StockTransferRequest from '../../../../components/vehicle-detail/stock-transfer-request';

import GatePassModal from '../../../../components/vehicle-detail/gate-pass';

/**
 * Block Vehicle component for blocking a request for vehicle
 *
 * @param {*} param0
 * @returns
 */
export default function BlockVehicle({ caption = 'Block Vehicle', vehicle, callback, city, requests }) {
    const [selected, setSelected] = useState({});

    const { user } = useContext(GlobalContext);

    const [visible, setVisible] = useState(false);

    const [loading, setLoading] = useState(false);

    const [stockTransferVisible, setStockTransferVisible] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    const [selectedRequest, setSelectedRequest] = useState([]);

    const [view, setView] = useState(false);

    const [stockTransferId, setStockTransferId] = useState()

    /**
     *
     * Block the vehicle
     */
    function blockVehicle(values) {

        setBtnLoading(true)

        setLoading(true);

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        // Update the request status
        // If vehicle and request are from different locations, create stock transfer request
        updateRequestStatus(selected, values, batch).then((response) => {

            if (response && response.stockTransferId)
                setStockTransferId(response.stockTransferId)
            // Update the pricing in the cart
            addPricing(selected, batch).then(async () => {

                await batch.commit();

                message.info('Blocked Successfully');

                setVisible(false);

                setLoading(false);

                setBtnLoading(false)

                setStockTransferVisible(false)
               
                callback();
            });
        });
    }

    /**
     * Function Updates the status of the request
     * and binds the vehicle and the request
     *
     * @param {*} selected
     */
    function updateRequestStatus(selected, values, batch) {

        var request = requests.filter((item) => item.id === selected).pop();

        return Vehicles.blockVehicle(request, vehicle, values, batch);
    }

    /**
     * Add the pricing for selected vehicle to cart
     *
     * @param {*} selected
     * @returns
     */
    function addPricing(selected, batch) {

        var request = requests.filter((item) => item.id === selected).pop();

        return VehicleUtils.addPricing(request, vehicle, user, batch);
    }

    /**
     *
     */
    function submitRequest() {

        var request = requests.filter((item) => item.id === selected)

        setSelectedRequest(request[0])

        setVisible(false)
        // If vehicle and request are from different locations, create stock transfer request
        if (request[0].dealerCode !== vehicle.dealerCode)
            setStockTransferVisible(true)
        else
            blockVehicle()
    }

    /**
     *
     */
    function closeModal() {
        setVisible(false);
    }

    return (
        <div className="block-vehicle">
            <Button
                size="small"
                type="secondary"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setVisible(true);
                }}
            >
                {caption}
            </Button>

            <Modal
                title="Allocation Requests"
                visible={visible}
                // onOk={submitRequest}
                onCancel={closeModal}
                footer={[
                    <Button key="back" onClick={closeModal}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={submitRequest}>
                        Submit
                    </Button>,
                ]}
            >
                <p>Select a request from below to block this vehicle for.</p>
   

                {/* && (['Verification Pending', 'Request Created'].indexOf(requests[index].status) === -1)) */}

                {requests
                    .filter((request) => {
                        return request.city === user.locations[0] && !request.allocation.vinNo && request.bookingNo && request.status != 'removed';
                    })
                    .map((request, index) => {
                        return (
                            <div
                                key={index}
                                className={`list-item ${selected === request ? 'active' : ''}`}
                                onClick={() => {
                                    setSelected(request.id);
                                }}
                            >
                                <h4>{request.customer.customerName}</h4>

                                {selected === request.id ? <CheckCircleOutlined /> : null}
                            </div>
                        );
                    })}
            </Modal>
            {/** Stock transfer modal begins */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Stock Transfer Request"
                visible={stockTransferVisible}
                okText="Okay"
                onOk={() => {
                    setStockTransferVisible(false);
                }}
                onCancel={() => {
                    setStockTransferVisible(false);
                }}
            >
                <StockTransferRequest
                    btnloading={btnloading}
                    vehicle={vehicle}
                    from={vehicle.dealerCode}
                    to={selectedRequest && selectedRequest.dealerCode ? selectedRequest.dealerCode : null}
                    required={true}
                    Callback={(values) => {
                        blockVehicle(values);
                    }}
                />
            </Modal>
            {/** Stock transfer modal ends */}
            {/** Gate pass modal begins */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Gate pass"
                visible={view}
                width={'60%'}
                okText="Okay"
                onOk={() => {
                    callback()
                    setView(false);
                }}
                onCancel={() => {
                    callback()
                    setView(false);

                }}
            >
                <GatePassModal params={vehicle.id} vehicle={vehicle} stockTransferId={stockTransferId} />
            </Modal>
            {/** Gate pass modal ends */}
        </div>
    );
}
