import React from "react";

import moment from 'moment';

import { capitalizeStrings, getFormattedDateAndTime } from './../../utils/common.utils';

import { Link } from 'react-router-dom';

import { Tag, Badge } from 'antd';



function AllocationCard({ vehicle, index, step }) {

    let waitingTime = step.field_time.split('.').reduce((o, i) => o[i], vehicle);

    let waitinghours = moment().diff(moment(waitingTime));

    return (
        <Link className="report-item" to={`${step.redirect_to}/${vehicle.city}/${index}`}>
            <div className="card">
                <h2 className="title amount">
                    {vehicle.bookingNo}
                </h2>

                {/* {vehicle.finance && vehicle.finance.documentStatus ? <Tag color="orange">{vehicle.finance && vehicle.finance.documentStatus}</Tag> : null} */}


                {/* {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                } */}


                <h3 className="title">
                    {vehicle.customer.customerName}
                </h3>

                <h2 className="title">
                    {vehicle.customer.phone}

                </h2>


                <h3 className="title">
                    {vehicle['variant']} - {vehicle['color']}

                </h3>


                <p className="address">
                    KEC on {vehicle.kec}
                </p>

                {
                    waitingTime && waitingTime.toString().length > 0 ?
                        <p className="date-time" style={{ display: "flex" }}>
                            Waiting since {moment(waitingTime).format('hh a dddd - DD/MM/YYYY')} (<div style={{ color: 'red' }}>{getFormattedDateAndTime(waitinghours)}</div>)
                        </p>
                        :
                        null
                }

                {/* <Tag color="magenta">{vehicle.status}</Tag> */}

                {vehicle.finance && vehicle.finance.documentStatus ?
                    <Badge status="processing" text={vehicle.finance.documentStatus} />
                    :
                    null
                }



            </div>
        </Link>
    )
}

/**
 * Custom Actions for Editing Request
 */
export const rowActions = [{
    caption: 'Edit',
    url: (record) => {
        return `/accessories/${record.city}/${record.id}`
    }
}, {
    caption: 'View',
    url: (record) => {
        return `/requests/${record.id}`
    }
}]

/**
 * Custom Actions for Editing Request
 */
export const billActions = [{
    caption: 'View',
    url: (record) => {
        // if (record.type === 'basic') {
        //     return `/bill/vehicle/${record.id}`
        // } else {
        return `/bill/${record.id}`
        // }
    }
}]


/**
 * Custom Actions for Editing Request
 */
export const packageActions = [{
    caption: 'Edit',
    url: (record) => {
        return `/package/${record.id}`
    }
}]




/**
 * Columns for Request Table
 */
export const requestColumns = [

    {
        type: 'input',
        caption: 'Customer',
        field: 'customer.customerName'
    },
    {
        type: 'input',
        caption: 'Part Name',
        field: 'customer.phone',
    },
    {
        type: 'tag',
        caption: 'Status',
        field: 'accessories.status',
    },

    {
        type: 'input',
        caption: 'KEC',
        field: 'kec',
    },
    {
        field: 'variant',
        type: 'input',
        caption: 'Variant'
    },

    {
        caption: 'Created At',
        field: 'created_at',
        type: 'input',
    },

    {
        caption: 'Booking No',
        field: 'bookingNo',
        type: 'input',
    },
]




/**
 * Steps for Accessories
 */
export const accessoriesSteps = [

    // Before Retails
    {
        caption: 'Data Collection',
        path: '/steps/data-collection',
        status: 'pending',
        redirect_to: 'collect-data',
        queries: [{
            field: 'accessories.process',
            value: 'incomplete'
        },
        {
            field: 'accessories.status',
            value: 'pending'
        },
        {
            field: 'allocation.status',
            operator: 'in',
            value: ['approved', 'Allocated']
        },   
        {
            field: 'status',
            operator: '!=',
            value:'removed'
        },

            // {
            //     field: 'allocation.status',
            //     value: 'Allocated'
            // },
        ],
        field_time: "allocation.created_time",
        Card: AllocationCard,
    },
    // Before Retail Ends

    // After Retail
    {
        caption: 'Ready for Fitment',
        path: '/steps/fitment',
        status: 'Ready for Fitment',
        redirect_to: 'accessories-fitment',
        queries: [{
            field: 'accessories.process',
            value: 'incomplete'
        },
        {
            field: 'accessories.status',
            value: 'Ready for Fitment'
        },
        {
            field: 'status',
            operator: '!=',
            value:'removed'
        }],
        field_time: "billing.invoiced_time",
        Card: AllocationCard,

    },

    {
        caption: 'For Billing',
        path: '/steps/paymentDone',
        status: 'Payment Done',
        redirect_to: 'for-billing',
        queries: [{
            field: 'accessories.process',
            value: 'incomplete'
        },
        {
            field: 'accessories.status',
            value: 'Ready for Billing'
        }, 
        {
            field: 'status',
            operator: '!=',
            value:'removed'
        }],
        field_time: "delivery.delivered_note_time",
        Card: AllocationCard,

    },
    // After Retail Ends


    // Issue insurance




    // Data Collection Form
    {
        isForm: true,
        caption: 'Data Collection',
        path: '/steps/collect-data/:city/:id',
        post_status: 'Data Collected',
        fields: [

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'remarks'
            },
        ],
        notification: (request, user) => {

            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Accessories Data Collected',
                body: `Accessories Data has been collected for ${cName} - ${request.allocation.vinNo} ${request.variant}.`
            }
        },
        extraParams: (request, params) => {

            let creation = {
                data_collected_at: moment().format('DD/MM/YYYY HH:mm'),
                data_collected_date: moment().startOf('day').valueOf(),
                data_collected_time: moment().valueOf()
            }

            return {
                accessories: {
                    ...creation,
                    ...params,
                },
            }

        },
    },



    // For Fitment
    {
        isForm: true,
        caption: 'Ready for Fitment',
        path: '/steps/accessories-fitment/:city/:id',
        post_status: 'Accessories Fitted',
        fields: [

            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'fitted_remarks'
            },
        ],
        notification: (request, user) => {

            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Accessories Fitment Complete',
                body: `Accessories Fitment has been completed for ${cName} - ${request.allocation.vinNo} ${request.variant}.`
            }
        },
        extraParams: (request, params) => {

            let creation = {
                fitted_at: moment().format('DD/MM/YYYY HH:mm'),
                fitted_date: moment().startOf('day').valueOf(),
                fitted_time: moment().valueOf()
            }

            return {
                accessories: {
                    ...creation,
                    ...params,
                },
            }

        },
    },


    {
        isForm: true,
        caption: 'Share Invoice',
        post_status: 'Invoice',
        path: '/steps/for-billing/:city/:id',
        fields: [

            {
                type: 'radio',
                options: ['Yes', 'No'],
                caption: 'Accessories/VAS Present?',
                field: 'isPresent'
            },

            {
                condition: (values) => {
                    return values.isPresent === 'Yes'
                },
                type: 'upload',
                field: 'invoice',
                caption: 'Invoice '
            },
            {
                type: 'textarea',
                caption: 'Remarks',
                field: 'invoiced_remarks'
            },
        ],
        notification: (request, user) => {

            let cName = capitalizeStrings(request.customer.customerName);

            return {
                title: cName + ' | Accessories Billing Complete',
                body: `Accessories Billing has been completed for ${cName} - ${request.allocation.vinNo} ${request.variant}.`
            }
        },
        extraParams: (request, accessoriesParams) => {

            let creation = {
                invoiced_at: moment().format('DD/MM/YYYY HH:mm'),
                invoiced_date: moment().startOf('day').valueOf(),
                invoiced_time: moment().valueOf()
            }

            return {
                accessories: {
                    ...accessoriesParams,
                    process: 'complete',
                    ...creation

                }
            }
        },
    }
]