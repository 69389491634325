import React, { useEffect, useState, useRef, useContext } from 'react';

import { Link } from 'react-router-dom';

import SELECT from 'react-select';

import {
    Tag,
    Tabs,
    Badge,
    Button,
    Row,
    List,
    Form,
    Select,
    Menu,
    Steps,
    Card,
    Alert,
    message,
    Input,
    Popconfirm,
    Table,
    Dropdown,
    Space,
    Modal,
    Upload,
} from 'antd';

import Documents from '../documents/documents';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import { GlobalContext } from '../../../../Store';

import FirebaseUtils from '../../../../utils/firebase.utils';

import CommentBlock from '../comment-block/comment-block';

import AllBills from '../../resources/all-bills/all-bills';

import RequestTimeline from './../../../../components/request-timeline/request-timeline';

import ImageWrapper from './../../../../components/image-wrapper/image-wrapper';

import Summary from './../../../../components/summary/summary';

import DeliveryNote from '../delivery-note/delivery-note';

import moment from 'moment';

import { CheckCircleOutlined, UserOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { Bills, Settings, StockTransfers, Users, Vehicles } from './../../../../models/';

import VehicleUtils from './../../../../utils/vehicles.utils';

import './request-detail.scss';

import { Cart, Employees, Requests, DocumentLogs, Designations } from '../../../../models/';

import { Typography } from 'antd';

import VehicleInvoice from '../../../../templates/vehicle-invoice/vehicle-invoice';

import InvoicePreview from '../../../../templates/invoice-preview/invoice-preview';
import StockTransferDetails from '../../resources/stock-transfer-details/stock-transfer-details';

import Bill from '../../../../models/bills';

import { BookingStatusModal } from '../../../accounts/components/booking-status-modal/booking-status-modal';
import { dealerKeys } from '../../../global-config';

const { Title } = Typography;

const { Option } = Select;

var badgeColor = {
    Allocated: 'error',
    'In transit': 'warning',
    'Free Stock': 'success',
};

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

const colorMaster = {
    SONET: [
        'CLEAR WHITE',
        'GLACIER WHITE PEARL',
        'STEEL SILVER',
        'GRAVITY GREY',
        'AURORA BLACK PEARL',
        'INTENSE RED',
        'INTELLIGENCY BLUE',
        'BEIGE GOLD',
        'MATTE GRAPHITE',
        'PEWTER OLIVE',
        'IMPERIAL BLUE'
    ],
    SELTOS: [
        'CLEAR WHITE',
        'GLACIER WHITE PEARL',
        'STEEL SILVER',
        'GRAVITY GREY',
        'AURORA BLACK PEARL',
        'INTENSE RED',
        'INTELLIGENCY BLUE',
        'PUNCHY ORANGE',
        'MATTE GRAPHITE',
        'PEWTER OLIVE',
        'IMPERIAL BLUE'
    ],
    CARNIVAL: [
        'CLEAR WHITE',
        'GLACIER WHITE PEARL',
        'STEEL SILVER',
        'GRAVITY GREY',
        'AURORA BLACK PEARL',
        'INTENSE RED',
        'INTELLIGENCY BLUE',
        'PUNCHY ORANGE',
        'MATTE GRAPHITE',
        'PEWTER OLIVE',
        'IMPERIAL BLUE'
    ],
    CARENS: [
        'CLEAR WHITE',
        'GLACIER WHITE PEARL',
        'STEEL SILVER',
        'GRAVITY GREY',
        'AURORA BLACK PEARL',
        'INTENSE RED',
        'INTELLIGENCY BLUE',
        'PUNCHY ORANGE',
        'MATTE GRAPHITE',
        'PEWTER OLIVE',
        'IMPERIAL BLUE'
    ],
    EV6: [],
};

var cityName = dealerKeys;

const { TabPane } = Tabs;

const { Step } = Steps;

const { TextArea } = Input;

const { Text } = Typography;

let options = {
    page: 1,
    current: 1,
    pageSize: 20,
};

export default function RequestDetail(props) {
    const { user } = useContext(GlobalContext);

    const [userRequest, setuserRequest] = useState({ comments: [] });

    const [booking, setBooking] = useState({ proofs: {} });

    const [transfer, setTransfer] = useState({});

    const [pricing, setPricing] = useState({});

    const [edit, setEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    const [btnloading, setBtnLoading] = useState(false);

    const [kecInitial, setKecInitial] = useState({});

    const [selected, setSelected] = useState([]);

    const [kecvisible, setKecVisible] = useState(false);

    const [kec, setKec] = useState([]);

    const [enableMenuItem, setEnableMenuItem] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [findKec, setFindKec] = useState([]);

    const [form] = Form.useForm();

    const [variants, setVariants] = useState([]);

    const [colors, setColors] = useState([]);

    const [master, setMaster] = useState({
        carnival: { lastUpdated: null },
        seltos: { lastUpdated: null },
        sonet: { lastUpdated: null },
    });

    // Primary Variables Ends

    const [breakup, setBreakup] = useState({});

    var city, id;

    if (props.id) {
        id = props.id;

        city = props.city;
    } else {
        const { match } = props;

        const { params } = match;

        // const { id, city } = params;

        id = params.id;

        city = params.city;
    }

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [props.id]);

    /**
     *
     */
    function loadData(resolve) {
        setLoader(true);

        FirebaseUtils.getRequest(id).then((result) => {
            if (result) {
                var request = result;

                // console.log(request);

                setuserRequest({
                    ...{ finance: {}, accounts: {} },
                    ...request,
                });

                setSelected(request.customer);

                //Get details of the current kec and teamleader

                getEmployee(request);

                getMaster(request);

                // Booking is identified, Get booking

                if (request.bookingNo) {
                    var queries = [
                        {
                            field: 'bookingNo',
                            value: request.bookingNo,
                        },
                        {
                            field: 'dealerCode',
                            value: request.dealerCode,
                        },
                    ];

                    Cart.get(queries).then((result) => {
                        if (['admin', 'edp'].indexOf(user.role) !== -1) {
                            setEnableMenuItem(true);
                        } else {
                            if (result.cart) {
                                setEnableMenuItem(false);
                            } else {
                                setEnableMenuItem(true);
                            }
                        }

                        getBooking(request.bookingNo);
                    });
                    // if (resolve) {
                    //     resolve(request);
                    // }
                } else {
                    setLoader(false);
                }
            } else {
                message.error('Failed to load the request');

                props.history.goBack();

                setLoader(true);
            }
        });
    }

    /***
     *
     * function to get current kec details from employee table
     * Here all kecs are loaded from employees and given in select for kec change
     */
    async function getEmployee(value) {
        var queries = [
            {
                field: 'branch',
                value: cityName[value.dealerCode],
            },
        ];

        return await Employees.get(queries).then((result) => {
            console.log(result);

            var query = [
                {
                    field: 'designation',
                    value: 'KEC',
                },
            ];

            return Designations.get(query).then((res) => {
                var kecList = result.employees.filter((ele) => ele.designation === res.designations[0].id);

                setFindKec(kecList);
                //Preparing data for react-select
                let b = Object.keys(kecList).map((employee) => {
                    return { label: kecList[employee].fname + ' ' + kecList[employee].lname, value: kecList[employee].id };
                });

                setKec(b);

                //Get the current kec from the consultant_id present in booking
                //the consultant_id is matched in employees to get the current cinsultant details
                var k = result.employees.find((ele) => ele.id === value.consultant_id);

                setKecInitial({ current_kec: k.fname + ' ' + k.lname }); //Setting initial values in the form for kec change

                return result.employees;
            });
        });
    }

    function getMaster(request) {
        FirebaseUtils.getMaster().then((result) => {
            let data = {};

            Object.keys(result.master).forEach((model) => {
                let vehicles = result.master[model];

                data[model] = Object.keys(vehicles).map((v) => {
                    return vehicles[v];
                });
            });

            setMaster({ ...data });

            if (request.model) {
                let model = request.model;
                setVariants(data[model]);

                setColors(colorMaster[model]);
            }
        });
    }

    function getBooking(id) {
        FirebaseUtils.getBooking(id).then((result) => {
            var booking = result.data();

            // console.log(booking);

            if (booking) {
                setBooking({ ...booking, comments: [], isValid: true });
            } else {
                message.error('Failed to load the booking');

                setBooking({ empty: true, isValid: false });
            }

            setLoader(false);

            // #todo the booking object have to be removed from
        });
    }

    function deleteRequest() {
        FirebaseUtils.removeRequest(userRequest.bookingNo).then(() => {
            FirebaseUtils.deleteRequest(userRequest.id).then(() => {
                FirebaseUtils.unblockVehicle(
                    // city,
                    userRequest.allocation.vinNo
                ).then(() => {
                    message.success('Request has been deleted');
                });
            });
        });
    }

    function handleMenuClick(index) {
        if (index.key === '1') {
            markHighPriority();
        } else if (index.key === '2') {
            addBasicPricing();
        }
    }

    function addBasicPricing(request) {
        let arr = request ? [request] : [userRequest];
        return Promise.all(arr.map((item) => FirebaseUtils.addBasicPrice(item))).then((entry) => {
            if (entry && entry[0]) {
                if (entry[0].status == 'success') {
                    message.success('Added basic pricing');
                    loadData();
                } else {
                    message.error(entry[0].error);
                }
            }
        });
    }

    function markHighPriority() {
        FirebaseUtils.updateRequestBody(userRequest.id, {
            isHighPriority: true,
            highPriorityStatus: 'requested',
        }).then(() => {
            message.success('Your request has been send for approval');
        });
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" icon={<UserOutlined />}>
                Make High Priority
            </Menu.Item>

            {enableMenuItem ? (
                <Menu.Item key="2" icon={<UserOutlined />}>
                    Add Basic Pricing
                </Menu.Item>
            ) : null}
            {/* <Menu.Item key="3" icon={<UserOutlined />}>
                3rd menu item
          </Menu.Item> */}
        </Menu>
    );

    /**
     * On edit model close
     */
    function closeModal() {
        setEdit(false);
    }

    /**
     * On edit model submit
     */
    function submitRequest() {
        setLoading(true);
        form.validateFields()
            .then((values) => {
                let placeObj;
                if (values.place) {
                    placeObj = values.place;
                } else if (userRequest.customer && userRequest.customer.place) {
                    placeObj = userRequest.customer.place;
                } else {
                    placeObj = '';
                }
                // Booking's color and varient need to update
                let booking = []

                let userContent = [
                    {
                        customer: {
                            ...userRequest.customer,
                            customerName: values.customerName,
                            place: placeObj,
                        },
                    },
                ];

                if (values.invoice_no) {
                    userContent.push({
                        billing: {
                            ...userRequest.billing,
                            invoice_no: values.invoice_no,
                        },
                    });
                }

                if (values.color) {
                    userContent.push({
                        color: values.color,
                    });
                    booking.push({
                        Color: values.color,
                    });
                }

                if (values.variant) {
                    userContent.push({
                        variant: values.variant,
                    });
                    booking.push({
                        Variant: values.variant,
                    });
                }

                Promise.all(
                    userContent.map((item) =>
                        FirebaseUtils.updateRequestBody(
                            // userRequest.city,
                            userRequest.id,
                            item
                        )
                    ),
                    booking.map((item) =>
                        FirebaseUtils.updateRecord(
                            'bookings', userRequest.bookingNo, item
                        )
                    )
                ).then(() => {
                    message.info('Details edited successfully');
                    setEdit(false);
                    setLoading(false);
                    loadData();
                });
            })
            .catch((info) => {
                // console.log('Validate Failed:', info);
                setEdit(false);
                setLoading(false);
            });
    }

    function assignKec() {
        setKecVisible(true);
        setBtnLoading(true);
    }

    /**
     * Update the Stock Trasnfer
     */
    function updateStockTransfer(transfer) {
        setTransfer(transfer);
    }

    /**
     * function to change kec
     * Here the newly selected kec's consultnat_id and teamleader_id are updated in bookinhgs, requests and enquiries
     * @param {*} value
     */
    async function changeKec(value) {
        setKecVisible(false);

        setBtnLoading(true);

        var selectChoice = value.selected_kec;

        var selectedkec = findKec.find((ele) => ele.id === selectChoice.value);

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        var booking = {};

        Employees.changeKec(selectedkec, booking, user, userRequest, batch).then(async (result) => {
            await batch.commit();

            message.success(`KEC and Teamleader for ${userRequest.id} is updated successfully`);

            setBtnLoading(false);

            loadData();
        });
    }

    const prop = {
        name: 'file',

        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <div className="request-detail listing">
            {!props.hideInfo ? (
                <div className="page-header">
                    <Title level={3}>
                        Allocation Request{' '}
                        {userRequest.isHighPriority && userRequest.highPriorityStatus === 'approved' ? <Tag color="red">High Priority</Tag> : null}
                    </Title>

                    <div>
                        {['super'].indexOf(user.role) !== -1 ? (
                            <Button onClick={deleteRequest} type="secondary" size={'small'}>
                                Delete
                            </Button>
                        ) : null}

                        {/* <Button onClick={loadData} type="secondary" size={'small'}>Refresh</Button> */}

                        {['sm', 'gm'].indexOf(user.role) !== -1 &&
                            ['Delivered', 'Delivery Completed'].indexOf(userRequest.delivery && userRequest.delivery.status) == -1 ? (
                            <Button
                                onClick={() => {
                                    setEdit(true);
                                }}
                                type="secondary"
                                size={'small'}
                            >
                                Edit
                            </Button>
                        ) : null}

                        <Dropdown.Button
                            size="small"
                            onClick={() => {
                                loadData();
                            }}
                            overlay={menu}
                        >
                            Refresh
                        </Dropdown.Button>
                    </div>
                </div>
            ) : null}

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <div>
                    <div className="page-content">
                        <RequestTimeline request={userRequest} booking={booking} />

                        {user.role === 'sm' || user.role == 'admin' || user.role == 'edp' ? (
                            // {/**Only billing pending or delivery pending cases should have kec change option */}
                            (userRequest &&
                                userRequest.actual_billing &&
                                ['pending', 'requested'].indexOf(userRequest.actual_billing.status) !== -1) ||
                                (userRequest &&
                                    userRequest.actual_delivery &&
                                    [
                                        'pending',
                                        'requested',
                                        'approved',
                                        'Delivery Note Taken',
                                        'Settlement Pending',
                                        'Settlement Ready',
                                        'Delivery Pending',
                                    ].indexOf(userRequest.actual_delivery.status) !== -1) ? (
                                <Button loading={btnloading} style={{ marginTop: '10px' }} type="secondary" onClick={() => assignKec()}>
                                    Assign request to another KEC
                                </Button>
                            ) : null
                        ) : null}

                        <div className="split-container">
                            {/* {!props.hideInfo ? <div className="left">

                                    <RequestInfo booking={booking} request={userRequest} />

                                </div> : null} */}

                            <div className="right">
                                {/* HighPriority Section */}
                                <HighPriorityBlock booking={booking} callback={loadData} userRequest={userRequest} />
                                {/* HighPriority Section Ends */}

                                {/* Billing Approval */}
                                {userRequest.billing || userRequest.delivery ? (
                                    <BillingApproval
                                        userRequest={userRequest}
                                        pricing={pricing}
                                        breakup={breakup}
                                        callback={() => {
                                            loadData();
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}

                                {/* Invoice */}
                                <Invoice userRequest={userRequest} booking={booking} />
                                {/* Invoice  Ends */}

                                {/* Vehicle Allocation */}
                                <VehicleAllocation
                                    addPricing={addBasicPricing}
                                    request={userRequest}
                                    callback={loadData}
                                    updateStockTransfer={updateStockTransfer}
                                />
                                {/* Vehicle Allocation Ends */}

                                <Space></Space>

                                {!loader && userRequest.id && booking['Booking No'] ? (
                                    <Summary
                                        booking={booking}
                                        request={userRequest}
                                        updatePricing={(pricing) => {
                                            setPricing(pricing);
                                        }}
                                        refresh={() => {
                                            loadData();
                                        }}
                                        saveBreakup={setBreakup}
                                    />
                                ) : null}

                                {/* Accessory Choices */}
                                {/* <AccessoryChoices disableEdit={true} request={userRequest} /> */}
                                {/* Accessory Choices Ends */}

                                {/* Refund Status */}
                                {userRequest && userRequest.accounts && userRequest.accounts.refund ? (
                                    <RefundStatus userRequest={userRequest} />
                                ) : null}
                                {/* Refund Status Ends */}

                                {/*All Created Bills*/}
                                <AllBills userRequest={userRequest} />

                                {/* Finance Status */}
                                <FinanceStatus userRequest={userRequest} />
                                {/* Finance Status Ends */}

                                {/* Insurance Status */}
                                <InsuranceStatus userRequest={userRequest} booking={booking} user={user} callback={loadData} />
                                {/* Insurance Status Ends */}

                                {/* Accessories Status */}
                                <AccessoriesStatus userRequest={userRequest} booking={booking} />
                                {/* Accessories Status Ends */}

                                {/* Fast Tag */}
                                <FastTag userRequest={userRequest} />
                                {/* Fast Tag  Ends */}

                                {/* Fast Tag */}
                                <RTOStatus userRequest={userRequest} />
                                {/* Fast Tag  Ends */}

                                {/* In transit */}
                                <PDIDocuments userRequest={userRequest} transfer={transfer} />
                                {/* In transit  Ends */}

                                <CustomerDocuments booking={booking} />

                                <DeliveryNote userRequest={userRequest} />

                                <Warranty userRequest={userRequest} />

                                {/*Added documents */}
                                <Documents userRequest={userRequest} booking={booking} />
                                {/*Added documents Ends */}
                                {/* Matching Vehicles */}
                                {!userRequest.allocation.vinNo && ['sm', 'admin', 'tl', 'rm'].indexOf(user.role) !== -1 ? (
                                    <MatchingVehicles userRequest={userRequest} callback={loadData} />
                                ) : null}
                                {/* Matching Vehicles Ends */}

                                {/* Comment Section */}
                                <CommentSection userRequest={userRequest} />
                                {/* Comment Section Ends */}
                            </div>
                        </div>
                    </div>

                    {/*Kec change modal begins */}

                    <Modal
                        visible={kecvisible}
                        footer={null}
                        className=""
                        onCancel={() => {
                            setBtnLoading(false);
                            setKecVisible(false);
                        }}
                        destroyOnClose={true}
                    >
                        <Form layout="vertical" onFinish={changeKec} initialValues={kecInitial}>
                            <p>Are you sure you want to proceed?</p>
                            <Form.Item name={'current_kec'} label="Current KEC">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={'selected_kec'}
                                label="Selected KEC"
                                rules={[
                                    {
                                        message: 'Please Select the KEC',
                                        required: true,
                                    },
                                ]}
                            >
                                {/* <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select"

                                >
                                    {kec.map((kec) => {
                                        return (
                                            <Option value={kec.id} key={kec.dms_name}>
                                                {kec.fname} - {kec.branch}
                                            </Option>
                                            // <Option value={kec.userId} key={kec.email}>
                                            //     {kec.name} - {kec.locations[0]}
                                            // </Option>
                                        );
                                    })}
                                </Select> */}
                                <SELECT style={{ width: '100%' }} options={kec} isClearable={true} />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    {/*Kec change modal ends */}

                    <Modal confirmLoading={loading} title="Edit Details" visible={edit} onOk={submitRequest} onCancel={closeModal}>
                        <Form
                            form={form}
                            {...layout}
                            name="new-record"
                            layout="vertical"
                            // onFinish={onSubmit}
                            // validateMessages={validateMessages}
                            onFieldsChange={(fields) => {
                                let field = fields[0];
                                if (field && field.value) {
                                    if (field.name[0] === 'model') {
                                        let model = field.value.toLowerCase();
                                        setVariants(master[model]);
                                        setColors(colorMaster[model]);
                                    }
                                }
                            }}
                            initialValues={{
                                ...selected,
                            }}
                        >
                            <Form.Item
                                name={'customerName'}
                                label="Customer Name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Customer Name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item name={'place'} label="Place" rules={[{ message: 'Enter Place' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'invoice_no'} label="Invoice No" rules={[{ message: 'Enter Number' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={'model'} label="Model" rules={[{ message: 'Please select model' }]}>
                                <Select style={{ width: '100%' }} defaultValue={userRequest.model} placeholder="Please select" disabled>
                                    {['SELTOS', 'SONET', 'CARNIVAL'].map((model, key) => {
                                        return (
                                            <Option value={model} key={key}>
                                                {model}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name={'variant'} label="Variant" rules={[{ message: 'Please select variant' }]}>
                                <Select
                                    allowClear
                                    style={{ width: '200%' }}
                                    placeholder="Please select"
                                    // defaultValue={['a10', 'c12']}
                                    onChange={() => { }}
                                >
                                    {variants.map((variant, key) => {
                                        return (
                                            <Option value={`${variant['variant']}`} key={key}>
                                                {variant['variant']} - {variant['extColor']}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name={'color'} label="Color" rules={[{ message: 'Please select color' }]}>
                                <Select
                                    allowClear
                                    style={{ width: '200%' }}
                                    placeholder="Please select"
                                    // defaultValue={['a10', 'c12']}
                                    onChange={() => { }}
                                >
                                    {colors.map((color, key) => {
                                        return (
                                            <Option value={`${color}`} key={key}>
                                                {color}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            )}
        </div>
    );
}

function Invoice({ userRequest, booking }) {
    const { billing } = userRequest;

    const [billId, setBillID] = useState();

    const { invoice = [] } = billing;

    let queries = [
        {
            field: 'bookingNo',
            value: userRequest.bookingNo,
        },
        {
            field: 'type',
            value: 'basic',
        },
        {
            field: 'cancellation_status',
            value: false,
        },
    ];

    Bills.get(queries).then((result) => {
        let bill = result.bills[0];
        if (bill) {
            setBillID(bill.id);
        } else {
            setBillID(false);
        }
    });

    return (
        <>
            <Tabs>
                {userRequest.actual_billing && userRequest.actual_billing.status === 'Invoiced' && billId ? (
                    <TabPane key={0} tab={'Vehicle Invoice'}>
                        <VehicleInvoice id={billId} printable={true} booking={booking} request={userRequest} />
                    </TabPane>
                ) : null}

                {billing && billing.invoice && billing.invoice ? (
                    <TabPane className="vehicle-card" key={1} tab={'DMS Invoice'}>
                        <Alert
                            message="Info"
                            description={`Please be informed that this invoice is only kept for reference and not allowed to be shared with the customer `}
                            type="warning"
                        />

                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>DMS Invoice</span>
                                </Row>
                                <Row span={12}>
                                    <ImageWrapper type={Array.isArray(billing.invoice) ? 'array' : 'object'} content={billing.invoice} />
                                </Row>
                            </div>
                        </div>
                    </TabPane>
                ) : null}
            </Tabs>
        </>
    );
}

function FastTag({ userRequest }) {
    const { fasttag } = userRequest;

    return (
        <>
            {fasttag && fasttag.attachment && fasttag.attachment ? (
                <div className="vehicle-card">
                    <Title level={3}>Fast Tag</Title>

                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Issued
                    </Tag>

                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <span>Fast Tag</span>
                            </Row>
                            <Row span={12}>
                                <ImageWrapper type={Array.isArray(fasttag.attachment) ? 'array' : 'object'} content={fasttag.attachment} />

                                {/* <h2 className="title">
                                    {
                                        !(fasttag.attachment.type.indexOf('image') !== -1)
                                            ?
                                            <embed src={fasttag.attachment.url} width="500" height="375"
                                                type="application/pdf" />
                                            : <Image width={200} src={fasttag.attachment.url} />
                                    }
                                </h2> */}
                            </Row>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

function AccessoriesStatus({ userRequest }) {
    const { accessories } = userRequest;

    return (
        <>
            {accessories && accessories.invoice && accessories.invoice ? (
                <div className="vehicle-card">
                    <Title level={3}>Accessories</Title>

                    <Tag icon={<CheckCircleOutlined />} color="success">
                        {accessories.status}
                    </Tag>

                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <span>Invoices</span>
                            </Row>
                            <Row span={12}>
                                <ImageWrapper type={Array.isArray(accessories.invoice) ? 'array' : 'object'} content={accessories.invoice} />
                            </Row>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}

function RTOStatus({ userRequest }) {
    const { rto } = userRequest;

    let content = [];

    ['disclaimer', 'form20', 'form21', 'form22', 'invoice', 'e_receipt', 'attachment', 'tax_license'].map((index) => {
        if (rto && rto[index]) {
            if (Array.isArray(rto[index])) {
                content.push({ files: rto[index][0], caption: index });
            } else {
                content.push({ files: rto[index], caption: index });
            }
        }
    });

    return (
        <>
            {rto && rto.status != 'pending' ? (
                <div className="vehicle-card">
                    {/* <Tag icon={<CheckCircleOutlined />} color="success">
                        Issued
                    </Tag> */}

                    <Title level={3}>RTO Status</Title>

                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <span>Status</span>
                            </Row>
                            <Row span={12}>
                                <h3 className="title">{userRequest.rto.status}</h3>
                            </Row>
                        </div>
                    </div>

                    <Tabs>
                        {content.map((entry, index) => (
                            <TabPane key={index} tab={entry.caption}>
                                <ImageWrapper type={'object'} content={entry.files} />
                            </TabPane>
                        ))}
                    </Tabs>

                    {/* <ImageWrapper type={'array'} content={content} /> */}
                    {/* 

                    {
                        Object.keys(rto).filter((ele) => rto[ele].url).map((entry) => {

                            return (<>


                                <div gutter={0} className="detail-wrapper">

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>
                                                {entry}
                                            </span>
                                        </Row>
                                        <Row span={12}>


                                            {
                                                (rto[entry].type.indexOf('image') === -1)
                                                    ?
                                                    <embed src={rto[entry].url} width="500" height="375"
                                                        type="application/pdf" />
                                                    :
                                                    <Image width={200} src={rto[entry].url} />
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </>)

                        })
                    } */}
                </div>
            ) : null}
        </>
    );
}

function PDIDocuments({ userRequest, transfer }) {
    let { city, bookingNo } = userRequest;

    const [vehicle, setVehicle] = useState({});

    useEffect(() => {
        getVehicle();
    }, []);

    useEffect(() => {
        if (transfer.from) {
            getDocuments(transfer);
        }
    }, [transfer]);

    /**
     *
     * @param {*} transfer
     */
    function getDocuments(transfer) {
        var queries = [
            {
                field: 'allocated.requestId',
                value: transfer.requestId,
            },
        ];

        FirebaseUtils.getVehicles(transfer.from, queries).then((result) => {
            if (result.vehicles.length) {
                let vehicle = result.vehicles[0];

                // console.log(vehicle);

                setVehicle(vehicle);
            } else {
            }
        });
    }

    function getVehicle() {
        var queries = [
            {
                field: 'allocated.requestId',
                value: userRequest.id,
            },
        ];

        FirebaseUtils.getVehicles(city, queries).then((result) => {
            if (result.vehicles.length) {
                let vehicle = result.vehicles[0];

                // console.log(vehicle);

                setVehicle(vehicle);
            } else {
            }
        });
    }

    let content = [];

    ['form_22', 'stencil_print'].map((index) => {
        if (vehicle && vehicle['pdi'] && vehicle['pdi'][index]) {
            const { pdi = {} } = vehicle;

            //
            if (Array.isArray(pdi[index])) {
                content.push({ files: [].concat(pdi[index]), caption: index });
            } else {
                content.push({ files: pdi, caption: index });
            }
        }
    });

    return (
        <>
            {vehicle && vehicle.pdi ? (
                <div className="vehicle-card">
                    <Title level={3}>PDI Documnets</Title>

                    <Tabs>
                        {content.map((entry, index) => (
                            <TabPane key={index} tab={entry.caption}>
                                <ImageWrapper type={Array.isArray(entry.files) ? 'array' : 'object'} content={entry.files} />
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            ) : null}
        </>
    );
}

function CustomerDocuments({ booking }) {
    const { proof } = booking;

    let content = [];

    ['Aadhar', 'Customer Photo', 'Documents'].map((index) => {
        if (proof && proof[index]) {
            if (Array.isArray(proof[index])) {
                content.push({ files: proof[index], caption: index });
            } else {
                content.push({ files: proof[index], caption: index });
            }
        }
    });

    return (
        <>
            {proof ? (
                <div className="vehicle-card">
                    {/* <Tag icon={<CheckCircleOutlined />} color="success">
                        Issued
                    </Tag> */}

                    <Title level={3}>Customer Proof</Title>

                    <Tabs>
                        {content.map((entry, index) => (
                            <TabPane key={index} tab={entry.caption}>
                                <ImageWrapper type={Array.isArray(entry.files) ? 'array' : 'object'} content={entry.files} />
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            ) : null}
        </>
    );
}

/**
 *
 * Component should show the current status of the request.
 * SM can update the status according to predefined conditions
 *
 *
 * @param {*} param0
 */

function BillingApproval({ userRequest, pricing, callback, breakup }) {
    const [form] = Form.useForm();

    const [vehicle, setVehicle] = useState();

    const { user } = useContext(GlobalContext);

    const [editMode, seteditMode] = useState(false);

    let billingStatus = {
        pending: 'Some message',
        requested: 'Your request for billing has been send for approval . Please wait.',
        approved: 'Your request for billing has been send for approval . Please wait.',
        done: 'Your request for billing has been send for approval . Please wait.',
    };

    let exShowroom = 0;

    if (breakup.breakup) {
        let d = breakup.breakup.filter((item) => item.description === 'Ex Showroom Price');

        if (d && d.length) {
            exShowroom = d[0].price;
        }
    }

    /**
     *
     *
     * @param {*} values
     */
    const onSMVerify = (values) => {
        let creation = {
            requested_date: moment().startOf('day').valueOf(),
            requested_time: moment().valueOf(),
        };

        // Add a retail request
        // let params = {
        //     requestId: userRequest.id,
        //     bookingNo: userRequest.bookingNo,
        //     accessories: userRequest.accessories,
        //     status: 'requested',
        //     total: breakup.total,
        //     breakup: breakup.breakup,
        //     balance: breakup.balance,
        //     received: breakup.received,
        //     city: userRequest.city,
        //     variant: userRequest.variant,
        //     color: userRequest.color,
        //     ...creation,
        //     ...userRequest.customer
        // }

        if (values.status === 'billing') {
            // Only allow if all the mandatory process are complete
            // if (
            //     userRequest.finance.process === 'complete' ||
            //     ['Disbursed', 'Disbursement', 'Disbursement Pending'].indexOf(userRequest.finance.status) !== -1
            // ) {
            // params.type = 'billing';

            // Billing has to be allowed only to sm
            if (['admin', 'rm', 'sm'].indexOf(user.role) !== -1) {
                // Billing Request
                // FirebaseUtils.addRecord(userRequest.city, 'billing_snapshot', params).then((result) => {
                // Attach the billing request
                // values.billingRequest = result.id;

                requestForBilling(values);

                // });
            } else {
                message.error('Only SM is allowed to initiate billing for a request');
            }
            // } else {
            //     message.error('Finance process has to be complete or reach disbursement before you can send this request for billing');
            // }
        } else if (values.status === 'delivery') {
            // Delivery Can be initiated
            // Only after insurance , fasttag , rto , accessories
            // if (
            //     userRequest.insurance.process === 'complete' &&
            //     userRequest.fasttag.status === 'Fast Tag Issue' &&
            //     userRequest.accessories.status === 'Accessories Fitted' &&
            //     [
            //         'Pending Payment',
            //         'Road Tax Issued',
            //         'Waiting for TP',
            //         'pending',
            //         'HSRP Dispatch Pending',
            //         'HSRP Receival Pending',
            //         'HSRP Fitment Completed',
            //         'HSRP Fitment Pending',
            //         'HSRP Pending',
            //         'HSRP Pending(Other)',
            //     ].indexOf(userRequest.rto.status) !== -1
            // ) {
            // params.type = 'delivery';

            // Delivery Request
            // FirebaseUtils.addRecord(userRequest.city, 'delivery_snapshot', params).then((result) => {

            // Attach the billing request
            // values.deliveryRequest = result.id;

            requestForDelivery(values);

            // });
            // } else {
            //     if (userRequest.insurance.process !== 'complete') {
            //         message.error('This request is not eligible for proceeding for Delivery because Insurance process is not complete.');
            //     } else if (userRequest.fasttag.status !== 'Fast Tag Issue') {
            //         message.error('This request is not eligible for proceeding for Delivery because Fast Tag status is not Fast Tag Issue.');
            //     } else if (userRequest.accessories.status !== 'Accessories Fitted') {
            //         message.error('This request is not eligible for proceeding for Delivery because Accesssories  status is incomplete.');
            //     } else {
            //         message.error(
            //             'This request is not eligible for proceeding for Delivery because either of Insurance, Fast Tag, Accesssories or RTO status is incomplete. Please contact your SM for any clarifications.'
            //         );
            //     }
            // }
        }
    };

    /**
     * Request for Billing
     */
    function requestForBilling(values) {
        var query = [
            {
                field: 'bookingNo',
                value: userRequest.bookingNo,
            },
            {
                field: 'type',
                value: 'basic',
            },
            {
                field: 'cancellation_status',
                value: false,
            },
        ];

        Bills.get(query).then((result) => {
            if (result.bills.length != 0) {
                let creation = {
                    requested_at: moment().format('DD/MM/YYYY HH:mm'),
                    requested_date: moment().startOf('day').valueOf(),
                    requested_time: moment().valueOf(),
                };

                // if (breakup.received > (95 / 100) * exShowroom) {
                let approval = {
                    approved_at: moment().format('DD/MM/YYYY HH:mm'),
                    approved_date: moment().startOf('day').valueOf(),
                    approved_time: moment().valueOf(),
                };

                let params = {
                    [values.status]: {
                        ...userRequest.billing,
                        ...creation,
                        ...approval,
                        readyForBilling: true,
                        ...values,
                        status: 'approved',
                    },
                };

                // #note Here we upload many other fields for insurance, warranty , accessories so that their process can be done.

                FirebaseUtils.updateRequestGeneric(
                    // userRequest.city,
                    userRequest.id,
                    params,
                    'Approved for ' + values.status,
                    values.remarks,
                    values.status
                ).then(() => {
                    message.success('Your status has been updated');

                    callback();
                });
                // } else {
                //     let params = {
                //         [values.status]: {
                //             ...userRequest.billing,

                //             ...creation,
                //             ...values,
                //             status: 'requested',
                //             approvalRequired: true,
                //         },
                //     };

                //     FirebaseUtils.updateRequestGeneric(
                //         // userRequest.city,
                //         userRequest.id,
                //         params,
                //         'Requested for Approval for ' + values.status,
                //         values.remarks,
                //         values.status
                //     ).then(() => {
                //         message.success('Your request has been send');

                //         callback();
                //     });
                // }
            } else {
                message.error('Please complete Surge Billing to proceed with DMS Billing!');
            }
        });
    }

    /**
     * Request for Delivery
     */
    function requestForDelivery(values) {
        let creation = {
            requested_at: moment().format('DD/MM/YYYY HH:mm'),
            requested_date: moment().startOf('day').valueOf(),
            requested_time: moment().valueOf(),
        };

        // if (breakup.received > exShowroom) {

        let approval = {
            approved_at: moment().format('DD/MM/YYYY HH:mm'),
            approved_date: moment().startOf('day').valueOf(),
            approved_time: moment().valueOf(),
        };

        let params = {
            [values.status]: {
                ...userRequest.delivery,

                ...approval,
                ...creation,
                readyForDelivery: true,
                ...values,
                status: 'approved',
            },
        };

        // #note Here we upload many other fields for insurance, warranty , accessories so that their process can be done.

        FirebaseUtils.updateRequestGeneric(
            // userRequest.city,
            userRequest.id,
            params,
            'Approved for ' + values.status,
            values.remarks,
            values.status
        ).then(() => {
            message.success('Your status has been updated');

            callback();
        });
        // } else {
        //     let params = {
        //         [values.status]: {
        //             ...userRequest.delivery,
        //             ...creation,
        //             ...values,
        //             status: 'requested',
        //             approvalRequired: true,
        //         },
        //     };

        //     FirebaseUtils.updateRequestGeneric(
        //         // userRequest.city,
        //         userRequest.id,
        //         params,
        //         'Requested for Approval for ' + values.status,
        //         values.remarks,
        //         values.status
        //     ).then(() => {
        //         message.success('Your request has been send');

        //         callback();
        //     });
        // }
    }
    /**
     * Get vehicle
     */
    const getVehicle = () => {
        if (userRequest.allocation && userRequest.allocation.vinNo) {
            return Vehicles.getRecord(userRequest.allocation.vinNo).then((result) => {
                setVehicle(result);
            });
        } else {
            setVehicle(null);
        }
    };

    return (
        <div>
            {editMode ? (
                <div className="vehicle-card">
                    {/* According to the current status we might have to show alerts to educate sm regarding the next steps  */}
                    {userRequest.finance.process !== 'complete' ? (
                        <Alert
                            className="vehicle-card"
                            message="Finance Process is incomplete"
                            description={`Please acheive login to process with Billing`}
                            type="warning"
                        />
                    ) : null}

                    {vehicle && vehicle.stock_status !== 'in-transit' ? (
                        ['sm', 'rm', 'admin', 'tl'].indexOf(user.role) !== -1 && userRequest.finance && userRequest.finance.status ? (
                            <div className="vehicle-card">
                                <div className="document-status">
                                    <Title level={3}>Update Status </Title>

                                    <p>Update the status of this request</p>

                                    <Form
                                        {...layout}
                                        form={form}
                                        name="new-record"
                                        onFinish={onSMVerify}
                                        onFieldsChange={(fields) => {
                                            let field = fields[0];

                                            if (field && field.name[0] === 'status') {
                                                if (field.value === 'billing') {
                                                }
                                            }
                                        }}
                                        layout="vertical"
                                        initialValues={
                                            {
                                                // status: userRequest.finance.status
                                            }
                                        }
                                    >
                                        <Form.Item
                                            name={'status'}
                                            label="Request Status"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter current status',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Please update the current status of the Customer">
                                                <Option value={'billing'} key={'billing'}>
                                                    Billing
                                                </Option>

                                                <Option value={'delivery'} disabled={!userRequest.delivery} key={'delivery'}>
                                                    Delivery
                                                </Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name={'remarks'}
                                            label="Remarks"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter remarks',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>

                                        {/* 
                                    (userRequest.billing && userRequest.billing.status === 'approved') ||
                                        breakup.received > (95 / 100) * exShowroom */}

                                        {true ? (
                                            <>
                                                <Button type="primary" htmlType="submit" className="submit-button">
                                                    Proceed
                                                </Button>

                                                <Button
                                                    size="small"
                                                    onClick={() => {
                                                        seteditMode(false);
                                                    }}
                                                    type="secondary"
                                                    className="submit-button"
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                {userRequest.billing && !userRequest.billing.status ? (
                                                    <Alert
                                                        message="Approval Required"
                                                        description={`Since the fund received is below the tolerance set you will need to request for approval from higher authority.`}
                                                        type="error"
                                                    />
                                                ) : null}

                                                <Button style={{ marginTop: '20px' }} type="secondary" htmlType="submit" className="submit-button">
                                                    Request for Approval
                                                </Button>

                                                <Button
                                                    size="small"
                                                    onClick={() => {
                                                        seteditMode(false);
                                                    }}
                                                    type="secondary"
                                                    className="submit-button"
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        ) : null
                    ) : (
                        <Alert
                            className="vehicle-card"
                            message="The vehicle is in in-transit mode"
                            description={`Can't update! The vehicle is in in-transit mode`}
                            type="warning"
                        />
                    )}
                </div>
            ) : (
                <div className="vehicle-card">
                    <Title level={3}>Request Status</Title>

                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <span>DMS Billing Status</span>
                            </Row>
                            <Row span={12}>
                                <Tag color="orange" style={{ margin: '10px 0px' }}>
                                    {userRequest.billing.status || 'In Process'}
                                </Tag>
                            </Row>
                        </div>

                        {userRequest.billing && userRequest.billing.status === 'approved' ? (
                            <Alert
                                className="vehicle-card"
                                message="Approved"
                                description={`The request is waiting at EDP for DMS billing`}
                                type="success"
                            />
                        ) : null}

                        {userRequest.billing && userRequest.billing.status === 'delivery' ? (
                            <Alert
                                className="vehicle-card"
                                message="Approved"
                                description={`The request is waiting at EDP for delivery`}
                                type="success"
                            />
                        ) : null}

                        {userRequest.delivery && userRequest.delivery.status ? (
                            <>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>DMS Delivery Status</span>
                                    </Row>
                                    <Row span={12}>
                                        <h2 className="title">{userRequest.delivery && userRequest.delivery.status}</h2>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Delivery Schedule</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3 className="title">{userRequest.delivery && userRequest.delivery.schedule} for </h3>
                                    </Row>

                                    {userRequest.delivery && userRequest.delivery.schedule === 'Delivery Scheduled' ? (
                                        <>
                                            <Row span={12}>
                                                <h2 className="title">
                                                    {userRequest.delivery &&
                                                        moment(userRequest.delivery.scheduled_delivery_time).format('DD/MM/YYYY, HH:mm A')}
                                                </h2>
                                            </Row>
                                        </>
                                    ) : null}
                                    <Row span={12}>
                                        <h3 className="title">{userRequest.delivery && userRequest.delivery.schedule} on </h3>
                                    </Row>
                                    {userRequest.delivery && userRequest.delivery.schedule === 'Delivery Scheduled' ? (
                                        <>
                                            <Row span={12}>
                                                <h2 className="title">
                                                    {userRequest.delivery &&
                                                        moment(userRequest.delivery.scheduled_time).format('DD/MM/YYYY, HH:mm A')}
                                                </h2>
                                            </Row>
                                        </>
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                    </div>

                    {(userRequest.billing && userRequest.billing.status === 'requested') ||
                        (userRequest.delivery && userRequest.delivery.status === 'requested') ? (
                        <>
                            <Alert
                                className="vehicle-card"
                                message={`Approval ${userRequest.billing.status}`}
                                description={billingStatus[userRequest.billing.status]}
                                type={userRequest.billing.status === 'done' ? 'success' : 'warning'}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    {/*  */}
                    {(['admin', 'rm', 'sm', 'tl'].indexOf(user.role) !== -1 &&
                        userRequest.allocation.vinNo &&
                        userRequest.billing &&
                        userRequest.billing.status === 'pending') ||
                        (userRequest.delivery && userRequest.delivery.status === 'pending') ? (
                        <>
                            {/* Edit Mode Toggle */}
                            <Button
                                size="small"
                                onClick={() => {
                                    seteditMode(true);
                                    getVehicle();
                                }}
                                type="secondary"
                                className="submit-button"
                            >
                                Edit
                            </Button>
                            {/* Edit Mode Toggle */}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </div>
    );
}

function HighPriorityBlock({ booking, userRequest, callback }) {
    const [form] = Form.useForm();

    const { user } = useContext(GlobalContext);

    const [bookingForm] = Form.useForm();

    const onSubmitBookingNumber = (values) => {
        FirebaseUtils.updateHighPriorityBookingNumber(
            // userRequest.city,
            userRequest.id,
            values.bookingNo
        )
            .then((result) => {
                if (result) {
                    message.success('Your request has been submitted');
                } else {
                    message.success('This request has been rejected');
                }

                callback();

                // props.history.goBack();
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    /**
     * Function veirfies high priority
     *
     * @param {*} props
     */
    const onVerify = (props) => {
        var values = form.getFieldsValue();

        var isCancel = Object.keys(props).length === 0;

        let content = {
            author: user.name,
            content: isCancel ? 'Request Rejected : ' : 'Request Approved : ' + values.remarks,
            datetime: moment().format('DD/MM/YYYY HH:mm'),
            deleted_at: null,
            deleted_by: null,
        };

        FirebaseUtils.verifyHighPriority(
            // userRequest.city,
            userRequest.id,
            content,
            isCancel
        )
            .then((result) => {
                if (result) {
                    message.success('Your request has been submitted');
                } else {
                    message.success('This request has been rejected');
                }

                callback();

                // props.history.goBack();
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    return (
        <>
            {/* High Priority Request Bloxk */}
            {userRequest.isHighPriority ? (
                <>
                    {['super', 'rm', 'admin', 'sm'].indexOf(user.role) !== -1 ? (
                        <>
                            {!userRequest.bookingNo ? (
                                <Card className="">
                                    <div style={{ marginTop: '10px' }}>
                                        <p>Please provide the Booking Number for this request</p>

                                        <Form
                                            {...layout}
                                            form={bookingForm}
                                            name="new-record"
                                            onFinish={onSubmitBookingNumber}
                                            layout="vertical"
                                            // validateMessages={validateMessages}
                                            initialValues={{}}
                                        >
                                            <Form.Item
                                                name={'bookingNo'}
                                                label="Booking Number"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter Booking Number',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Button style={{ marginRight: '10px' }} type="primary" htmlType="submit" className="submit-button">
                                                SUBMIT
                                            </Button>
                                        </Form>
                                    </div>
                                </Card>
                            ) : (
                                <>
                                    {/* {
                                            booking.isValid ?
                                                <BookingCard booking={booking} />
                                                :
                                                <>

                                                    Invalid Booking Number {userRequest.bookingNo}
                                                </>
                                        } */}
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}

                    {['super', 'rm', 'admin'].indexOf(user.role) !== -1 ? (
                        <>
                            {userRequest.highPriorityStatus === 'requested' ? (
                                <div style={{ marginTop: '10px' }}>
                                    <p>Please provide your approval after checking with the customer to mark this as high priority request</p>

                                    <Form
                                        {...layout}
                                        form={form}
                                        name="new-record"
                                        onFinish={onVerify}
                                        layout="vertical"
                                        // validateMessages={validateMessages}
                                        initialValues={{}}
                                    >
                                        <Form.Item
                                            name={'remarks'}
                                            label="Remarks"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter Remarks',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>

                                        <Button style={{ marginRight: '10px' }} type="primary" htmlType="submit" className="submit-button">
                                            APPROVE
                                        </Button>

                                        <Button
                                            onClick={() => {
                                                onVerify(true);
                                            }}
                                            type="danger"
                                            className="submit-button"
                                        >
                                            DECLINE
                                        </Button>
                                    </Form>
                                </div>
                            ) : null}

                            {/* <Alert
                                                                    message={`${['approved', 'done'].indexOf(userRequest.status) !== -1 ? 'Approval accepted' : 'Approval Rejected'}`}
                                                                    description={`${userRequest.status === 'approved' ? 'This customers case has been approved' : 'This customers case has been rejected'}`}
                                                                    type={userRequest.status === 'approved' ? 'success' : 'error'}
                                                                /> */}
                        </>
                    ) : (
                        <></>
                    )}

                    {/* Approval Pending */}
                    {userRequest.status === 'requested' ? (
                        <Alert
                            message="Approval Pending"
                            description={`This request is yet to be approved from the concerned department.`}
                            type="error"
                        />
                    ) : null}
                </>
            ) : null}
        </>
    );
}

/**
 *
 * Vehicle Allocation Module
 *
 */
function VehicleAllocation({ request, callback, addPricing, updateStockTransfer }) {
    const [loader, setLoader] = useState(false);

    const [transfer, setTransfer] = useState({});

    const [allocated, setAllocated] = useState({});

    const [vehicle, setVehicle] = useState({});

    const [outwardTransfer, setOutwardTransfer] = useState({});

    const { user } = useContext(GlobalContext);

    const [bookingStatusModal, setBookingStatusModal] = useState(false);

    const [access_unblocking, setAccessUnblocking] = useState();

    useEffect(() => {
        // Load who all can unblocking-allowed
        Settings.getValue('unblocking-allowed').then((result) => {
            var allowed = result.value.indexOf(user.email) !== -1;
            setAccessUnblocking(allowed);
        });

        return () => { };
    }, []);

    useEffect(() => {
        if (request.allocation.vinNo) {
            getSoftAllocation(request);

            // If there is a stock transfer requested
            if (request.transferRequest) {
                getStockTransfer(request.transferRequest);
            }
        } else {
        }

        if (request.bookingNo) {
            getVehicle(request.city, request);
        } else {
            message.error('No booking found');
        }
    }, []);

    function getStockTransfer(transferRequest) {
        StockTransfers.getRecordStock(transferRequest).then(async (result) => {
            setTransfer(result);

            var outwardTransfer;

            if (result.type === 'inward') {
                // If transfer type is inward then corresponding outward transfer also need to be considered to show the stock transfer
                outwardTransfer = await StockTransfers.get([{ field: 'batch_id', value: result.id }]);

                var outwardData = await StockTransfers.getRecordStock(outwardTransfer.stock_transfers[0].id);

                setOutwardTransfer(outwardData);
            }

            updateStockTransfer(result);
        });
    }

    function getSoftAllocation(request) {
        var queries = [
            {
                field: 'Vin Number',
                value: request.allocation.vinNo,
            },
        ];

        let city = request.destination_city || request.city;

        setLoader(true);

        FirebaseUtils.getVehicles(null, queries).then((result) => {
            setLoader(false);

            if (result.vehicles.length) {
                let vehicle = result.vehicles[0];

                setVehicle(vehicle);
                // setAllocated(vehicle);
            } else {
            }
        });
    }

    function getVehicle(vehicleCity, request) {
        var queries = [
            {
                field: 'Booking No',
                value: request.bookingNo,
            },
            // {
            //     field: 'update',
            //     value: 'new'
            // }
        ];

        setLoader(true);

        FirebaseUtils.getVehicles(vehicleCity, queries).then((result) => {
            setLoader(false);

            if (result.vehicles.length) {
                let vehicle = result.vehicles[0];

                setAllocated(vehicle);
            } else {
            }
        });
    }

    // Unblocking a vehicle from a request
    async function unblock() {
        setBookingStatusModal(true);

        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        await Vehicles.unblockVehicle(vehicle, request, batch);
        // If transfer request is present then it is to be cancelled on unblock
        if (transfer.active) {
            if (transfer.sub_mode === 'virtual') {
                await StockTransfers.cancelStockTransferRequest(transfer, batch);
            }
        }

        await batch.commit();

        // callback();

        message.success('Vehicle Unblocked');
    }

    function updateAllocation() {
        let creation = {
            created_by: user.id,
            created_by_name: user.name,
            created_by_role: user.role,
            // created_by: { name: user.name, role: user.role },
            created_at: moment().format('DD/MM/YYYY HH:mm'),
            created_date: moment().startOf('day').valueOf(),
            created_time: moment().valueOf(),
            deleted_at: null,
            deleted_by: null,
        };

        let allocationBody = {
            status: 'completed',
            vinNo: allocated['Vin Number'],
            ...creation,
            requestId: request.id,
            customerID: request.customer.customerID,
            bookingNo: request.bookingNo,
            dealerCode: request.dealerCode,
        };

        FirebaseUtils.allocateVehicle(allocationBody).then((result) => {
            let userContent = {
                allocation: {
                    ...creation,
                    ...request.allocation,
                    status: 'Allocated',
                    vinNo: allocated['Vin Number'],
                    allocationId: result.id,
                },
            };
            FirebaseUtils.updateRequestBody(
                // request.city,
                request.id,
                userContent
            ).then(() => {
                message.success('Vehicle allocated successfully.');
                addPricing();
            });
        });
    }

    // const stockTransfersArray = Object.entries(transfer).map(([key, value]) => ({ id: key, ...value }));

    return (
        <div className="vehicle-card">
            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <div>
                    {!vehicle['Vin Number'] ? (
                        <div style={{ margin: '10px 0px' }}>
                            <Alert message="Soft Allocation Pending" type="info" />
                            <div style={{ margin: '10px 0px' }}>
                                {allocated['Vin Number'] ? (
                                    <Button type="secondary" onClick={updateAllocation}>
                                        Update Allocation
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <>
                            <>
                                <div gutter={0} className="detail-wrapper">
                                    <Tag icon={<CheckCircleOutlined />} color="success">
                                        Vehicle Allocated (Soft Allocated)
                                    </Tag>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Vin Number</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{vehicle['Vin Number']}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>DMS Allocation by EDP</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{request.allocation.status === 'Allocated' ? 'Done' : 'Pending'}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Stock Location</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{vehicle['Stock Location'] || 'Unavailable'}</h3>
                                        </Row>
                                    </div>

                                    {/* Pass the transfer object to the StockTransfer component for display stock Transfer */}
                                    {transfer.active ? (
                                        <div style={{ marginBottom: '6px' }}>
                                            <Row span={12}>
                                                <span>Stock Transfer</span>
                                            </Row>
                                            <StockTransferDetails
                                                transferRequest={request.transferRequest}
                                                stockTransfers={outwardTransfer.id ? [transfer, outwardTransfer] : [transfer]}
                                                style={{ margin: '20px' }}
                                            />
                                        </div>
                                    ) : null}

                                    {/* {transfer.active ? (
                                        <Alert
                                            message="Stock Transfer"
                                            description={`There is a ${transfer.sub_mode==="virtual"?"temporary":"actual"} stock transfer request from ${transfer.from} to ${transfer.to} requested by ${transfer.created_by_name}. Current Status : ${transfer.status}`}
                                            type="info"
                                        />
                                    ) : null} */}
                                    {['admin', 'sm', 'rm'].indexOf(user.role) !== -1 &&
                                        request.actual_billing &&
                                        (access_unblocking || request.allocation.created_by.name == user.name) &&
                                        (request.actual_billing.status === 'pending' || request.actual_billing.status === 'cancelled') ? (
                                        <Popconfirm
                                            title="Are you sure you want to Unblock this Vehicle ? "
                                            onConfirm={() => {
                                                unblock();
                                            }}
                                            onCancel={() => { }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="secondary">Unblock</Button>{' '}
                                        </Popconfirm>
                                    ) : null}

                                    {/* update booking current status value when unblocking the vehicle  */}
                                    {bookingStatusModal ? (
                                        <div>
                                            <Modal
                                                width={'50%'}
                                                destroyOnClose={false}
                                                footer={null}
                                                title="Booking Status"
                                                visible={bookingStatusModal}
                                                okText="Okay"
                                                onOk={() => {
                                                    // setBookingStatusModal(false);
                                                }}
                                                onCancel={() => {
                                                    // setBookingStatusModal(false);
                                                }}
                                            >
                                                <BookingStatusModal id={request.bookingNo} callback={callback} />
                                            </Modal>
                                        </div>
                                    ) : null}
                                    {/*Ends */}
                                </div>
                            </>
                        </>
                    )}

                    {/* {!allocated['Vin Number'] ? (
                        <Alert message="DMS Allocation Pending" type="info" />
                    ) : (
                        <>
                            <>
                                <div gutter={0} className="detail-wrapper">
                                    <Tag icon={<CheckCircleOutlined />} color="success">
                                        Vehicle Allocated (DMS)
                                    </Tag>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Vin Number</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{allocated['Vin Number']}</h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Stock Location</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>{allocated['Stock Location'] || 'Unavailable'}</h3>
                                        </Row>
                                    </div>
                                </div>
                            </>
                        </>
                    )} */}
                </div>
            )}
        </div>
    );
}

function FinanceStatus({ userRequest }) {
    return (
        <div className="vehicle-card ">
            {userRequest.finance.status !== 'pending' ? (
                <div className="pricing">
                    <div className="document-status">
                        <Title level={3}>Finance Status </Title>

                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Status</span>
                                </Row>
                                <Row span={12}>
                                    <h3 className="title">{userRequest.finance.status}</h3>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Finance Type</span>
                                </Row>
                                <Row span={12}>
                                    <h2 className="title">{userRequest.finance.type}</h2>
                                </Row>
                            </div>

                            {userRequest.finance.type && userRequest.finance.type !== 'Own Fund' ? (
                                <>
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Bank</span>
                                        </Row>
                                        <Row span={12}>
                                            <h2 className="title">{userRequest.finance.bank}</h2>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Remarks</span>
                                        </Row>
                                        <Row span={12}>
                                            <h2 className="title">{userRequest.finance.remarks}</h2>
                                        </Row>
                                    </div>
                                </>
                            ) : null}

                            {userRequest.finance.requestedAmount ? (
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Requested Amount</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3 className="title">{userRequest.finance.requestedAmount}</h3>
                                    </Row>
                                </div>
                            ) : null}

                            {userRequest.finance.sanctioned_amount ? (
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Sanctioned Amount</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3 className="title">{userRequest.finance.sanctioned_amount}</h3>
                                    </Row>
                                </div>
                            ) : null}

                            {userRequest.finance.deliveryOrder ? (
                                <ImageWrapper
                                    type={Array.isArray(userRequest.finance.deliveryOrder) ? 'array' : 'object'}
                                    content={userRequest.finance.deliveryOrder}
                                />
                            ) : null}

                            {/* {
                                userRequest.finance.deliveryOrder ?
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>
                                                Delivery Order
                                            </span>
                                        </Row>
                                        <Row span={12}>

                                            <h2 className="title">
                                                {
                                                    userRequest.finance.deliveryOrder.type === "application/pdf"
                                                        ?
                                                        <embed src={userRequest.finance.deliveryOrder.url} width="500" height="375"
                                                            type="application/pdf" />
                                                        : <Image width={200} src={userRequest.finance.deliveryOrder.url} />
                                                }
                                            </h2>
                                        </Row>
                                    </div> : null
                            } */}
                        </div>
                    </div>
                </div>
            ) : (
                <Alert message="Finance Pending" description={`The finance process for this request has not yet started.`} type="error" />
            )}
        </div>
    );
}

function RefundStatus({ userRequest }) {
    const [status, setStatus] = useState();

    useEffect(() => {
        if (userRequest.accounts.refund.id) {
            let formBody = {
                id: userRequest.accounts.refund.id,
                mode: userRequest.accounts.refund.mode,
                location: userRequest.city,
            };

            FirebaseUtils.getRefundStatus({ formBody: formBody }).then((result) => {
                if (result.data && result.data.id) {
                    setStatus(result.data.status);
                } else {
                    message.error(result.data.description);
                }
            });
        }
    }, []);

    return (
        <div className="vehicle-card ">
            {status ? (
                <div className="pricing">
                    <div className="document-status">
                        <Title level={3}>Refund Status </Title>

                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Status</span>
                                </Row>
                                <Row span={12}>
                                    <h3 className="title">{status}</h3>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

function InsuranceStatus({ userRequest, booking, user, callback }) {

    const { insurance } = userRequest;

    // Acess for Insurance revertings
    let revertInsurance = Users.hasPermission(user, 'revert_insurance');

    let content = [];

    /**
    * Revert insurance while uploading wrong insurance
    */
    const startOver = () => {
        var params = {
            insurance: {
                process: 'incomplete',
                status: 'Ready for Issue',
            },
            is_insurance_revert: true
        }
        return Requests.update(userRequest.id, params).then(async () => {
            let batch = FirebaseUtils.getApp().batch();
            const requestRef = Requests.getRecordReference(userRequest.id);
            //The updated values, previous values, updated fields, updated model, who updated are all store in document_logs
            await DocumentLogs.recordLog('request', userRequest, params, user, batch, requestRef).then(async () => {
                message.success('Successfully reverted Insurance')
                callback();

            })
        })
    };
    ['attachment', 'invoice'].map((index) => {
        if (insurance && insurance[index]) {
            if (Array.isArray(insurance[index])) {
                content.push({ files: insurance[index][0], caption: index });
            } else {
                content.push({ files: insurance[index], caption: index });
            }
        }
    });

    return (
        <div className="vehicle-card ">
            {userRequest.insurance && userRequest.insurance.status !== 'pending' && userRequest.insurance.status !== 'Ready for Issue' ? (
                <div className="pricing">
                    <div className="document-status">
                        <div className="page-header">
                            <Title level={3}>Insurance Status </Title>
                            <div className="action">

                                {/* insurance Reverting option while uploading wrong insurance */}
                                {revertInsurance && user.role === 'accounts' ? (
                                    <Popconfirm
                                        title="Are you sure you want to revert insurance? "
                                        onConfirm={() => startOver()}
                                        onCancel={() => { }}
                                        okText="Yes"
                                        cancelText="No">
                                        <Button type="dashed" className="print-button">
                                            Revert Insurance
                                        </Button>
                                    </Popconfirm>
                                ) : null}

                            </div>
                        </div>
                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Status</span>
                                </Row>
                                <Row span={12}>
                                    <h3 className="title">{userRequest.insurance.status}</h3>
                                </Row>
                            </div>
                        </div>

                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Type</span>
                                </Row>
                                <Row span={12}>
                                    <h3 className="title">{userRequest.insurance.type}</h3>
                                </Row>
                            </div>
                        </div>

                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Actual Amount</span>
                                </Row>
                                <Row span={12}>
                                    <h3 className="title">{userRequest.insurance.actualAmount}</h3>
                                </Row>
                            </div>
                        </div>

                        {userRequest.insurance.attachment ? (
                            <>
                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Insurance</span>
                                        </Row>
                                        <Row span={12}>
                                            <Tabs>
                                                {content.map((entry, index) => (
                                                    <TabPane key={index} tab={entry.caption}>
                                                        <ImageWrapper type={'object'} content={entry.files} />
                                                    </TabPane>
                                                ))}
                                            </Tabs>
                                        </Row>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            ) : (
                <>
                    {booking.insurance && booking.insurance.status === 'Data Collected' ? (
                        <>
                            <div className="document-status">
                                <Title level={3}>Insurance Status </Title>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Status</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3 className="title">{booking.insurance.status}</h3>
                                        </Row>
                                    </div>
                                </div>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Type</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3 className="title">{booking.insurance.type}</h3>
                                        </Row>
                                    </div>
                                </div>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Provider</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3 className="title">{booking.insurance.provider}</h3>
                                        </Row>
                                    </div>
                                </div>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>Policy Amount</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3 className="title">{booking.insurance.premium_amount}</h3>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Alert message="Insurance Pending" description={`The Insurance process for this request has not yet started.`} type="error" />
                    )}
                </>
            )}
        </div>
    );
}

function VehicleCard({ vehicle, request = {}, city, callback }) {
    const { user } = useContext(GlobalContext);

    /**
     *
     *
     */
    async function blockVehicle() {
        var app = FirebaseUtils.getApp();

        var batch = app.batch();

        await Vehicles.blockVehicle(request, vehicle, batch);

        await VehicleUtils.addPricing(request, vehicle, user, batch);

        await batch.commit();

        message.info('Blocked Successfully');

        callback();
    }

    return (
        <div className="stock-item">
            <div className="left">
                <Title level={4}>
                    {vehicle['Variant']}
                    <span>{` [ ${vehicle['Exterior Color Name']} ] `}</span>
                </Title>

                <div>{`${vehicle['Vin Number']}`}</div>

                <small className="status">
                    <Badge status={badgeColor[vehicle['Stock Status']]} />

                    {vehicle['Stock Location'] || vehicle['Stock Status']}
                </small>
            </div>
            <div className="right">
                {vehicle.allocated && vehicle.allocated.customer ? (
                    <div>
                        {/* {['admin', 'sm'].indexOf(user.role) !== -1 ? <Button size="small" onClick={() => { unblock(vehicle) }} type="secondary">Unblock</Button> : null} */}

                        <Tag icon={<CheckCircleOutlined />} color="success">
                            Blocked
                        </Tag>
                    </div>
                ) : (
                    <div>
                        {request.isHighPriority ? (
                            <>
                                {['sm', 'admin', 'rm'].indexOf(user.role) !== -1 && request.highPriorityStatus === 'approved' ? (
                                    <Button size="small" type="secondary" onClick={blockVehicle}>
                                        Block Vehicle
                                    </Button>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Link
                                    to={`/${city}/preference?model=${vehicle['Model']}&variant=${vehicle['Variant']}&color=${vehicle['Exterior Color Name']}`}
                                >
                                    Block Vehicle
                                </Link>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

function BookingCard({ booking }) {
    const { user } = useContext(GlobalContext);

    return (
        <div className="booking-card allocation-request">
            <div className="card">
                <div className="left">
                    <Title level={4}>{booking['Name of the Customer']}</Title>
                    <h4 className="title">{booking['Contact Number']}</h4>
                    <h3 className="title ">{booking['Variant']}</h3>
                    <div>
                        <small> {booking['Color']}</small>
                    </div>
                    <h4>
                        {booking['Booking No']} - <Tag color="#2db7f5">{/* {dealer[booking['Dealer Code']]} */}</Tag>
                    </h4>
                    <h4>KEC : {booking['Consultant Name']}</h4>
                    <h4>Team Leader : {booking['Team Leader']}</h4>
                    <p className="">
                        Booked on {booking['Booking Date']},{' '}
                        <Text type="danger">{`${moment().diff(moment(booking['Booking Date'], 'DD/MM/YYYY'), 'days')} days ago.`}</Text>
                        <Tag color="magenta">{booking.status || 'Initial'}</Tag>
                    </p>
                    Committed Delivery Date {booking['Committed Delivery Date']} , Booked By {booking['Consultant Name']}
                </div>
                <div className="right card-buttons">
                    <a className="card-action" href={`tel:${booking['Contact Number']}`} key="list-loadmore-edit">
                        Call
                    </a>
                </div>
            </div>
        </div>
    );
}

function MatchingVehicles({ userRequest, callback }) {
    const [vehicle, setVehicle] = useState({});

    const { user } = useContext(GlobalContext);

    const city = userRequest.city;

    useEffect(() => {
        findMatchingVehicles(userRequest);
    }, []);

    /**
     *
     * Find matching vehicles for request
     *
     * @param {*} request
     */
    function findMatchingVehicles(request) {
        if (request.model && request.color && request.variant && city) {
            var queries = [
                {
                    field: 'Model',
                    value: request.model,
                },
                {
                    field: 'Stock Status',
                    value: ['Free Stock', 'In Transit'],
                    operator: 'in',
                },
            ];

            FirebaseUtils.getVehicles(request.city, queries).then((result) => {
                setVehicle(result.vehicles);
            });
        } else {
            message.error('Failed due to an error');
        }
    }

    return (
        <>
            {/* Show matching vehicles if no vehicle is allocated */}

            <Title level={3} className="tab-header">
                Matching Vehicles
            </Title>

            <Tabs onChange={() => { }}>
                {/* !allocated['Vin Number'] && userRequest.bookingNo ? */}

                <TabPane
                    tab={`Similar (${Object.keys(vehicle).filter(
                        (key) => vehicle[key]['Variant'] === userRequest.variant && vehicle[key]['Exterior Color Name'] === userRequest.color
                    ).length
                        })`}
                    key="0"
                >
                    <List
                        size="small"
                        dataSource={Object.keys(vehicle).filter(
                            (key) => vehicle[key]['Variant'] === userRequest.variant && vehicle[key]['Exterior Color Name'] === userRequest.color
                        )}
                        renderItem={(car) => <VehicleCard callback={callback} city={city} vehicle={vehicle[car]} request={userRequest} />}
                    />
                </TabPane>

                <TabPane tab={`By Variant (${Object.keys(vehicle).filter((key) => vehicle[key]['Variant'] === userRequest.variant).length})`} key="1">
                    <List
                        size="small"
                        dataSource={Object.keys(vehicle).filter((key) => vehicle[key]['Variant'] === userRequest.variant)}
                        renderItem={(car) => <VehicleCard callback={callback} city={city} vehicle={vehicle[car]} request={userRequest} />}
                    />
                </TabPane>

                <TabPane
                    tab={`By Colour (${Object.keys(vehicle).filter((key) => vehicle[key]['Exterior Color Name'] === userRequest.color).length})`}
                    key="2"
                >
                    <List
                        size="small"
                        dataSource={Object.keys(vehicle).filter((key) => vehicle[key]['Exterior Color Name'] === userRequest.color)}
                        renderItem={(car) => <VehicleCard callback={callback} city={city} request={userRequest} vehicle={vehicle[car]} />}
                    />
                </TabPane>

                <TabPane tab={`By Model (${Object.keys(vehicle).length})`} key="3">
                    <List
                        size="small"
                        dataSource={Object.keys(vehicle)}
                        renderItem={(car) => <VehicleCard callback={callback} city={city} request={userRequest} vehicle={vehicle[car]} />}
                    />
                </TabPane>
            </Tabs>
        </>
    );
}

function CommentSection({ userRequest }) {
    return (
        <>
            <CommentBlock id={userRequest.id} />
        </>
    );
}

function Warranty({ userRequest }) {
    const { warranty } = userRequest;

    return (
        <>
            {warranty && warranty.note ? (
                <div className="vehicle-card">
                    <Title level={3}>Extended Warranty</Title>
                    <div gutter={0} className="detail-wrapper">
                        <div gutter={0} className="detail-wrapper">
                            <div className="detail-element">
                                <Row span={12}>
                                    <span>Invoice</span>
                                </Row>
                                <Row span={12}>
                                    <ImageWrapper type={Array.isArray(warranty.note) ? 'array' : 'object'} content={warranty.note} />
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
