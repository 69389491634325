/**
*
* @author Nihala Dilshi
*/

import React, { useEffect, useState, Fragment, useContext } from 'react';

import { Tag, Button, Card } from 'antd';

import PlaceHolder from '../../../../components/ui_elements/PlaceHolder';

import moment from 'moment';

import { Enquirys, StatusLogs } from '../../../../models';

import { Typography } from 'antd';

const { Title, Text } = Typography;

export default function EnquiryDetail({ match }) {
    const [enquiry, setEnquiry] = useState({ proofs: {} });

    const { params } = match;

    const { id } = params;
    const [loader, setLoader] = useState(true);
    const [statusLogs, setStatusLogs] = useState([]);

    useEffect(() => {
        loadData();
        loadDocument()
    }, []);


    function loadData() {
        setLoader(true);

        Enquirys.getRecord(id).then((result) => {

            setEnquiry({ proof: {}, ...result, comments: [] });

            setLoader(false);

        });
    }

    /*
    *Load the Document Logs record
    */
    function loadDocument() {
        setLoader(true);
        var queries = [
            {
                field: 'reference_number',
                value: id,
            },
        ];
        StatusLogs.get(queries).then((result) => {

            setStatusLogs([result.status_logs]);
        });
    }
    const flattenedLogs = statusLogs.flat().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return (
        <div className="booking-detail listing">
            <div className="page-header">
                <Title level={3}>
                    Enquiry <i>{enquiry.id}</i>
                </Title>
                <div>
                    <Button onClick={loadData} type="secondary" size={'small'}>
                        Refresh
                    </Button>
                </div>
            </div>

            {loader ? (
                <PlaceHolder type="report" />
            ) : (
                <Fragment>
                    <div className="page-content">
                        <div className="split-container">
                            <div className="left">
                                <div className="booking-info" >
                                    <h2 className="title amount">{enquiry['Name of the Customer']}</h2>
                                    <h3 className="title amount">{enquiry['Contact Number']}</h3>
                                    <h3>{enquiry['Enquiry Status']}</h3>
                                    <Tag color="magenta">{enquiry['enquiry_status']}</Tag>
                                </div>
                            </div>

                            <div className="right">
                                <i>Details of Follow up status</i>
                                {flattenedLogs.map((log, index) => (
                                    <Card>
                                        <div key={index}>
                                            <h3><span className="title amount"><strong>Enquiry Follow Up Statu:</strong></span> {log.follow_up_status}</h3>
                                            <p>Enquiry Follow Up Date: {moment(log.created_at, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY")}</p>
                                            <p>Enquiry Follow up By: {log.created_by_name}</p>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}

        </div>
    );
}



